/*#region Responsive Templates */
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px ) !default;

//@media (min-width: 1200px) {}
//@include media-breakpoint-only(xl) {}

//@media (min-width: 992px) and (max-width: 1199px) {}
//@include media-breakpoint-only(lg) {}

//@media (min-width: 768px) and (max-width: 991px) {}
//@include media-breakpoint-only(md) {}

//@media (max-width: 767px) {}
//@include media-breakpoint-down(sm) {}

/*#endregion */

$white: #fff !default;
$black: #000 !default;

$gray-100: #F9FAFC !default;
$gray-200: #F1F2F6 !default;
$gray-300: #E5E9F2 !default;
$gray-400: #C0CCDA !default;
$gray-500: #8492A6 !default;
$gray-600: #8391A7 !default;
$gray-700: #5A6679 !default;
$gray-800: #3C4858 !default;
$gray-900: #1C2C40 !default;

$blue: #0BAFFF !default;
$purple: #7550E9 !default;
$orange: #FF7D50 !default;
$pink: #ED63D2 !default;
$green: #2DCA73 !default;
$red: #F03F3F;
$yellow: #FFC213 !default;

$border-color: $gray-300 !default;
$background-color: $gray-100 !default;

$border-radius: .5rem !default;

$font-size-base: 1rem !default; // 16px

$h1-font-size: rem(32 px) !default; 
$h2-font-size: rem(24 px) !default;
$h3-font-size: rem(18 px) !default; 
$h4-font-size: rem(16 px) !default; 
$h5-font-size: rem(13 px) !default; 
$h6-font-size: rem(10 px) !default; 

$theme-colors: ("white": $white, "black": $black, "gray-100": $gray-100, "gray-200": $gray-200, "gray-300": $gray-300, "gray-400": $gray-400, "gray-500": $gray-500, "gray-600": $gray-600, "gray-700": $gray-700, "gray-800": $gray-800, "gray-900": $gray-900, "blue": $blue, "purple": $purple, "orange": $orange, "pink": $pink, "green": $green, "red": $red, "yellow": $yellow);

$spacer: 1rem !default; // 1.25rem
$spacers: (
  0: 0, 
  5: rem(5px),
  10: rem(10px),
  15: rem(15px),
  20: rem(20px),
  25: rem(25px),
  30: rem(30px),
  35: rem(35px),
  40: rem(40px),
  50: rem(50px),
  60: rem(60px),
  80: rem(80px),
  100: rem(100px),
  120: rem(120px),
  140: rem(140px),
  160: rem(160px),
  180: rem(180px),
  200: rem(200px) 
  ) 
!default;
