@import "../variables";
@import "../mixins";

$form-group-margin-bottom: 30px;
$form-feedback-invalid-color: $red;
$input-padding-y: 0;
$input-padding-x: 20px;
$input-line-height: 1;
$input-color: $gray-900;
$input-bg: $white;
$input-border-width: 1px;
$input-border-color: $border-color;
$input-placeholder-color: rgba($gray-500, 0.9);
$input-disabled-bg: $background-color;
$input-height: 60px;

$input-focus-color: $gray-900;
$input-focus-bg: $gray-100;
$input-focus-border-color: $gray-500;

$input-group-addon-border-color: $border-color;
$input-group-addon-bg: $gray-100;
$input-group-addon-color: $gray-500;

.form-group {
  position: relative;

  label {
    color: $gray-700;
    margin-bottom: 10px;
    min-height: 16px;
  }

  textarea {
    @include scrollbar();
  }

  &.form-group-icon-right {
    .form-control {
      padding-right: 58px;
    }

    > i {
      position: absolute;
      top: 52px;
      right: 20px;
      font-size: 20px;
      color: $input-group-addon-color;
    }
  }

}

.form-control:not(textarea) {
  height: $input-height!important;
}

input, textarea {
  outline: none;
  box-shadow:none !important;
  }


  