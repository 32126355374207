@import "../variables";
@import "../mixins";

$dropdown-border-color: $border-color;
$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: $blue;
$dropdown-link-active-color: $blue;
$dropdown-link-hover-bg: $background-color;

.dropdown-toggle {
  &::after {
    display: none !important;
  }
}


.dropdown-menu {
  border-color: $border-color;
}
