$fontpath: "../fonts/";

//font-weight:
//100 - Thin
//200 - Extra Light, Ultra Light
//300 - Light
//400 - Normal, Book, Regular
//500 - Medium
//600 - Semi Bold, Demi Bold
//700 - Bold
//800 - Extra Bold, Ultra Bold
//900 - Black, Heavy

@font-face {
  font-family: 'Circular';
  src: url('#{$fontpath}CircularStd-Book.woff2') format('woff2');
  src: url('#{$fontpath}CircularStd-Book.woff') format('woff'), 
  url('#{$fontpath}CircularStd-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('#{$fontpath}CircularStd-Medium.woff2') format('woff2');
  src: url('#{$fontpath}CircularStd-Medium.woff') format('woff'), url('#{$fontpath}CircularStd-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Circular';
  src: url('#{$fontpath}CircularStd-Bold.woff2') format('woff2');
  src: url('#{$fontpath}CircularStd-Bold.woff') format('woff'), url('#{$fontpath}CircularStd-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$fontpath}Nunito-Black.woff2') format('woff2'),
      url('#{$fontpath}Nunito-Black.woff') format('woff'),
      url('#{$fontpath}Nunito-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$fontpath}Nunito-Bold.woff2') format('woff2'),
      url('#{$fontpath}Nunito-Bold.woff') format('woff'),
      url('#{$fontpath}Nunito-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('#{$fontpath}Nunito-Regular.woff2') format('woff2'),
      url('#{$fontpath}Nunito-Regular.woff') format('woff'),
      url('#{$fontpath}Nunito-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
