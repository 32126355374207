/*#region Rem */
/*#endregion */
/*#region Responsive Templates */
/*#endregion */
@import url("../icons/iconfont.css");
@font-face {
  font-family: 'Circular';
  src: url("../fonts/CircularStd-Book.woff2") format("woff2");
  src: url("../fonts/CircularStd-Book.woff") format("woff"), url("../fonts/CircularStd-Book.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Circular';
  src: url("../fonts/CircularStd-Medium.woff2") format("woff2");
  src: url("../fonts/CircularStd-Medium.woff") format("woff"), url("../fonts/CircularStd-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Circular';
  src: url("../fonts/CircularStd-Bold.woff2") format("woff2");
  src: url("../fonts/CircularStd-Bold.woff") format("woff"), url("../fonts/CircularStd-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Black.woff2") format("woff2"), url("../fonts/Nunito-Black.woff") format("woff"), url("../fonts/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Bold.woff2") format("woff2"), url("../fonts/Nunito-Bold.woff") format("woff"), url("../fonts/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Nunito';
  src: url("../fonts/Nunito-Regular.woff2") format("woff2"), url("../fonts/Nunito-Regular.woff") format("woff"), url("../fonts/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

:root {
  --blue: #0BAFFF;
  --indigo: #6610f2;
  --purple: #7550E9;
  --pink: #ED63D2;
  --red: #F03F3F;
  --orange: #FF7D50;
  --yellow: #FFC213;
  --green: #2DCA73;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #8391A7;
  --gray-dark: #3C4858;
  --primary: #0BAFFF;
  --secondary: #8391A7;
  --success: #2DCA73;
  --info: #17a2b8;
  --warning: #FFC213;
  --danger: #F03F3F;
  --light: #F9FAFC;
  --dark: #3C4858;
  --white: #fff;
  --black: #000;
  --gray-100: #F9FAFC;
  --gray-200: #F1F2F6;
  --gray-300: #E5E9F2;
  --gray-400: #C0CCDA;
  --gray-500: #8492A6;
  --gray-600: #8391A7;
  --gray-700: #5A6679;
  --gray-800: #3C4858;
  --gray-900: #1C2C40;
  --blue: #0BAFFF;
  --purple: #7550E9;
  --orange: #FF7D50;
  --pink: #ED63D2;
  --green: #2DCA73;
  --red: #F03F3F;
  --yellow: #FFC213;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1C2C40;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0BAFFF;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #007fbe;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #8391A7;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 32 px; }

h2, .h2 {
  font-size: 24 px; }

h3, .h3 {
  font-size: 18 px; }

h4, .h4 {
  font-size: 16 px; }

h5, .h5 {
  font-size: 13 px; }

h6, .h6 {
  font-size: 10 px; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #8391A7; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #E5E9F2;
  border-radius: 0.5rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #8391A7; }

code {
  font-size: 87.5%;
  color: #ED63D2;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #1C2C40;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #1C2C40; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #1C2C40; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #E5E9F2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #E5E9F2; }
  .table tbody + tbody {
    border-top: 2px solid #E5E9F2; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #E5E9F2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #E5E9F2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #1C2C40;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #bbe9ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #80d5ff; }

.table-hover .table-primary:hover {
  background-color: #a2e1ff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a2e1ff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dce0e6; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #bfc6d1; }

.table-hover .table-secondary:hover {
  background-color: #cdd3db; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cdd3db; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c4f0d8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #92e3b6; }

.table-hover .table-success:hover {
  background-color: #b0ebcb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b0ebcb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeebd; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf84; }

.table-hover .table-warning:hover {
  background-color: #ffe7a4; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe7a4; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbc9c9; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f79b9b; }

.table-hover .table-danger:hover {
  background-color: #f9b1b1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f9b1b1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfefe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fcfcfd; }

.table-hover .table-light:hover {
  background-color: #ecf6f6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ecf6f6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8ccd0; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #9aa0a8; }

.table-hover .table-dark:hover {
  background-color: #babfc4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #babfc4; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fdfefe; }

.table-gray-100 th,
.table-gray-100 td,
.table-gray-100 thead th,
.table-gray-100 tbody + tbody {
  border-color: #fcfcfd; }

.table-hover .table-gray-100:hover {
  background-color: #ecf6f6; }
  .table-hover .table-gray-100:hover > td,
  .table-hover .table-gray-100:hover > th {
    background-color: #ecf6f6; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #fbfbfc; }

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f8f8fa; }

.table-hover .table-gray-200:hover {
  background-color: #ececf1; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #ececf1; }

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #f8f9fb; }

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #f1f4f8; }

.table-hover .table-gray-300:hover {
  background-color: #e8ebf2; }
  .table-hover .table-gray-300:hover > td,
  .table-hover .table-gray-300:hover > th {
    background-color: #e8ebf2; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #edf1f5; }

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #dee4ec; }

.table-hover .table-gray-400:hover {
  background-color: #dde4ec; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #dde4ec; }

.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #dde0e6; }

.table-gray-500 th,
.table-gray-500 td,
.table-gray-500 thead th,
.table-gray-500 tbody + tbody {
  border-color: #bfc6d1; }

.table-hover .table-gray-500:hover {
  background-color: #ced3db; }
  .table-hover .table-gray-500:hover > td,
  .table-hover .table-gray-500:hover > th {
    background-color: #ced3db; }

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #dce0e6; }

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #bfc6d1; }

.table-hover .table-gray-600:hover {
  background-color: #cdd3db; }
  .table-hover .table-gray-600:hover > td,
  .table-hover .table-gray-600:hover > th {
    background-color: #cdd3db; }

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #d1d4d9; }

.table-gray-700 th,
.table-gray-700 td,
.table-gray-700 thead th,
.table-gray-700 tbody + tbody {
  border-color: #a9afb9; }

.table-hover .table-gray-700:hover {
  background-color: #c3c7cd; }
  .table-hover .table-gray-700:hover > td,
  .table-hover .table-gray-700:hover > th {
    background-color: #c3c7cd; }

.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #c8ccd0; }

.table-gray-800 th,
.table-gray-800 td,
.table-gray-800 thead th,
.table-gray-800 tbody + tbody {
  border-color: #9aa0a8; }

.table-hover .table-gray-800:hover {
  background-color: #babfc4; }
  .table-hover .table-gray-800:hover > td,
  .table-hover .table-gray-800:hover > th {
    background-color: #babfc4; }

.table-gray-900,
.table-gray-900 > th,
.table-gray-900 > td {
  background-color: #bfc4ca; }

.table-gray-900 th,
.table-gray-900 td,
.table-gray-900 thead th,
.table-gray-900 tbody + tbody {
  border-color: #89919c; }

.table-hover .table-gray-900:hover {
  background-color: #b1b7be; }
  .table-hover .table-gray-900:hover > td,
  .table-hover .table-gray-900:hover > th {
    background-color: #b1b7be; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #bbe9ff; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #80d5ff; }

.table-hover .table-blue:hover {
  background-color: #a2e1ff; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #a2e1ff; }

.table-purple,
.table-purple > th,
.table-purple > td {
  background-color: #d8cef9; }

.table-purple th,
.table-purple td,
.table-purple thead th,
.table-purple tbody + tbody {
  border-color: #b7a4f4; }

.table-hover .table-purple:hover {
  background-color: #c6b7f6; }
  .table-hover .table-purple:hover > td,
  .table-hover .table-purple:hover > th {
    background-color: #c6b7f6; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffdbce; }

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #ffbba4; }

.table-hover .table-orange:hover {
  background-color: #ffc8b5; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffc8b5; }

.table-pink,
.table-pink > th,
.table-pink > td {
  background-color: #fad3f2; }

.table-pink th,
.table-pink td,
.table-pink thead th,
.table-pink tbody + tbody {
  border-color: #f6aee8; }

.table-hover .table-pink:hover {
  background-color: #f7bceb; }
  .table-hover .table-pink:hover > td,
  .table-hover .table-pink:hover > th {
    background-color: #f7bceb; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #c4f0d8; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #92e3b6; }

.table-hover .table-green:hover {
  background-color: #b0ebcb; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #b0ebcb; }

.table-red,
.table-red > th,
.table-red > td {
  background-color: #fbc9c9; }

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #f79b9b; }

.table-hover .table-red:hover {
  background-color: #f9b1b1; }
  .table-hover .table-red:hover > td,
  .table-hover .table-red:hover > th {
    background-color: #f9b1b1; }

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #ffeebd; }

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #ffdf84; }

.table-hover .table-yellow:hover {
  background-color: #ffe7a4; }
  .table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #ffe7a4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #3C4858;
  border-color: #4c5b6f; }

.table .thead-light th {
  color: #5A6679;
  background-color: #F1F2F6;
  border-color: #E5E9F2; }

.table-dark {
  color: #fff;
  background-color: #3C4858; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #4c5b6f; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5A6679;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #C0CCDA;
  border-radius: 0.5rem;
  transition: all border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out ease;
  transition-delay: 0; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #5A6679; }
  .form-control:focus {
    color: #5A6679;
    background-color: #fff;
    border-color: #8bd9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.25); }
  .form-control::placeholder {
    color: #8391A7;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #F1F2F6;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #5A6679;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #1C2C40;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #8391A7; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #2DCA73; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(45, 202, 115, 0.9);
  border-radius: 0.5rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #2DCA73;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232DCA73' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #2DCA73;
    box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #2DCA73;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233C4858' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232DCA73' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #2DCA73;
    box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #2DCA73; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #2DCA73; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #2DCA73; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #52d88e;
  background-color: #52d88e; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #2DCA73; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #2DCA73; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #2DCA73;
  box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F03F3F; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(240, 63, 63, 0.9);
  border-radius: 0.5rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F03F3F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F03F3F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F03F3F' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #F03F3F;
    box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F03F3F;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233C4858' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F03F3F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F03F3F' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F03F3F;
    box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F03F3F; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F03F3F; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F03F3F; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f46e6e;
  background-color: #f46e6e; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F03F3F; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F03F3F; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F03F3F;
  box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #1C2C40;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: all color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out ease;
  transition-delay: 0; }
  .btn:hover {
    color: #1C2C40;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0BAFFF;
  border-color: #0BAFFF; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0099e4;
    border-color: #0091d7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0099e4;
    border-color: #0091d7;
    box-shadow: 0 0 0 0.2rem rgba(48, 187, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0091d7;
    border-color: #0088ca; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 187, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #8391A7;
  border-color: #8391A7; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #6d7d97;
    border-color: #677790; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #6d7d97;
    border-color: #677790;
    box-shadow: 0 0 0 0.2rem rgba(150, 162, 180, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #677790;
    border-color: #617189; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 162, 180, 0.5); }

.btn-success {
  color: #fff;
  background-color: #2DCA73;
  border-color: #2DCA73; }
  .btn-success:hover {
    color: #fff;
    background-color: #26ab61;
    border-color: #24a05b; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #26ab61;
    border-color: #24a05b;
    box-shadow: 0 0 0 0.2rem rgba(77, 210, 136, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #24a05b;
    border-color: #219655; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 210, 136, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #1C2C40;
  background-color: #FFC213;
  border-color: #FFC213; }
  .btn-warning:hover {
    color: #1C2C40;
    background-color: #ecaf00;
    border-color: #dfa500; }
  .btn-warning:focus, .btn-warning.focus {
    color: #1C2C40;
    background-color: #ecaf00;
    border-color: #dfa500;
    box-shadow: 0 0 0 0.2rem rgba(221, 172, 26, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #1C2C40;
    background-color: #dfa500;
    border-color: #d29c00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 172, 26, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #F03F3F;
  border-color: #F03F3F; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ed1c1c;
    border-color: #ea1212; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ed1c1c;
    border-color: #ea1212;
    box-shadow: 0 0 0 0.2rem rgba(242, 92, 92, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1212;
    border-color: #de1111; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 92, 92, 0.5); }

.btn-light {
  color: #1C2C40;
  background-color: #F9FAFC;
  border-color: #F9FAFC; }
  .btn-light:hover {
    color: #1C2C40;
    background-color: #e0e5ef;
    border-color: #d7deeb; }
  .btn-light:focus, .btn-light.focus {
    color: #1C2C40;
    background-color: #e0e5ef;
    border-color: #d7deeb;
    box-shadow: 0 0 0 0.2rem rgba(216, 219, 224, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #1C2C40;
    background-color: #d7deeb;
    border-color: #cfd7e7; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 219, 224, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3C4858;
  border-color: #3C4858; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2c3541;
    border-color: #272f3a; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2c3541;
    border-color: #272f3a;
    box-shadow: 0 0 0 0.2rem rgba(89, 99, 113, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #272f3a;
    border-color: #222932; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 99, 113, 0.5); }

.btn-white {
  color: #1C2C40;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #1C2C40;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #1C2C40;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(221, 223, 226, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #1C2C40;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #1C2C40;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 223, 226, 0.5); }

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000; }
  .btn-black:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-black:focus, .btn-black.focus {
    color: #fff;
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }
  .btn-black.disabled, .btn-black:disabled {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
  .show > .btn-black.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5); }

.btn-gray-100 {
  color: #1C2C40;
  background-color: #F9FAFC;
  border-color: #F9FAFC; }
  .btn-gray-100:hover {
    color: #1C2C40;
    background-color: #e0e5ef;
    border-color: #d7deeb; }
  .btn-gray-100:focus, .btn-gray-100.focus {
    color: #1C2C40;
    background-color: #e0e5ef;
    border-color: #d7deeb;
    box-shadow: 0 0 0 0.2rem rgba(216, 219, 224, 0.5); }
  .btn-gray-100.disabled, .btn-gray-100:disabled {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
  .btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-gray-100.dropdown-toggle {
    color: #1C2C40;
    background-color: #d7deeb;
    border-color: #cfd7e7; }
    .btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 219, 224, 0.5); }

.btn-gray-200 {
  color: #1C2C40;
  background-color: #F1F2F6;
  border-color: #F1F2F6; }
  .btn-gray-200:hover {
    color: #1C2C40;
    background-color: #dadce7;
    border-color: #d2d5e2; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    color: #1C2C40;
    background-color: #dadce7;
    border-color: #d2d5e2;
    box-shadow: 0 0 0 0.2rem rgba(209, 212, 219, 0.5); }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    color: #1C2C40;
    background-color: #F1F2F6;
    border-color: #F1F2F6; }
  .btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-gray-200.dropdown-toggle {
    color: #1C2C40;
    background-color: #d2d5e2;
    border-color: #cacedd; }
    .btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(209, 212, 219, 0.5); }

.btn-gray-300 {
  color: #1C2C40;
  background-color: #E5E9F2;
  border-color: #E5E9F2; }
  .btn-gray-300:hover {
    color: #1C2C40;
    background-color: #ccd3e5;
    border-color: #c3cce1; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    color: #1C2C40;
    background-color: #ccd3e5;
    border-color: #c3cce1;
    box-shadow: 0 0 0 0.2rem rgba(199, 205, 215, 0.5); }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    color: #1C2C40;
    background-color: #E5E9F2;
    border-color: #E5E9F2; }
  .btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-gray-300.dropdown-toggle {
    color: #1C2C40;
    background-color: #c3cce1;
    border-color: #bbc5dd; }
    .btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(199, 205, 215, 0.5); }

.btn-gray-400 {
  color: #1C2C40;
  background-color: #C0CCDA;
  border-color: #C0CCDA; }
  .btn-gray-400:hover {
    color: #1C2C40;
    background-color: #a8b8cc;
    border-color: #a0b2c7; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    color: #1C2C40;
    background-color: #a8b8cc;
    border-color: #a0b2c7;
    box-shadow: 0 0 0 0.2rem rgba(167, 180, 195, 0.5); }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    color: #1C2C40;
    background-color: #C0CCDA;
    border-color: #C0CCDA; }
  .btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-gray-400.dropdown-toggle {
    color: #1C2C40;
    background-color: #a0b2c7;
    border-color: #98abc2; }
    .btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(167, 180, 195, 0.5); }

.btn-gray-500 {
  color: #fff;
  background-color: #8492A6;
  border-color: #8492A6; }
  .btn-gray-500:hover {
    color: #fff;
    background-color: #6e7e96;
    border-color: #68788f; }
  .btn-gray-500:focus, .btn-gray-500.focus {
    color: #fff;
    background-color: #6e7e96;
    border-color: #68788f;
    box-shadow: 0 0 0 0.2rem rgba(150, 162, 179, 0.5); }
  .btn-gray-500.disabled, .btn-gray-500:disabled {
    color: #fff;
    background-color: #8492A6;
    border-color: #8492A6; }
  .btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #68788f;
    border-color: #627288; }
    .btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 162, 179, 0.5); }

.btn-gray-600 {
  color: #fff;
  background-color: #8391A7;
  border-color: #8391A7; }
  .btn-gray-600:hover {
    color: #fff;
    background-color: #6d7d97;
    border-color: #677790; }
  .btn-gray-600:focus, .btn-gray-600.focus {
    color: #fff;
    background-color: #6d7d97;
    border-color: #677790;
    box-shadow: 0 0 0 0.2rem rgba(150, 162, 180, 0.5); }
  .btn-gray-600.disabled, .btn-gray-600:disabled {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
  .btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #677790;
    border-color: #617189; }
    .btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 162, 180, 0.5); }

.btn-gray-700 {
  color: #fff;
  background-color: #5A6679;
  border-color: #5A6679; }
  .btn-gray-700:hover {
    color: #fff;
    background-color: #4a5463;
    border-color: #444d5c; }
  .btn-gray-700:focus, .btn-gray-700.focus {
    color: #fff;
    background-color: #4a5463;
    border-color: #444d5c;
    box-shadow: 0 0 0 0.2rem rgba(115, 125, 141, 0.5); }
  .btn-gray-700.disabled, .btn-gray-700:disabled {
    color: #fff;
    background-color: #5A6679;
    border-color: #5A6679; }
  .btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #444d5c;
    border-color: #3f4754; }
    .btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(115, 125, 141, 0.5); }

.btn-gray-800 {
  color: #fff;
  background-color: #3C4858;
  border-color: #3C4858; }
  .btn-gray-800:hover {
    color: #fff;
    background-color: #2c3541;
    border-color: #272f3a; }
  .btn-gray-800:focus, .btn-gray-800.focus {
    color: #fff;
    background-color: #2c3541;
    border-color: #272f3a;
    box-shadow: 0 0 0 0.2rem rgba(89, 99, 113, 0.5); }
  .btn-gray-800.disabled, .btn-gray-800:disabled {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
  .btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #272f3a;
    border-color: #222932; }
    .btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(89, 99, 113, 0.5); }

.btn-gray-900 {
  color: #fff;
  background-color: #1C2C40;
  border-color: #1C2C40; }
  .btn-gray-900:hover {
    color: #fff;
    background-color: #101a25;
    border-color: #0c141d; }
  .btn-gray-900:focus, .btn-gray-900.focus {
    color: #fff;
    background-color: #101a25;
    border-color: #0c141d;
    box-shadow: 0 0 0 0.2rem rgba(62, 76, 93, 0.5); }
  .btn-gray-900.disabled, .btn-gray-900:disabled {
    color: #fff;
    background-color: #1C2C40;
    border-color: #1C2C40; }
  .btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #0c141d;
    border-color: #090e14; }
    .btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 76, 93, 0.5); }

.btn-blue {
  color: #fff;
  background-color: #0BAFFF;
  border-color: #0BAFFF; }
  .btn-blue:hover {
    color: #fff;
    background-color: #0099e4;
    border-color: #0091d7; }
  .btn-blue:focus, .btn-blue.focus {
    color: #fff;
    background-color: #0099e4;
    border-color: #0091d7;
    box-shadow: 0 0 0 0.2rem rgba(48, 187, 255, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #0091d7;
    border-color: #0088ca; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(48, 187, 255, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #7550E9;
  border-color: #7550E9; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5a2ee5;
    border-color: #5123e3; }
  .btn-purple:focus, .btn-purple.focus {
    color: #fff;
    background-color: #5a2ee5;
    border-color: #5123e3;
    box-shadow: 0 0 0 0.2rem rgba(138, 106, 236, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #7550E9;
    border-color: #7550E9; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #5123e3;
    border-color: #4b1cdd; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 106, 236, 0.5); }

.btn-orange {
  color: #1C2C40;
  background-color: #FF7D50;
  border-color: #FF7D50; }
  .btn-orange:hover {
    color: #fff;
    background-color: #ff612a;
    border-color: #ff571d; }
  .btn-orange:focus, .btn-orange.focus {
    color: #fff;
    background-color: #ff612a;
    border-color: #ff571d;
    box-shadow: 0 0 0 0.2rem rgba(221, 113, 78, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #1C2C40;
    background-color: #FF7D50;
    border-color: #FF7D50; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ff571d;
    border-color: #ff4e10; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 113, 78, 0.5); }

.btn-pink {
  color: #1C2C40;
  background-color: #ED63D2;
  border-color: #ED63D2; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e941c8;
    border-color: #e835c5; }
  .btn-pink:focus, .btn-pink.focus {
    color: #fff;
    background-color: #e941c8;
    border-color: #e835c5;
    box-shadow: 0 0 0 0.2rem rgba(206, 91, 188, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #1C2C40;
    background-color: #ED63D2;
    border-color: #ED63D2; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e835c5;
    border-color: #e62ac2; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(206, 91, 188, 0.5); }

.btn-green {
  color: #fff;
  background-color: #2DCA73;
  border-color: #2DCA73; }
  .btn-green:hover {
    color: #fff;
    background-color: #26ab61;
    border-color: #24a05b; }
  .btn-green:focus, .btn-green.focus {
    color: #fff;
    background-color: #26ab61;
    border-color: #24a05b;
    box-shadow: 0 0 0 0.2rem rgba(77, 210, 136, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #24a05b;
    border-color: #219655; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(77, 210, 136, 0.5); }

.btn-red {
  color: #fff;
  background-color: #F03F3F;
  border-color: #F03F3F; }
  .btn-red:hover {
    color: #fff;
    background-color: #ed1c1c;
    border-color: #ea1212; }
  .btn-red:focus, .btn-red.focus {
    color: #fff;
    background-color: #ed1c1c;
    border-color: #ea1212;
    box-shadow: 0 0 0 0.2rem rgba(242, 92, 92, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #ea1212;
    border-color: #de1111; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 92, 92, 0.5); }

.btn-yellow {
  color: #1C2C40;
  background-color: #FFC213;
  border-color: #FFC213; }
  .btn-yellow:hover {
    color: #1C2C40;
    background-color: #ecaf00;
    border-color: #dfa500; }
  .btn-yellow:focus, .btn-yellow.focus {
    color: #1C2C40;
    background-color: #ecaf00;
    border-color: #dfa500;
    box-shadow: 0 0 0 0.2rem rgba(221, 172, 26, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #1C2C40;
    background-color: #dfa500;
    border-color: #d29c00; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(221, 172, 26, 0.5); }

.btn-outline-primary {
  color: #0BAFFF;
  border-color: #0BAFFF; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0BAFFF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.5); }

.btn-outline-secondary {
  color: #8391A7;
  border-color: #8391A7; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(131, 145, 167, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #8391A7;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(131, 145, 167, 0.5); }

.btn-outline-success {
  color: #2DCA73;
  border-color: #2DCA73; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #2DCA73;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #FFC213;
  border-color: #FFC213; }
  .btn-outline-warning:hover {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 194, 19, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFC213;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 194, 19, 0.5); }

.btn-outline-danger {
  color: #F03F3F;
  border-color: #F03F3F; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F03F3F;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.5); }

.btn-outline-light {
  color: #F9FAFC;
  border-color: #F9FAFC; }
  .btn-outline-light:hover {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F9FAFC;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5); }

.btn-outline-dark {
  color: #3C4858;
  border-color: #3C4858; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 72, 88, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3C4858;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 72, 88, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #1C2C40;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #1C2C40;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-black {
  color: #000;
  border-color: #000; }
  .btn-outline-black:hover {
    color: #fff;
    background-color: #000;
    border-color: #000; }
  .btn-outline-black:focus, .btn-outline-black.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-black.disabled, .btn-outline-black:disabled {
    color: #000;
    background-color: transparent; }
  .btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
  .show > .btn-outline-black.dropdown-toggle {
    color: #fff;
    background-color: #000;
    border-color: #000; }
    .btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-black.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-gray-100 {
  color: #F9FAFC;
  border-color: #F9FAFC; }
  .btn-outline-gray-100:hover {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
  .btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
    box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5); }
  .btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
    color: #F9FAFC;
    background-color: transparent; }
  .btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-100.dropdown-toggle {
    color: #1C2C40;
    background-color: #F9FAFC;
    border-color: #F9FAFC; }
    .btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(249, 250, 252, 0.5); }

.btn-outline-gray-200 {
  color: #F1F2F6;
  border-color: #F1F2F6; }
  .btn-outline-gray-200:hover {
    color: #1C2C40;
    background-color: #F1F2F6;
    border-color: #F1F2F6; }
  .btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
    box-shadow: 0 0 0 0.2rem rgba(241, 242, 246, 0.5); }
  .btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
    color: #F1F2F6;
    background-color: transparent; }
  .btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-200.dropdown-toggle {
    color: #1C2C40;
    background-color: #F1F2F6;
    border-color: #F1F2F6; }
    .btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(241, 242, 246, 0.5); }

.btn-outline-gray-300 {
  color: #E5E9F2;
  border-color: #E5E9F2; }
  .btn-outline-gray-300:hover {
    color: #1C2C40;
    background-color: #E5E9F2;
    border-color: #E5E9F2; }
  .btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
    box-shadow: 0 0 0 0.2rem rgba(229, 233, 242, 0.5); }
  .btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
    color: #E5E9F2;
    background-color: transparent; }
  .btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-300.dropdown-toggle {
    color: #1C2C40;
    background-color: #E5E9F2;
    border-color: #E5E9F2; }
    .btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(229, 233, 242, 0.5); }

.btn-outline-gray-400 {
  color: #C0CCDA;
  border-color: #C0CCDA; }
  .btn-outline-gray-400:hover {
    color: #1C2C40;
    background-color: #C0CCDA;
    border-color: #C0CCDA; }
  .btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
    box-shadow: 0 0 0 0.2rem rgba(192, 204, 218, 0.5); }
  .btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
    color: #C0CCDA;
    background-color: transparent; }
  .btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-400.dropdown-toggle {
    color: #1C2C40;
    background-color: #C0CCDA;
    border-color: #C0CCDA; }
    .btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(192, 204, 218, 0.5); }

.btn-outline-gray-500 {
  color: #8492A6;
  border-color: #8492A6; }
  .btn-outline-gray-500:hover {
    color: #fff;
    background-color: #8492A6;
    border-color: #8492A6; }
  .btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
    box-shadow: 0 0 0 0.2rem rgba(132, 146, 166, 0.5); }
  .btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
    color: #8492A6;
    background-color: transparent; }
  .btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-500.dropdown-toggle {
    color: #fff;
    background-color: #8492A6;
    border-color: #8492A6; }
    .btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 146, 166, 0.5); }

.btn-outline-gray-600 {
  color: #8391A7;
  border-color: #8391A7; }
  .btn-outline-gray-600:hover {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
  .btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
    box-shadow: 0 0 0 0.2rem rgba(131, 145, 167, 0.5); }
  .btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
    color: #8391A7;
    background-color: transparent; }
  .btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #8391A7;
    border-color: #8391A7; }
    .btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(131, 145, 167, 0.5); }

.btn-outline-gray-700 {
  color: #5A6679;
  border-color: #5A6679; }
  .btn-outline-gray-700:hover {
    color: #fff;
    background-color: #5A6679;
    border-color: #5A6679; }
  .btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 102, 121, 0.5); }
  .btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
    color: #5A6679;
    background-color: transparent; }
  .btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #5A6679;
    border-color: #5A6679; }
    .btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(90, 102, 121, 0.5); }

.btn-outline-gray-800 {
  color: #3C4858;
  border-color: #3C4858; }
  .btn-outline-gray-800:hover {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
  .btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
    box-shadow: 0 0 0 0.2rem rgba(60, 72, 88, 0.5); }
  .btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
    color: #3C4858;
    background-color: transparent; }
  .btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #3C4858;
    border-color: #3C4858; }
    .btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(60, 72, 88, 0.5); }

.btn-outline-gray-900 {
  color: #1C2C40;
  border-color: #1C2C40; }
  .btn-outline-gray-900:hover {
    color: #fff;
    background-color: #1C2C40;
    border-color: #1C2C40; }
  .btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 44, 64, 0.5); }
  .btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
    color: #1C2C40;
    background-color: transparent; }
  .btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-900.dropdown-toggle {
    color: #fff;
    background-color: #1C2C40;
    border-color: #1C2C40; }
    .btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(28, 44, 64, 0.5); }

.btn-outline-blue {
  color: #0BAFFF;
  border-color: #0BAFFF; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #0BAFFF;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #0BAFFF;
    border-color: #0BAFFF; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.5); }

.btn-outline-purple {
  color: #7550E9;
  border-color: #7550E9; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #7550E9;
    border-color: #7550E9; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(117, 80, 233, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #7550E9;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #7550E9;
    border-color: #7550E9; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(117, 80, 233, 0.5); }

.btn-outline-orange {
  color: #FF7D50;
  border-color: #FF7D50; }
  .btn-outline-orange:hover {
    color: #1C2C40;
    background-color: #FF7D50;
    border-color: #FF7D50; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 125, 80, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #FF7D50;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #1C2C40;
    background-color: #FF7D50;
    border-color: #FF7D50; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 125, 80, 0.5); }

.btn-outline-pink {
  color: #ED63D2;
  border-color: #ED63D2; }
  .btn-outline-pink:hover {
    color: #1C2C40;
    background-color: #ED63D2;
    border-color: #ED63D2; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(237, 99, 210, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #ED63D2;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #1C2C40;
    background-color: #ED63D2;
    border-color: #ED63D2; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(237, 99, 210, 0.5); }

.btn-outline-green {
  color: #2DCA73;
  border-color: #2DCA73; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #2DCA73;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #2DCA73;
    border-color: #2DCA73; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(45, 202, 115, 0.5); }

.btn-outline-red {
  color: #F03F3F;
  border-color: #F03F3F; }
  .btn-outline-red:hover {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #F03F3F;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #F03F3F;
    border-color: #F03F3F; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 63, 63, 0.5); }

.btn-outline-yellow {
  color: #FFC213;
  border-color: #FFC213; }
  .btn-outline-yellow:hover {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 194, 19, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #FFC213;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #1C2C40;
    background-color: #FFC213;
    border-color: #FFC213; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 194, 19, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0BAFFF;
  text-decoration: none; }
  .btn-link:hover {
    color: #007fbe;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #8391A7;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: all opacity 0.15s linear ease;
  transition-delay: 0; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: all height 0.35s ease ease;
  transition-delay: 0; }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1C2C40;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #F1F2F6; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #1C2C40;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #14202e;
    text-decoration: none;
    background-color: #F9FAFC; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0BAFFF; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #8391A7;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8391A7;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #1C2C40; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5A6679;
  text-align: center;
  white-space: nowrap;
  background-color: #F1F2F6;
  border: 1px solid #C0CCDA;
  border-radius: 0.5rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #8391A7;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #E5E9F2; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #F1F2F6 #F1F2F6 #E5E9F2; }
    .nav-tabs .nav-link.disabled {
      color: #8391A7;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #5A6679;
    background-color: #fff;
    border-color: #E5E9F2 #E5E9F2 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.5rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0BAFFF; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.5rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.5rem - 1px) calc(0.5rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.5rem - 1px) calc(0.5rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.5rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0BAFFF;
  background-color: #fff;
  border: 1px solid #E5E9F2; }
  .page-link:hover {
    z-index: 2;
    color: #007fbe;
    text-decoration: none;
    background-color: #F1F2F6;
    border-color: #E5E9F2; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(11, 175, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0BAFFF;
  border-color: #0BAFFF; }

.page-item.disabled .page-link {
  color: #8391A7;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #E5E9F2; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.5rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #065b85;
  background-color: #ceefff;
  border-color: #bbe9ff; }
  .alert-primary hr {
    border-top-color: #a2e1ff; }
  .alert-primary .alert-link {
    color: #043a54; }

.alert-secondary {
  color: #444b57;
  background-color: #e6e9ed;
  border-color: #dce0e6; }
  .alert-secondary hr {
    border-top-color: #cdd3db; }
  .alert-secondary .alert-link {
    color: #2e323a; }

.alert-success {
  color: #17693c;
  background-color: #d5f4e3;
  border-color: #c4f0d8; }
  .alert-success hr {
    border-top-color: #b0ebcb; }
  .alert-success .alert-link {
    color: #0e3f24; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #85650a;
  background-color: #fff3d0;
  border-color: #ffeebd; }
  .alert-warning hr {
    border-top-color: #ffe7a4; }
  .alert-warning .alert-link {
    color: #564106; }

.alert-danger {
  color: #7d2121;
  background-color: #fcd9d9;
  border-color: #fbc9c9; }
  .alert-danger hr {
    border-top-color: #f9b1b1; }
  .alert-danger .alert-link {
    color: #551616; }

.alert-light {
  color: #818283;
  background-color: #fefefe;
  border-color: #fdfefe; }
  .alert-light hr {
    border-top-color: #ecf6f6; }
  .alert-light .alert-link {
    color: #686969; }

.alert-dark {
  color: #1f252e;
  background-color: #d8dade;
  border-color: #c8ccd0; }
  .alert-dark hr {
    border-top-color: #babfc4; }
  .alert-dark .alert-link {
    color: #0a0c10; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8; }
  .alert-black hr {
    border-top-color: #ababab; }
  .alert-black .alert-link {
    color: black; }

.alert-gray-100 {
  color: #818283;
  background-color: #fefefe;
  border-color: #fdfefe; }
  .alert-gray-100 hr {
    border-top-color: #ecf6f6; }
  .alert-gray-100 .alert-link {
    color: #686969; }

.alert-gray-200 {
  color: #7d7e80;
  background-color: #fcfcfd;
  border-color: #fbfbfc; }
  .alert-gray-200 hr {
    border-top-color: #ececf1; }
  .alert-gray-200 .alert-link {
    color: #646566; }

.alert-gray-300 {
  color: #77797e;
  background-color: #fafbfc;
  border-color: #f8f9fb; }
  .alert-gray-300 hr {
    border-top-color: #e8ebf2; }
  .alert-gray-300 .alert-link {
    color: #5e6064; }

.alert-gray-400 {
  color: #646a71;
  background-color: #f2f5f8;
  border-color: #edf1f5; }
  .alert-gray-400 hr {
    border-top-color: #dde4ec; }
  .alert-gray-400 .alert-link {
    color: #4c5156; }

.alert-gray-500 {
  color: #454c56;
  background-color: #e6e9ed;
  border-color: #dde0e6; }
  .alert-gray-500 hr {
    border-top-color: #ced3db; }
  .alert-gray-500 .alert-link {
    color: #2e333a; }

.alert-gray-600 {
  color: #444b57;
  background-color: #e6e9ed;
  border-color: #dce0e6; }
  .alert-gray-600 hr {
    border-top-color: #cdd3db; }
  .alert-gray-600 .alert-link {
    color: #2e323a; }

.alert-gray-700 {
  color: #2f353f;
  background-color: #dee0e4;
  border-color: #d1d4d9; }
  .alert-gray-700 hr {
    border-top-color: #c3c7cd; }
  .alert-gray-700 .alert-link {
    color: #191c22; }

.alert-gray-800 {
  color: #1f252e;
  background-color: #d8dade;
  border-color: #c8ccd0; }
  .alert-gray-800 hr {
    border-top-color: #babfc4; }
  .alert-gray-800 .alert-link {
    color: #0a0c10; }

.alert-gray-900 {
  color: #0f1721;
  background-color: #d2d5d9;
  border-color: #bfc4ca; }
  .alert-gray-900 hr {
    border-top-color: #b1b7be; }
  .alert-gray-900 .alert-link {
    color: black; }

.alert-blue {
  color: #065b85;
  background-color: #ceefff;
  border-color: #bbe9ff; }
  .alert-blue hr {
    border-top-color: #a2e1ff; }
  .alert-blue .alert-link {
    color: #043a54; }

.alert-purple {
  color: #3d2a79;
  background-color: #e3dcfb;
  border-color: #d8cef9; }
  .alert-purple hr {
    border-top-color: #c6b7f6; }
  .alert-purple .alert-link {
    color: #2a1d53; }

.alert-orange {
  color: #85412a;
  background-color: #ffe5dc;
  border-color: #ffdbce; }
  .alert-orange hr {
    border-top-color: #ffc8b5; }
  .alert-orange .alert-link {
    color: #5e2e1e; }

.alert-pink {
  color: #7b336d;
  background-color: #fbe0f6;
  border-color: #fad3f2; }
  .alert-pink hr {
    border-top-color: #f7bceb; }
  .alert-pink .alert-link {
    color: #57244d; }

.alert-green {
  color: #17693c;
  background-color: #d5f4e3;
  border-color: #c4f0d8; }
  .alert-green hr {
    border-top-color: #b0ebcb; }
  .alert-green .alert-link {
    color: #0e3f24; }

.alert-red {
  color: #7d2121;
  background-color: #fcd9d9;
  border-color: #fbc9c9; }
  .alert-red hr {
    border-top-color: #f9b1b1; }
  .alert-red .alert-link {
    color: #551616; }

.alert-yellow {
  color: #85650a;
  background-color: #fff3d0;
  border-color: #ffeebd; }
  .alert-yellow hr {
    border-top-color: #ffe7a4; }
  .alert-yellow .alert-link {
    color: #564106; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: all transform 0.3s ease-out ease;
    transition-delay: 0;
    transform: translate(0, -50px); }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E5E9F2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E5E9F2;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.5rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0BAFFF !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0091d7 !important; }

.bg-secondary {
  background-color: #8391A7 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #677790 !important; }

.bg-success {
  background-color: #2DCA73 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #24a05b !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #FFC213 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dfa500 !important; }

.bg-danger {
  background-color: #F03F3F !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1212 !important; }

.bg-light {
  background-color: #F9FAFC !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d7deeb !important; }

.bg-dark {
  background-color: #3C4858 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #272f3a !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-black {
  background-color: #000 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important; }

.bg-gray-100 {
  background-color: #F9FAFC !important; }

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #d7deeb !important; }

.bg-gray-200 {
  background-color: #F1F2F6 !important; }

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #d2d5e2 !important; }

.bg-gray-300 {
  background-color: #E5E9F2 !important; }

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c3cce1 !important; }

.bg-gray-400 {
  background-color: #C0CCDA !important; }

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #a0b2c7 !important; }

.bg-gray-500 {
  background-color: #8492A6 !important; }

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #68788f !important; }

.bg-gray-600 {
  background-color: #8391A7 !important; }

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #677790 !important; }

.bg-gray-700 {
  background-color: #5A6679 !important; }

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #444d5c !important; }

.bg-gray-800 {
  background-color: #3C4858 !important; }

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #272f3a !important; }

.bg-gray-900 {
  background-color: #1C2C40 !important; }

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #0c141d !important; }

.bg-blue {
  background-color: #0BAFFF !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #0091d7 !important; }

.bg-purple {
  background-color: #7550E9 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #5123e3 !important; }

.bg-orange {
  background-color: #FF7D50 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #ff571d !important; }

.bg-pink {
  background-color: #ED63D2 !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #e835c5 !important; }

.bg-green {
  background-color: #2DCA73 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #24a05b !important; }

.bg-red {
  background-color: #F03F3F !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #ea1212 !important; }

.bg-yellow {
  background-color: #FFC213 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #dfa500 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #E5E9F2 !important; }

.border-top {
  border-top: 1px solid #E5E9F2 !important; }

.border-right {
  border-right: 1px solid #E5E9F2 !important; }

.border-bottom {
  border-bottom: 1px solid #E5E9F2 !important; }

.border-left {
  border-left: 1px solid #E5E9F2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0BAFFF !important; }

.border-secondary {
  border-color: #8391A7 !important; }

.border-success {
  border-color: #2DCA73 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #FFC213 !important; }

.border-danger {
  border-color: #F03F3F !important; }

.border-light {
  border-color: #F9FAFC !important; }

.border-dark {
  border-color: #3C4858 !important; }

.border-white {
  border-color: #fff !important; }

.border-black {
  border-color: #000 !important; }

.border-gray-100 {
  border-color: #F9FAFC !important; }

.border-gray-200 {
  border-color: #F1F2F6 !important; }

.border-gray-300 {
  border-color: #E5E9F2 !important; }

.border-gray-400 {
  border-color: #C0CCDA !important; }

.border-gray-500 {
  border-color: #8492A6 !important; }

.border-gray-600 {
  border-color: #8391A7 !important; }

.border-gray-700 {
  border-color: #5A6679 !important; }

.border-gray-800 {
  border-color: #3C4858 !important; }

.border-gray-900 {
  border-color: #1C2C40 !important; }

.border-blue {
  border-color: #0BAFFF !important; }

.border-purple {
  border-color: #7550E9 !important; }

.border-orange {
  border-color: #FF7D50 !important; }

.border-pink {
  border-color: #ED63D2 !important; }

.border-green {
  border-color: #2DCA73 !important; }

.border-red {
  border-color: #F03F3F !important; }

.border-yellow {
  border-color: #FFC213 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.5rem !important; }

.rounded-top {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important; }

.rounded-right {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important; }

.rounded-left {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 0.3125rem !important; }

.mt-5,
.my-5 {
  margin-top: 0.3125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 0.3125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 0.3125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 0.3125rem !important; }

.m-10 {
  margin: 0.625rem !important; }

.mt-10,
.my-10 {
  margin-top: 0.625rem !important; }

.mr-10,
.mx-10 {
  margin-right: 0.625rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 0.625rem !important; }

.ml-10,
.mx-10 {
  margin-left: 0.625rem !important; }

.m-15 {
  margin: 0.9375rem !important; }

.mt-15,
.my-15 {
  margin-top: 0.9375rem !important; }

.mr-15,
.mx-15 {
  margin-right: 0.9375rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 0.9375rem !important; }

.ml-15,
.mx-15 {
  margin-left: 0.9375rem !important; }

.m-20 {
  margin: 1.25rem !important; }

.mt-20,
.my-20 {
  margin-top: 1.25rem !important; }

.mr-20,
.mx-20 {
  margin-right: 1.25rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 1.25rem !important; }

.ml-20,
.mx-20 {
  margin-left: 1.25rem !important; }

.m-25 {
  margin: 1.5625rem !important; }

.mt-25,
.my-25 {
  margin-top: 1.5625rem !important; }

.mr-25,
.mx-25 {
  margin-right: 1.5625rem !important; }

.mb-25,
.my-25 {
  margin-bottom: 1.5625rem !important; }

.ml-25,
.mx-25 {
  margin-left: 1.5625rem !important; }

.m-30 {
  margin: 1.875rem !important; }

.mt-30,
.my-30 {
  margin-top: 1.875rem !important; }

.mr-30,
.mx-30 {
  margin-right: 1.875rem !important; }

.mb-30,
.my-30 {
  margin-bottom: 1.875rem !important; }

.ml-30,
.mx-30 {
  margin-left: 1.875rem !important; }

.m-35 {
  margin: 2.1875rem !important; }

.mt-35,
.my-35 {
  margin-top: 2.1875rem !important; }

.mr-35,
.mx-35 {
  margin-right: 2.1875rem !important; }

.mb-35,
.my-35 {
  margin-bottom: 2.1875rem !important; }

.ml-35,
.mx-35 {
  margin-left: 2.1875rem !important; }

.m-40 {
  margin: 2.5rem !important; }

.mt-40,
.my-40 {
  margin-top: 2.5rem !important; }

.mr-40,
.mx-40 {
  margin-right: 2.5rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 2.5rem !important; }

.ml-40,
.mx-40 {
  margin-left: 2.5rem !important; }

.m-50 {
  margin: 3.125rem !important; }

.mt-50,
.my-50 {
  margin-top: 3.125rem !important; }

.mr-50,
.mx-50 {
  margin-right: 3.125rem !important; }

.mb-50,
.my-50 {
  margin-bottom: 3.125rem !important; }

.ml-50,
.mx-50 {
  margin-left: 3.125rem !important; }

.m-60 {
  margin: 3.75rem !important; }

.mt-60,
.my-60 {
  margin-top: 3.75rem !important; }

.mr-60,
.mx-60 {
  margin-right: 3.75rem !important; }

.mb-60,
.my-60 {
  margin-bottom: 3.75rem !important; }

.ml-60,
.mx-60 {
  margin-left: 3.75rem !important; }

.m-80 {
  margin: 5rem !important; }

.mt-80,
.my-80 {
  margin-top: 5rem !important; }

.mr-80,
.mx-80 {
  margin-right: 5rem !important; }

.mb-80,
.my-80 {
  margin-bottom: 5rem !important; }

.ml-80,
.mx-80 {
  margin-left: 5rem !important; }

.m-100 {
  margin: 6.25rem !important; }

.mt-100,
.my-100 {
  margin-top: 6.25rem !important; }

.mr-100,
.mx-100 {
  margin-right: 6.25rem !important; }

.mb-100,
.my-100 {
  margin-bottom: 6.25rem !important; }

.ml-100,
.mx-100 {
  margin-left: 6.25rem !important; }

.m-120 {
  margin: 7.5rem !important; }

.mt-120,
.my-120 {
  margin-top: 7.5rem !important; }

.mr-120,
.mx-120 {
  margin-right: 7.5rem !important; }

.mb-120,
.my-120 {
  margin-bottom: 7.5rem !important; }

.ml-120,
.mx-120 {
  margin-left: 7.5rem !important; }

.m-140 {
  margin: 8.75rem !important; }

.mt-140,
.my-140 {
  margin-top: 8.75rem !important; }

.mr-140,
.mx-140 {
  margin-right: 8.75rem !important; }

.mb-140,
.my-140 {
  margin-bottom: 8.75rem !important; }

.ml-140,
.mx-140 {
  margin-left: 8.75rem !important; }

.m-160 {
  margin: 10rem !important; }

.mt-160,
.my-160 {
  margin-top: 10rem !important; }

.mr-160,
.mx-160 {
  margin-right: 10rem !important; }

.mb-160,
.my-160 {
  margin-bottom: 10rem !important; }

.ml-160,
.mx-160 {
  margin-left: 10rem !important; }

.m-180 {
  margin: 11.25rem !important; }

.mt-180,
.my-180 {
  margin-top: 11.25rem !important; }

.mr-180,
.mx-180 {
  margin-right: 11.25rem !important; }

.mb-180,
.my-180 {
  margin-bottom: 11.25rem !important; }

.ml-180,
.mx-180 {
  margin-left: 11.25rem !important; }

.m-200 {
  margin: 12.5rem !important; }

.mt-200,
.my-200 {
  margin-top: 12.5rem !important; }

.mr-200,
.mx-200 {
  margin-right: 12.5rem !important; }

.mb-200,
.my-200 {
  margin-bottom: 12.5rem !important; }

.ml-200,
.mx-200 {
  margin-left: 12.5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 0.3125rem !important; }

.pt-5,
.py-5 {
  padding-top: 0.3125rem !important; }

.pr-5,
.px-5 {
  padding-right: 0.3125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 0.3125rem !important; }

.pl-5,
.px-5 {
  padding-left: 0.3125rem !important; }

.p-10 {
  padding: 0.625rem !important; }

.pt-10,
.py-10 {
  padding-top: 0.625rem !important; }

.pr-10,
.px-10 {
  padding-right: 0.625rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 0.625rem !important; }

.pl-10,
.px-10 {
  padding-left: 0.625rem !important; }

.p-15 {
  padding: 0.9375rem !important; }

.pt-15,
.py-15 {
  padding-top: 0.9375rem !important; }

.pr-15,
.px-15 {
  padding-right: 0.9375rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 0.9375rem !important; }

.pl-15,
.px-15 {
  padding-left: 0.9375rem !important; }

.p-20 {
  padding: 1.25rem !important; }

.pt-20,
.py-20 {
  padding-top: 1.25rem !important; }

.pr-20,
.px-20 {
  padding-right: 1.25rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 1.25rem !important; }

.pl-20,
.px-20 {
  padding-left: 1.25rem !important; }

.p-25 {
  padding: 1.5625rem !important; }

.pt-25,
.py-25 {
  padding-top: 1.5625rem !important; }

.pr-25,
.px-25 {
  padding-right: 1.5625rem !important; }

.pb-25,
.py-25 {
  padding-bottom: 1.5625rem !important; }

.pl-25,
.px-25 {
  padding-left: 1.5625rem !important; }

.p-30 {
  padding: 1.875rem !important; }

.pt-30,
.py-30 {
  padding-top: 1.875rem !important; }

.pr-30,
.px-30 {
  padding-right: 1.875rem !important; }

.pb-30,
.py-30 {
  padding-bottom: 1.875rem !important; }

.pl-30,
.px-30 {
  padding-left: 1.875rem !important; }

.p-35 {
  padding: 2.1875rem !important; }

.pt-35,
.py-35 {
  padding-top: 2.1875rem !important; }

.pr-35,
.px-35 {
  padding-right: 2.1875rem !important; }

.pb-35,
.py-35 {
  padding-bottom: 2.1875rem !important; }

.pl-35,
.px-35 {
  padding-left: 2.1875rem !important; }

.p-40 {
  padding: 2.5rem !important; }

.pt-40,
.py-40 {
  padding-top: 2.5rem !important; }

.pr-40,
.px-40 {
  padding-right: 2.5rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 2.5rem !important; }

.pl-40,
.px-40 {
  padding-left: 2.5rem !important; }

.p-50 {
  padding: 3.125rem !important; }

.pt-50,
.py-50 {
  padding-top: 3.125rem !important; }

.pr-50,
.px-50 {
  padding-right: 3.125rem !important; }

.pb-50,
.py-50 {
  padding-bottom: 3.125rem !important; }

.pl-50,
.px-50 {
  padding-left: 3.125rem !important; }

.p-60 {
  padding: 3.75rem !important; }

.pt-60,
.py-60 {
  padding-top: 3.75rem !important; }

.pr-60,
.px-60 {
  padding-right: 3.75rem !important; }

.pb-60,
.py-60 {
  padding-bottom: 3.75rem !important; }

.pl-60,
.px-60 {
  padding-left: 3.75rem !important; }

.p-80 {
  padding: 5rem !important; }

.pt-80,
.py-80 {
  padding-top: 5rem !important; }

.pr-80,
.px-80 {
  padding-right: 5rem !important; }

.pb-80,
.py-80 {
  padding-bottom: 5rem !important; }

.pl-80,
.px-80 {
  padding-left: 5rem !important; }

.p-100 {
  padding: 6.25rem !important; }

.pt-100,
.py-100 {
  padding-top: 6.25rem !important; }

.pr-100,
.px-100 {
  padding-right: 6.25rem !important; }

.pb-100,
.py-100 {
  padding-bottom: 6.25rem !important; }

.pl-100,
.px-100 {
  padding-left: 6.25rem !important; }

.p-120 {
  padding: 7.5rem !important; }

.pt-120,
.py-120 {
  padding-top: 7.5rem !important; }

.pr-120,
.px-120 {
  padding-right: 7.5rem !important; }

.pb-120,
.py-120 {
  padding-bottom: 7.5rem !important; }

.pl-120,
.px-120 {
  padding-left: 7.5rem !important; }

.p-140 {
  padding: 8.75rem !important; }

.pt-140,
.py-140 {
  padding-top: 8.75rem !important; }

.pr-140,
.px-140 {
  padding-right: 8.75rem !important; }

.pb-140,
.py-140 {
  padding-bottom: 8.75rem !important; }

.pl-140,
.px-140 {
  padding-left: 8.75rem !important; }

.p-160 {
  padding: 10rem !important; }

.pt-160,
.py-160 {
  padding-top: 10rem !important; }

.pr-160,
.px-160 {
  padding-right: 10rem !important; }

.pb-160,
.py-160 {
  padding-bottom: 10rem !important; }

.pl-160,
.px-160 {
  padding-left: 10rem !important; }

.p-180 {
  padding: 11.25rem !important; }

.pt-180,
.py-180 {
  padding-top: 11.25rem !important; }

.pr-180,
.px-180 {
  padding-right: 11.25rem !important; }

.pb-180,
.py-180 {
  padding-bottom: 11.25rem !important; }

.pl-180,
.px-180 {
  padding-left: 11.25rem !important; }

.p-200 {
  padding: 12.5rem !important; }

.pt-200,
.py-200 {
  padding-top: 12.5rem !important; }

.pr-200,
.px-200 {
  padding-right: 12.5rem !important; }

.pb-200,
.py-200 {
  padding-bottom: 12.5rem !important; }

.pl-200,
.px-200 {
  padding-left: 12.5rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -0.3125rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -0.3125rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -0.3125rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -0.3125rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -0.3125rem !important; }

.m-n10 {
  margin: -0.625rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -0.625rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -0.625rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -0.625rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -0.625rem !important; }

.m-n15 {
  margin: -0.9375rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -0.9375rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -0.9375rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -0.9375rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -0.9375rem !important; }

.m-n20 {
  margin: -1.25rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -1.25rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -1.25rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -1.25rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -1.25rem !important; }

.m-n25 {
  margin: -1.5625rem !important; }

.mt-n25,
.my-n25 {
  margin-top: -1.5625rem !important; }

.mr-n25,
.mx-n25 {
  margin-right: -1.5625rem !important; }

.mb-n25,
.my-n25 {
  margin-bottom: -1.5625rem !important; }

.ml-n25,
.mx-n25 {
  margin-left: -1.5625rem !important; }

.m-n30 {
  margin: -1.875rem !important; }

.mt-n30,
.my-n30 {
  margin-top: -1.875rem !important; }

.mr-n30,
.mx-n30 {
  margin-right: -1.875rem !important; }

.mb-n30,
.my-n30 {
  margin-bottom: -1.875rem !important; }

.ml-n30,
.mx-n30 {
  margin-left: -1.875rem !important; }

.m-n35 {
  margin: -2.1875rem !important; }

.mt-n35,
.my-n35 {
  margin-top: -2.1875rem !important; }

.mr-n35,
.mx-n35 {
  margin-right: -2.1875rem !important; }

.mb-n35,
.my-n35 {
  margin-bottom: -2.1875rem !important; }

.ml-n35,
.mx-n35 {
  margin-left: -2.1875rem !important; }

.m-n40 {
  margin: -2.5rem !important; }

.mt-n40,
.my-n40 {
  margin-top: -2.5rem !important; }

.mr-n40,
.mx-n40 {
  margin-right: -2.5rem !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -2.5rem !important; }

.ml-n40,
.mx-n40 {
  margin-left: -2.5rem !important; }

.m-n50 {
  margin: -3.125rem !important; }

.mt-n50,
.my-n50 {
  margin-top: -3.125rem !important; }

.mr-n50,
.mx-n50 {
  margin-right: -3.125rem !important; }

.mb-n50,
.my-n50 {
  margin-bottom: -3.125rem !important; }

.ml-n50,
.mx-n50 {
  margin-left: -3.125rem !important; }

.m-n60 {
  margin: -3.75rem !important; }

.mt-n60,
.my-n60 {
  margin-top: -3.75rem !important; }

.mr-n60,
.mx-n60 {
  margin-right: -3.75rem !important; }

.mb-n60,
.my-n60 {
  margin-bottom: -3.75rem !important; }

.ml-n60,
.mx-n60 {
  margin-left: -3.75rem !important; }

.m-n80 {
  margin: -5rem !important; }

.mt-n80,
.my-n80 {
  margin-top: -5rem !important; }

.mr-n80,
.mx-n80 {
  margin-right: -5rem !important; }

.mb-n80,
.my-n80 {
  margin-bottom: -5rem !important; }

.ml-n80,
.mx-n80 {
  margin-left: -5rem !important; }

.m-n100 {
  margin: -6.25rem !important; }

.mt-n100,
.my-n100 {
  margin-top: -6.25rem !important; }

.mr-n100,
.mx-n100 {
  margin-right: -6.25rem !important; }

.mb-n100,
.my-n100 {
  margin-bottom: -6.25rem !important; }

.ml-n100,
.mx-n100 {
  margin-left: -6.25rem !important; }

.m-n120 {
  margin: -7.5rem !important; }

.mt-n120,
.my-n120 {
  margin-top: -7.5rem !important; }

.mr-n120,
.mx-n120 {
  margin-right: -7.5rem !important; }

.mb-n120,
.my-n120 {
  margin-bottom: -7.5rem !important; }

.ml-n120,
.mx-n120 {
  margin-left: -7.5rem !important; }

.m-n140 {
  margin: -8.75rem !important; }

.mt-n140,
.my-n140 {
  margin-top: -8.75rem !important; }

.mr-n140,
.mx-n140 {
  margin-right: -8.75rem !important; }

.mb-n140,
.my-n140 {
  margin-bottom: -8.75rem !important; }

.ml-n140,
.mx-n140 {
  margin-left: -8.75rem !important; }

.m-n160 {
  margin: -10rem !important; }

.mt-n160,
.my-n160 {
  margin-top: -10rem !important; }

.mr-n160,
.mx-n160 {
  margin-right: -10rem !important; }

.mb-n160,
.my-n160 {
  margin-bottom: -10rem !important; }

.ml-n160,
.mx-n160 {
  margin-left: -10rem !important; }

.m-n180 {
  margin: -11.25rem !important; }

.mt-n180,
.my-n180 {
  margin-top: -11.25rem !important; }

.mr-n180,
.mx-n180 {
  margin-right: -11.25rem !important; }

.mb-n180,
.my-n180 {
  margin-bottom: -11.25rem !important; }

.ml-n180,
.mx-n180 {
  margin-left: -11.25rem !important; }

.m-n200 {
  margin: -12.5rem !important; }

.mt-n200,
.my-n200 {
  margin-top: -12.5rem !important; }

.mr-n200,
.mx-n200 {
  margin-right: -12.5rem !important; }

.mb-n200,
.my-n200 {
  margin-bottom: -12.5rem !important; }

.ml-n200,
.mx-n200 {
  margin-left: -12.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 0.3125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 0.3125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 0.3125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 0.3125rem !important; }
  .m-sm-10 {
    margin: 0.625rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 0.625rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 0.625rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 0.625rem !important; }
  .m-sm-15 {
    margin: 0.9375rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 0.9375rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 0.9375rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 0.9375rem !important; }
  .m-sm-20 {
    margin: 1.25rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 1.25rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 1.25rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 1.25rem !important; }
  .m-sm-25 {
    margin: 1.5625rem !important; }
  .mt-sm-25,
  .my-sm-25 {
    margin-top: 1.5625rem !important; }
  .mr-sm-25,
  .mx-sm-25 {
    margin-right: 1.5625rem !important; }
  .mb-sm-25,
  .my-sm-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-sm-25,
  .mx-sm-25 {
    margin-left: 1.5625rem !important; }
  .m-sm-30 {
    margin: 1.875rem !important; }
  .mt-sm-30,
  .my-sm-30 {
    margin-top: 1.875rem !important; }
  .mr-sm-30,
  .mx-sm-30 {
    margin-right: 1.875rem !important; }
  .mb-sm-30,
  .my-sm-30 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-30,
  .mx-sm-30 {
    margin-left: 1.875rem !important; }
  .m-sm-35 {
    margin: 2.1875rem !important; }
  .mt-sm-35,
  .my-sm-35 {
    margin-top: 2.1875rem !important; }
  .mr-sm-35,
  .mx-sm-35 {
    margin-right: 2.1875rem !important; }
  .mb-sm-35,
  .my-sm-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-sm-35,
  .mx-sm-35 {
    margin-left: 2.1875rem !important; }
  .m-sm-40 {
    margin: 2.5rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 2.5rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 2.5rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 2.5rem !important; }
  .m-sm-50 {
    margin: 3.125rem !important; }
  .mt-sm-50,
  .my-sm-50 {
    margin-top: 3.125rem !important; }
  .mr-sm-50,
  .mx-sm-50 {
    margin-right: 3.125rem !important; }
  .mb-sm-50,
  .my-sm-50 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-50,
  .mx-sm-50 {
    margin-left: 3.125rem !important; }
  .m-sm-60 {
    margin: 3.75rem !important; }
  .mt-sm-60,
  .my-sm-60 {
    margin-top: 3.75rem !important; }
  .mr-sm-60,
  .mx-sm-60 {
    margin-right: 3.75rem !important; }
  .mb-sm-60,
  .my-sm-60 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-60,
  .mx-sm-60 {
    margin-left: 3.75rem !important; }
  .m-sm-80 {
    margin: 5rem !important; }
  .mt-sm-80,
  .my-sm-80 {
    margin-top: 5rem !important; }
  .mr-sm-80,
  .mx-sm-80 {
    margin-right: 5rem !important; }
  .mb-sm-80,
  .my-sm-80 {
    margin-bottom: 5rem !important; }
  .ml-sm-80,
  .mx-sm-80 {
    margin-left: 5rem !important; }
  .m-sm-100 {
    margin: 6.25rem !important; }
  .mt-sm-100,
  .my-sm-100 {
    margin-top: 6.25rem !important; }
  .mr-sm-100,
  .mx-sm-100 {
    margin-right: 6.25rem !important; }
  .mb-sm-100,
  .my-sm-100 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-100,
  .mx-sm-100 {
    margin-left: 6.25rem !important; }
  .m-sm-120 {
    margin: 7.5rem !important; }
  .mt-sm-120,
  .my-sm-120 {
    margin-top: 7.5rem !important; }
  .mr-sm-120,
  .mx-sm-120 {
    margin-right: 7.5rem !important; }
  .mb-sm-120,
  .my-sm-120 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-120,
  .mx-sm-120 {
    margin-left: 7.5rem !important; }
  .m-sm-140 {
    margin: 8.75rem !important; }
  .mt-sm-140,
  .my-sm-140 {
    margin-top: 8.75rem !important; }
  .mr-sm-140,
  .mx-sm-140 {
    margin-right: 8.75rem !important; }
  .mb-sm-140,
  .my-sm-140 {
    margin-bottom: 8.75rem !important; }
  .ml-sm-140,
  .mx-sm-140 {
    margin-left: 8.75rem !important; }
  .m-sm-160 {
    margin: 10rem !important; }
  .mt-sm-160,
  .my-sm-160 {
    margin-top: 10rem !important; }
  .mr-sm-160,
  .mx-sm-160 {
    margin-right: 10rem !important; }
  .mb-sm-160,
  .my-sm-160 {
    margin-bottom: 10rem !important; }
  .ml-sm-160,
  .mx-sm-160 {
    margin-left: 10rem !important; }
  .m-sm-180 {
    margin: 11.25rem !important; }
  .mt-sm-180,
  .my-sm-180 {
    margin-top: 11.25rem !important; }
  .mr-sm-180,
  .mx-sm-180 {
    margin-right: 11.25rem !important; }
  .mb-sm-180,
  .my-sm-180 {
    margin-bottom: 11.25rem !important; }
  .ml-sm-180,
  .mx-sm-180 {
    margin-left: 11.25rem !important; }
  .m-sm-200 {
    margin: 12.5rem !important; }
  .mt-sm-200,
  .my-sm-200 {
    margin-top: 12.5rem !important; }
  .mr-sm-200,
  .mx-sm-200 {
    margin-right: 12.5rem !important; }
  .mb-sm-200,
  .my-sm-200 {
    margin-bottom: 12.5rem !important; }
  .ml-sm-200,
  .mx-sm-200 {
    margin-left: 12.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 0.3125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 0.3125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 0.3125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 0.3125rem !important; }
  .p-sm-10 {
    padding: 0.625rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 0.625rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 0.625rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 0.625rem !important; }
  .p-sm-15 {
    padding: 0.9375rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 0.9375rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 0.9375rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 0.9375rem !important; }
  .p-sm-20 {
    padding: 1.25rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 1.25rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 1.25rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 1.25rem !important; }
  .p-sm-25 {
    padding: 1.5625rem !important; }
  .pt-sm-25,
  .py-sm-25 {
    padding-top: 1.5625rem !important; }
  .pr-sm-25,
  .px-sm-25 {
    padding-right: 1.5625rem !important; }
  .pb-sm-25,
  .py-sm-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-sm-25,
  .px-sm-25 {
    padding-left: 1.5625rem !important; }
  .p-sm-30 {
    padding: 1.875rem !important; }
  .pt-sm-30,
  .py-sm-30 {
    padding-top: 1.875rem !important; }
  .pr-sm-30,
  .px-sm-30 {
    padding-right: 1.875rem !important; }
  .pb-sm-30,
  .py-sm-30 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-30,
  .px-sm-30 {
    padding-left: 1.875rem !important; }
  .p-sm-35 {
    padding: 2.1875rem !important; }
  .pt-sm-35,
  .py-sm-35 {
    padding-top: 2.1875rem !important; }
  .pr-sm-35,
  .px-sm-35 {
    padding-right: 2.1875rem !important; }
  .pb-sm-35,
  .py-sm-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-sm-35,
  .px-sm-35 {
    padding-left: 2.1875rem !important; }
  .p-sm-40 {
    padding: 2.5rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 2.5rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 2.5rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 2.5rem !important; }
  .p-sm-50 {
    padding: 3.125rem !important; }
  .pt-sm-50,
  .py-sm-50 {
    padding-top: 3.125rem !important; }
  .pr-sm-50,
  .px-sm-50 {
    padding-right: 3.125rem !important; }
  .pb-sm-50,
  .py-sm-50 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-50,
  .px-sm-50 {
    padding-left: 3.125rem !important; }
  .p-sm-60 {
    padding: 3.75rem !important; }
  .pt-sm-60,
  .py-sm-60 {
    padding-top: 3.75rem !important; }
  .pr-sm-60,
  .px-sm-60 {
    padding-right: 3.75rem !important; }
  .pb-sm-60,
  .py-sm-60 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-60,
  .px-sm-60 {
    padding-left: 3.75rem !important; }
  .p-sm-80 {
    padding: 5rem !important; }
  .pt-sm-80,
  .py-sm-80 {
    padding-top: 5rem !important; }
  .pr-sm-80,
  .px-sm-80 {
    padding-right: 5rem !important; }
  .pb-sm-80,
  .py-sm-80 {
    padding-bottom: 5rem !important; }
  .pl-sm-80,
  .px-sm-80 {
    padding-left: 5rem !important; }
  .p-sm-100 {
    padding: 6.25rem !important; }
  .pt-sm-100,
  .py-sm-100 {
    padding-top: 6.25rem !important; }
  .pr-sm-100,
  .px-sm-100 {
    padding-right: 6.25rem !important; }
  .pb-sm-100,
  .py-sm-100 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-100,
  .px-sm-100 {
    padding-left: 6.25rem !important; }
  .p-sm-120 {
    padding: 7.5rem !important; }
  .pt-sm-120,
  .py-sm-120 {
    padding-top: 7.5rem !important; }
  .pr-sm-120,
  .px-sm-120 {
    padding-right: 7.5rem !important; }
  .pb-sm-120,
  .py-sm-120 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-120,
  .px-sm-120 {
    padding-left: 7.5rem !important; }
  .p-sm-140 {
    padding: 8.75rem !important; }
  .pt-sm-140,
  .py-sm-140 {
    padding-top: 8.75rem !important; }
  .pr-sm-140,
  .px-sm-140 {
    padding-right: 8.75rem !important; }
  .pb-sm-140,
  .py-sm-140 {
    padding-bottom: 8.75rem !important; }
  .pl-sm-140,
  .px-sm-140 {
    padding-left: 8.75rem !important; }
  .p-sm-160 {
    padding: 10rem !important; }
  .pt-sm-160,
  .py-sm-160 {
    padding-top: 10rem !important; }
  .pr-sm-160,
  .px-sm-160 {
    padding-right: 10rem !important; }
  .pb-sm-160,
  .py-sm-160 {
    padding-bottom: 10rem !important; }
  .pl-sm-160,
  .px-sm-160 {
    padding-left: 10rem !important; }
  .p-sm-180 {
    padding: 11.25rem !important; }
  .pt-sm-180,
  .py-sm-180 {
    padding-top: 11.25rem !important; }
  .pr-sm-180,
  .px-sm-180 {
    padding-right: 11.25rem !important; }
  .pb-sm-180,
  .py-sm-180 {
    padding-bottom: 11.25rem !important; }
  .pl-sm-180,
  .px-sm-180 {
    padding-left: 11.25rem !important; }
  .p-sm-200 {
    padding: 12.5rem !important; }
  .pt-sm-200,
  .py-sm-200 {
    padding-top: 12.5rem !important; }
  .pr-sm-200,
  .px-sm-200 {
    padding-right: 12.5rem !important; }
  .pb-sm-200,
  .py-sm-200 {
    padding-bottom: 12.5rem !important; }
  .pl-sm-200,
  .px-sm-200 {
    padding-left: 12.5rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -0.3125rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -0.3125rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -0.3125rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -0.3125rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -0.3125rem !important; }
  .m-sm-n10 {
    margin: -0.625rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -0.625rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -0.625rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -0.625rem !important; }
  .m-sm-n15 {
    margin: -0.9375rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -0.9375rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -0.9375rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -0.9375rem !important; }
  .m-sm-n20 {
    margin: -1.25rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -1.25rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -1.25rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -1.25rem !important; }
  .m-sm-n25 {
    margin: -1.5625rem !important; }
  .mt-sm-n25,
  .my-sm-n25 {
    margin-top: -1.5625rem !important; }
  .mr-sm-n25,
  .mx-sm-n25 {
    margin-right: -1.5625rem !important; }
  .mb-sm-n25,
  .my-sm-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-sm-n25,
  .mx-sm-n25 {
    margin-left: -1.5625rem !important; }
  .m-sm-n30 {
    margin: -1.875rem !important; }
  .mt-sm-n30,
  .my-sm-n30 {
    margin-top: -1.875rem !important; }
  .mr-sm-n30,
  .mx-sm-n30 {
    margin-right: -1.875rem !important; }
  .mb-sm-n30,
  .my-sm-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-sm-n30,
  .mx-sm-n30 {
    margin-left: -1.875rem !important; }
  .m-sm-n35 {
    margin: -2.1875rem !important; }
  .mt-sm-n35,
  .my-sm-n35 {
    margin-top: -2.1875rem !important; }
  .mr-sm-n35,
  .mx-sm-n35 {
    margin-right: -2.1875rem !important; }
  .mb-sm-n35,
  .my-sm-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-sm-n35,
  .mx-sm-n35 {
    margin-left: -2.1875rem !important; }
  .m-sm-n40 {
    margin: -2.5rem !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -2.5rem !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -2.5rem !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -2.5rem !important; }
  .m-sm-n50 {
    margin: -3.125rem !important; }
  .mt-sm-n50,
  .my-sm-n50 {
    margin-top: -3.125rem !important; }
  .mr-sm-n50,
  .mx-sm-n50 {
    margin-right: -3.125rem !important; }
  .mb-sm-n50,
  .my-sm-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-sm-n50,
  .mx-sm-n50 {
    margin-left: -3.125rem !important; }
  .m-sm-n60 {
    margin: -3.75rem !important; }
  .mt-sm-n60,
  .my-sm-n60 {
    margin-top: -3.75rem !important; }
  .mr-sm-n60,
  .mx-sm-n60 {
    margin-right: -3.75rem !important; }
  .mb-sm-n60,
  .my-sm-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n60,
  .mx-sm-n60 {
    margin-left: -3.75rem !important; }
  .m-sm-n80 {
    margin: -5rem !important; }
  .mt-sm-n80,
  .my-sm-n80 {
    margin-top: -5rem !important; }
  .mr-sm-n80,
  .mx-sm-n80 {
    margin-right: -5rem !important; }
  .mb-sm-n80,
  .my-sm-n80 {
    margin-bottom: -5rem !important; }
  .ml-sm-n80,
  .mx-sm-n80 {
    margin-left: -5rem !important; }
  .m-sm-n100 {
    margin: -6.25rem !important; }
  .mt-sm-n100,
  .my-sm-n100 {
    margin-top: -6.25rem !important; }
  .mr-sm-n100,
  .mx-sm-n100 {
    margin-right: -6.25rem !important; }
  .mb-sm-n100,
  .my-sm-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n100,
  .mx-sm-n100 {
    margin-left: -6.25rem !important; }
  .m-sm-n120 {
    margin: -7.5rem !important; }
  .mt-sm-n120,
  .my-sm-n120 {
    margin-top: -7.5rem !important; }
  .mr-sm-n120,
  .mx-sm-n120 {
    margin-right: -7.5rem !important; }
  .mb-sm-n120,
  .my-sm-n120 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n120,
  .mx-sm-n120 {
    margin-left: -7.5rem !important; }
  .m-sm-n140 {
    margin: -8.75rem !important; }
  .mt-sm-n140,
  .my-sm-n140 {
    margin-top: -8.75rem !important; }
  .mr-sm-n140,
  .mx-sm-n140 {
    margin-right: -8.75rem !important; }
  .mb-sm-n140,
  .my-sm-n140 {
    margin-bottom: -8.75rem !important; }
  .ml-sm-n140,
  .mx-sm-n140 {
    margin-left: -8.75rem !important; }
  .m-sm-n160 {
    margin: -10rem !important; }
  .mt-sm-n160,
  .my-sm-n160 {
    margin-top: -10rem !important; }
  .mr-sm-n160,
  .mx-sm-n160 {
    margin-right: -10rem !important; }
  .mb-sm-n160,
  .my-sm-n160 {
    margin-bottom: -10rem !important; }
  .ml-sm-n160,
  .mx-sm-n160 {
    margin-left: -10rem !important; }
  .m-sm-n180 {
    margin: -11.25rem !important; }
  .mt-sm-n180,
  .my-sm-n180 {
    margin-top: -11.25rem !important; }
  .mr-sm-n180,
  .mx-sm-n180 {
    margin-right: -11.25rem !important; }
  .mb-sm-n180,
  .my-sm-n180 {
    margin-bottom: -11.25rem !important; }
  .ml-sm-n180,
  .mx-sm-n180 {
    margin-left: -11.25rem !important; }
  .m-sm-n200 {
    margin: -12.5rem !important; }
  .mt-sm-n200,
  .my-sm-n200 {
    margin-top: -12.5rem !important; }
  .mr-sm-n200,
  .mx-sm-n200 {
    margin-right: -12.5rem !important; }
  .mb-sm-n200,
  .my-sm-n200 {
    margin-bottom: -12.5rem !important; }
  .ml-sm-n200,
  .mx-sm-n200 {
    margin-left: -12.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 0.3125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 0.3125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 0.3125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 0.3125rem !important; }
  .m-md-10 {
    margin: 0.625rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 0.625rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 0.625rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 0.625rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 0.625rem !important; }
  .m-md-15 {
    margin: 0.9375rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 0.9375rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 0.9375rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 0.9375rem !important; }
  .m-md-20 {
    margin: 1.25rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 1.25rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 1.25rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 1.25rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 1.25rem !important; }
  .m-md-25 {
    margin: 1.5625rem !important; }
  .mt-md-25,
  .my-md-25 {
    margin-top: 1.5625rem !important; }
  .mr-md-25,
  .mx-md-25 {
    margin-right: 1.5625rem !important; }
  .mb-md-25,
  .my-md-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-md-25,
  .mx-md-25 {
    margin-left: 1.5625rem !important; }
  .m-md-30 {
    margin: 1.875rem !important; }
  .mt-md-30,
  .my-md-30 {
    margin-top: 1.875rem !important; }
  .mr-md-30,
  .mx-md-30 {
    margin-right: 1.875rem !important; }
  .mb-md-30,
  .my-md-30 {
    margin-bottom: 1.875rem !important; }
  .ml-md-30,
  .mx-md-30 {
    margin-left: 1.875rem !important; }
  .m-md-35 {
    margin: 2.1875rem !important; }
  .mt-md-35,
  .my-md-35 {
    margin-top: 2.1875rem !important; }
  .mr-md-35,
  .mx-md-35 {
    margin-right: 2.1875rem !important; }
  .mb-md-35,
  .my-md-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-md-35,
  .mx-md-35 {
    margin-left: 2.1875rem !important; }
  .m-md-40 {
    margin: 2.5rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 2.5rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 2.5rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 2.5rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 2.5rem !important; }
  .m-md-50 {
    margin: 3.125rem !important; }
  .mt-md-50,
  .my-md-50 {
    margin-top: 3.125rem !important; }
  .mr-md-50,
  .mx-md-50 {
    margin-right: 3.125rem !important; }
  .mb-md-50,
  .my-md-50 {
    margin-bottom: 3.125rem !important; }
  .ml-md-50,
  .mx-md-50 {
    margin-left: 3.125rem !important; }
  .m-md-60 {
    margin: 3.75rem !important; }
  .mt-md-60,
  .my-md-60 {
    margin-top: 3.75rem !important; }
  .mr-md-60,
  .mx-md-60 {
    margin-right: 3.75rem !important; }
  .mb-md-60,
  .my-md-60 {
    margin-bottom: 3.75rem !important; }
  .ml-md-60,
  .mx-md-60 {
    margin-left: 3.75rem !important; }
  .m-md-80 {
    margin: 5rem !important; }
  .mt-md-80,
  .my-md-80 {
    margin-top: 5rem !important; }
  .mr-md-80,
  .mx-md-80 {
    margin-right: 5rem !important; }
  .mb-md-80,
  .my-md-80 {
    margin-bottom: 5rem !important; }
  .ml-md-80,
  .mx-md-80 {
    margin-left: 5rem !important; }
  .m-md-100 {
    margin: 6.25rem !important; }
  .mt-md-100,
  .my-md-100 {
    margin-top: 6.25rem !important; }
  .mr-md-100,
  .mx-md-100 {
    margin-right: 6.25rem !important; }
  .mb-md-100,
  .my-md-100 {
    margin-bottom: 6.25rem !important; }
  .ml-md-100,
  .mx-md-100 {
    margin-left: 6.25rem !important; }
  .m-md-120 {
    margin: 7.5rem !important; }
  .mt-md-120,
  .my-md-120 {
    margin-top: 7.5rem !important; }
  .mr-md-120,
  .mx-md-120 {
    margin-right: 7.5rem !important; }
  .mb-md-120,
  .my-md-120 {
    margin-bottom: 7.5rem !important; }
  .ml-md-120,
  .mx-md-120 {
    margin-left: 7.5rem !important; }
  .m-md-140 {
    margin: 8.75rem !important; }
  .mt-md-140,
  .my-md-140 {
    margin-top: 8.75rem !important; }
  .mr-md-140,
  .mx-md-140 {
    margin-right: 8.75rem !important; }
  .mb-md-140,
  .my-md-140 {
    margin-bottom: 8.75rem !important; }
  .ml-md-140,
  .mx-md-140 {
    margin-left: 8.75rem !important; }
  .m-md-160 {
    margin: 10rem !important; }
  .mt-md-160,
  .my-md-160 {
    margin-top: 10rem !important; }
  .mr-md-160,
  .mx-md-160 {
    margin-right: 10rem !important; }
  .mb-md-160,
  .my-md-160 {
    margin-bottom: 10rem !important; }
  .ml-md-160,
  .mx-md-160 {
    margin-left: 10rem !important; }
  .m-md-180 {
    margin: 11.25rem !important; }
  .mt-md-180,
  .my-md-180 {
    margin-top: 11.25rem !important; }
  .mr-md-180,
  .mx-md-180 {
    margin-right: 11.25rem !important; }
  .mb-md-180,
  .my-md-180 {
    margin-bottom: 11.25rem !important; }
  .ml-md-180,
  .mx-md-180 {
    margin-left: 11.25rem !important; }
  .m-md-200 {
    margin: 12.5rem !important; }
  .mt-md-200,
  .my-md-200 {
    margin-top: 12.5rem !important; }
  .mr-md-200,
  .mx-md-200 {
    margin-right: 12.5rem !important; }
  .mb-md-200,
  .my-md-200 {
    margin-bottom: 12.5rem !important; }
  .ml-md-200,
  .mx-md-200 {
    margin-left: 12.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 0.3125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 0.3125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 0.3125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 0.3125rem !important; }
  .p-md-10 {
    padding: 0.625rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 0.625rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 0.625rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 0.625rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 0.625rem !important; }
  .p-md-15 {
    padding: 0.9375rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 0.9375rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 0.9375rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 0.9375rem !important; }
  .p-md-20 {
    padding: 1.25rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 1.25rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 1.25rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 1.25rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 1.25rem !important; }
  .p-md-25 {
    padding: 1.5625rem !important; }
  .pt-md-25,
  .py-md-25 {
    padding-top: 1.5625rem !important; }
  .pr-md-25,
  .px-md-25 {
    padding-right: 1.5625rem !important; }
  .pb-md-25,
  .py-md-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-md-25,
  .px-md-25 {
    padding-left: 1.5625rem !important; }
  .p-md-30 {
    padding: 1.875rem !important; }
  .pt-md-30,
  .py-md-30 {
    padding-top: 1.875rem !important; }
  .pr-md-30,
  .px-md-30 {
    padding-right: 1.875rem !important; }
  .pb-md-30,
  .py-md-30 {
    padding-bottom: 1.875rem !important; }
  .pl-md-30,
  .px-md-30 {
    padding-left: 1.875rem !important; }
  .p-md-35 {
    padding: 2.1875rem !important; }
  .pt-md-35,
  .py-md-35 {
    padding-top: 2.1875rem !important; }
  .pr-md-35,
  .px-md-35 {
    padding-right: 2.1875rem !important; }
  .pb-md-35,
  .py-md-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-md-35,
  .px-md-35 {
    padding-left: 2.1875rem !important; }
  .p-md-40 {
    padding: 2.5rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 2.5rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 2.5rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 2.5rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 2.5rem !important; }
  .p-md-50 {
    padding: 3.125rem !important; }
  .pt-md-50,
  .py-md-50 {
    padding-top: 3.125rem !important; }
  .pr-md-50,
  .px-md-50 {
    padding-right: 3.125rem !important; }
  .pb-md-50,
  .py-md-50 {
    padding-bottom: 3.125rem !important; }
  .pl-md-50,
  .px-md-50 {
    padding-left: 3.125rem !important; }
  .p-md-60 {
    padding: 3.75rem !important; }
  .pt-md-60,
  .py-md-60 {
    padding-top: 3.75rem !important; }
  .pr-md-60,
  .px-md-60 {
    padding-right: 3.75rem !important; }
  .pb-md-60,
  .py-md-60 {
    padding-bottom: 3.75rem !important; }
  .pl-md-60,
  .px-md-60 {
    padding-left: 3.75rem !important; }
  .p-md-80 {
    padding: 5rem !important; }
  .pt-md-80,
  .py-md-80 {
    padding-top: 5rem !important; }
  .pr-md-80,
  .px-md-80 {
    padding-right: 5rem !important; }
  .pb-md-80,
  .py-md-80 {
    padding-bottom: 5rem !important; }
  .pl-md-80,
  .px-md-80 {
    padding-left: 5rem !important; }
  .p-md-100 {
    padding: 6.25rem !important; }
  .pt-md-100,
  .py-md-100 {
    padding-top: 6.25rem !important; }
  .pr-md-100,
  .px-md-100 {
    padding-right: 6.25rem !important; }
  .pb-md-100,
  .py-md-100 {
    padding-bottom: 6.25rem !important; }
  .pl-md-100,
  .px-md-100 {
    padding-left: 6.25rem !important; }
  .p-md-120 {
    padding: 7.5rem !important; }
  .pt-md-120,
  .py-md-120 {
    padding-top: 7.5rem !important; }
  .pr-md-120,
  .px-md-120 {
    padding-right: 7.5rem !important; }
  .pb-md-120,
  .py-md-120 {
    padding-bottom: 7.5rem !important; }
  .pl-md-120,
  .px-md-120 {
    padding-left: 7.5rem !important; }
  .p-md-140 {
    padding: 8.75rem !important; }
  .pt-md-140,
  .py-md-140 {
    padding-top: 8.75rem !important; }
  .pr-md-140,
  .px-md-140 {
    padding-right: 8.75rem !important; }
  .pb-md-140,
  .py-md-140 {
    padding-bottom: 8.75rem !important; }
  .pl-md-140,
  .px-md-140 {
    padding-left: 8.75rem !important; }
  .p-md-160 {
    padding: 10rem !important; }
  .pt-md-160,
  .py-md-160 {
    padding-top: 10rem !important; }
  .pr-md-160,
  .px-md-160 {
    padding-right: 10rem !important; }
  .pb-md-160,
  .py-md-160 {
    padding-bottom: 10rem !important; }
  .pl-md-160,
  .px-md-160 {
    padding-left: 10rem !important; }
  .p-md-180 {
    padding: 11.25rem !important; }
  .pt-md-180,
  .py-md-180 {
    padding-top: 11.25rem !important; }
  .pr-md-180,
  .px-md-180 {
    padding-right: 11.25rem !important; }
  .pb-md-180,
  .py-md-180 {
    padding-bottom: 11.25rem !important; }
  .pl-md-180,
  .px-md-180 {
    padding-left: 11.25rem !important; }
  .p-md-200 {
    padding: 12.5rem !important; }
  .pt-md-200,
  .py-md-200 {
    padding-top: 12.5rem !important; }
  .pr-md-200,
  .px-md-200 {
    padding-right: 12.5rem !important; }
  .pb-md-200,
  .py-md-200 {
    padding-bottom: 12.5rem !important; }
  .pl-md-200,
  .px-md-200 {
    padding-left: 12.5rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -0.3125rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -0.3125rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -0.3125rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -0.3125rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -0.3125rem !important; }
  .m-md-n10 {
    margin: -0.625rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -0.625rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -0.625rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -0.625rem !important; }
  .m-md-n15 {
    margin: -0.9375rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -0.9375rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -0.9375rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -0.9375rem !important; }
  .m-md-n20 {
    margin: -1.25rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -1.25rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -1.25rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -1.25rem !important; }
  .m-md-n25 {
    margin: -1.5625rem !important; }
  .mt-md-n25,
  .my-md-n25 {
    margin-top: -1.5625rem !important; }
  .mr-md-n25,
  .mx-md-n25 {
    margin-right: -1.5625rem !important; }
  .mb-md-n25,
  .my-md-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-md-n25,
  .mx-md-n25 {
    margin-left: -1.5625rem !important; }
  .m-md-n30 {
    margin: -1.875rem !important; }
  .mt-md-n30,
  .my-md-n30 {
    margin-top: -1.875rem !important; }
  .mr-md-n30,
  .mx-md-n30 {
    margin-right: -1.875rem !important; }
  .mb-md-n30,
  .my-md-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-md-n30,
  .mx-md-n30 {
    margin-left: -1.875rem !important; }
  .m-md-n35 {
    margin: -2.1875rem !important; }
  .mt-md-n35,
  .my-md-n35 {
    margin-top: -2.1875rem !important; }
  .mr-md-n35,
  .mx-md-n35 {
    margin-right: -2.1875rem !important; }
  .mb-md-n35,
  .my-md-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-md-n35,
  .mx-md-n35 {
    margin-left: -2.1875rem !important; }
  .m-md-n40 {
    margin: -2.5rem !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -2.5rem !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -2.5rem !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -2.5rem !important; }
  .m-md-n50 {
    margin: -3.125rem !important; }
  .mt-md-n50,
  .my-md-n50 {
    margin-top: -3.125rem !important; }
  .mr-md-n50,
  .mx-md-n50 {
    margin-right: -3.125rem !important; }
  .mb-md-n50,
  .my-md-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-md-n50,
  .mx-md-n50 {
    margin-left: -3.125rem !important; }
  .m-md-n60 {
    margin: -3.75rem !important; }
  .mt-md-n60,
  .my-md-n60 {
    margin-top: -3.75rem !important; }
  .mr-md-n60,
  .mx-md-n60 {
    margin-right: -3.75rem !important; }
  .mb-md-n60,
  .my-md-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n60,
  .mx-md-n60 {
    margin-left: -3.75rem !important; }
  .m-md-n80 {
    margin: -5rem !important; }
  .mt-md-n80,
  .my-md-n80 {
    margin-top: -5rem !important; }
  .mr-md-n80,
  .mx-md-n80 {
    margin-right: -5rem !important; }
  .mb-md-n80,
  .my-md-n80 {
    margin-bottom: -5rem !important; }
  .ml-md-n80,
  .mx-md-n80 {
    margin-left: -5rem !important; }
  .m-md-n100 {
    margin: -6.25rem !important; }
  .mt-md-n100,
  .my-md-n100 {
    margin-top: -6.25rem !important; }
  .mr-md-n100,
  .mx-md-n100 {
    margin-right: -6.25rem !important; }
  .mb-md-n100,
  .my-md-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n100,
  .mx-md-n100 {
    margin-left: -6.25rem !important; }
  .m-md-n120 {
    margin: -7.5rem !important; }
  .mt-md-n120,
  .my-md-n120 {
    margin-top: -7.5rem !important; }
  .mr-md-n120,
  .mx-md-n120 {
    margin-right: -7.5rem !important; }
  .mb-md-n120,
  .my-md-n120 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n120,
  .mx-md-n120 {
    margin-left: -7.5rem !important; }
  .m-md-n140 {
    margin: -8.75rem !important; }
  .mt-md-n140,
  .my-md-n140 {
    margin-top: -8.75rem !important; }
  .mr-md-n140,
  .mx-md-n140 {
    margin-right: -8.75rem !important; }
  .mb-md-n140,
  .my-md-n140 {
    margin-bottom: -8.75rem !important; }
  .ml-md-n140,
  .mx-md-n140 {
    margin-left: -8.75rem !important; }
  .m-md-n160 {
    margin: -10rem !important; }
  .mt-md-n160,
  .my-md-n160 {
    margin-top: -10rem !important; }
  .mr-md-n160,
  .mx-md-n160 {
    margin-right: -10rem !important; }
  .mb-md-n160,
  .my-md-n160 {
    margin-bottom: -10rem !important; }
  .ml-md-n160,
  .mx-md-n160 {
    margin-left: -10rem !important; }
  .m-md-n180 {
    margin: -11.25rem !important; }
  .mt-md-n180,
  .my-md-n180 {
    margin-top: -11.25rem !important; }
  .mr-md-n180,
  .mx-md-n180 {
    margin-right: -11.25rem !important; }
  .mb-md-n180,
  .my-md-n180 {
    margin-bottom: -11.25rem !important; }
  .ml-md-n180,
  .mx-md-n180 {
    margin-left: -11.25rem !important; }
  .m-md-n200 {
    margin: -12.5rem !important; }
  .mt-md-n200,
  .my-md-n200 {
    margin-top: -12.5rem !important; }
  .mr-md-n200,
  .mx-md-n200 {
    margin-right: -12.5rem !important; }
  .mb-md-n200,
  .my-md-n200 {
    margin-bottom: -12.5rem !important; }
  .ml-md-n200,
  .mx-md-n200 {
    margin-left: -12.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 0.3125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 0.3125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 0.3125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 0.3125rem !important; }
  .m-lg-10 {
    margin: 0.625rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 0.625rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 0.625rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 0.625rem !important; }
  .m-lg-15 {
    margin: 0.9375rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 0.9375rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 0.9375rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 0.9375rem !important; }
  .m-lg-20 {
    margin: 1.25rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 1.25rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 1.25rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 1.25rem !important; }
  .m-lg-25 {
    margin: 1.5625rem !important; }
  .mt-lg-25,
  .my-lg-25 {
    margin-top: 1.5625rem !important; }
  .mr-lg-25,
  .mx-lg-25 {
    margin-right: 1.5625rem !important; }
  .mb-lg-25,
  .my-lg-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-lg-25,
  .mx-lg-25 {
    margin-left: 1.5625rem !important; }
  .m-lg-30 {
    margin: 1.875rem !important; }
  .mt-lg-30,
  .my-lg-30 {
    margin-top: 1.875rem !important; }
  .mr-lg-30,
  .mx-lg-30 {
    margin-right: 1.875rem !important; }
  .mb-lg-30,
  .my-lg-30 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-30,
  .mx-lg-30 {
    margin-left: 1.875rem !important; }
  .m-lg-35 {
    margin: 2.1875rem !important; }
  .mt-lg-35,
  .my-lg-35 {
    margin-top: 2.1875rem !important; }
  .mr-lg-35,
  .mx-lg-35 {
    margin-right: 2.1875rem !important; }
  .mb-lg-35,
  .my-lg-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-lg-35,
  .mx-lg-35 {
    margin-left: 2.1875rem !important; }
  .m-lg-40 {
    margin: 2.5rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 2.5rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 2.5rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 2.5rem !important; }
  .m-lg-50 {
    margin: 3.125rem !important; }
  .mt-lg-50,
  .my-lg-50 {
    margin-top: 3.125rem !important; }
  .mr-lg-50,
  .mx-lg-50 {
    margin-right: 3.125rem !important; }
  .mb-lg-50,
  .my-lg-50 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-50,
  .mx-lg-50 {
    margin-left: 3.125rem !important; }
  .m-lg-60 {
    margin: 3.75rem !important; }
  .mt-lg-60,
  .my-lg-60 {
    margin-top: 3.75rem !important; }
  .mr-lg-60,
  .mx-lg-60 {
    margin-right: 3.75rem !important; }
  .mb-lg-60,
  .my-lg-60 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-60,
  .mx-lg-60 {
    margin-left: 3.75rem !important; }
  .m-lg-80 {
    margin: 5rem !important; }
  .mt-lg-80,
  .my-lg-80 {
    margin-top: 5rem !important; }
  .mr-lg-80,
  .mx-lg-80 {
    margin-right: 5rem !important; }
  .mb-lg-80,
  .my-lg-80 {
    margin-bottom: 5rem !important; }
  .ml-lg-80,
  .mx-lg-80 {
    margin-left: 5rem !important; }
  .m-lg-100 {
    margin: 6.25rem !important; }
  .mt-lg-100,
  .my-lg-100 {
    margin-top: 6.25rem !important; }
  .mr-lg-100,
  .mx-lg-100 {
    margin-right: 6.25rem !important; }
  .mb-lg-100,
  .my-lg-100 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-100,
  .mx-lg-100 {
    margin-left: 6.25rem !important; }
  .m-lg-120 {
    margin: 7.5rem !important; }
  .mt-lg-120,
  .my-lg-120 {
    margin-top: 7.5rem !important; }
  .mr-lg-120,
  .mx-lg-120 {
    margin-right: 7.5rem !important; }
  .mb-lg-120,
  .my-lg-120 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-120,
  .mx-lg-120 {
    margin-left: 7.5rem !important; }
  .m-lg-140 {
    margin: 8.75rem !important; }
  .mt-lg-140,
  .my-lg-140 {
    margin-top: 8.75rem !important; }
  .mr-lg-140,
  .mx-lg-140 {
    margin-right: 8.75rem !important; }
  .mb-lg-140,
  .my-lg-140 {
    margin-bottom: 8.75rem !important; }
  .ml-lg-140,
  .mx-lg-140 {
    margin-left: 8.75rem !important; }
  .m-lg-160 {
    margin: 10rem !important; }
  .mt-lg-160,
  .my-lg-160 {
    margin-top: 10rem !important; }
  .mr-lg-160,
  .mx-lg-160 {
    margin-right: 10rem !important; }
  .mb-lg-160,
  .my-lg-160 {
    margin-bottom: 10rem !important; }
  .ml-lg-160,
  .mx-lg-160 {
    margin-left: 10rem !important; }
  .m-lg-180 {
    margin: 11.25rem !important; }
  .mt-lg-180,
  .my-lg-180 {
    margin-top: 11.25rem !important; }
  .mr-lg-180,
  .mx-lg-180 {
    margin-right: 11.25rem !important; }
  .mb-lg-180,
  .my-lg-180 {
    margin-bottom: 11.25rem !important; }
  .ml-lg-180,
  .mx-lg-180 {
    margin-left: 11.25rem !important; }
  .m-lg-200 {
    margin: 12.5rem !important; }
  .mt-lg-200,
  .my-lg-200 {
    margin-top: 12.5rem !important; }
  .mr-lg-200,
  .mx-lg-200 {
    margin-right: 12.5rem !important; }
  .mb-lg-200,
  .my-lg-200 {
    margin-bottom: 12.5rem !important; }
  .ml-lg-200,
  .mx-lg-200 {
    margin-left: 12.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 0.3125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 0.3125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 0.3125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 0.3125rem !important; }
  .p-lg-10 {
    padding: 0.625rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 0.625rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 0.625rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 0.625rem !important; }
  .p-lg-15 {
    padding: 0.9375rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 0.9375rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 0.9375rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 0.9375rem !important; }
  .p-lg-20 {
    padding: 1.25rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 1.25rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 1.25rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 1.25rem !important; }
  .p-lg-25 {
    padding: 1.5625rem !important; }
  .pt-lg-25,
  .py-lg-25 {
    padding-top: 1.5625rem !important; }
  .pr-lg-25,
  .px-lg-25 {
    padding-right: 1.5625rem !important; }
  .pb-lg-25,
  .py-lg-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-lg-25,
  .px-lg-25 {
    padding-left: 1.5625rem !important; }
  .p-lg-30 {
    padding: 1.875rem !important; }
  .pt-lg-30,
  .py-lg-30 {
    padding-top: 1.875rem !important; }
  .pr-lg-30,
  .px-lg-30 {
    padding-right: 1.875rem !important; }
  .pb-lg-30,
  .py-lg-30 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-30,
  .px-lg-30 {
    padding-left: 1.875rem !important; }
  .p-lg-35 {
    padding: 2.1875rem !important; }
  .pt-lg-35,
  .py-lg-35 {
    padding-top: 2.1875rem !important; }
  .pr-lg-35,
  .px-lg-35 {
    padding-right: 2.1875rem !important; }
  .pb-lg-35,
  .py-lg-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-lg-35,
  .px-lg-35 {
    padding-left: 2.1875rem !important; }
  .p-lg-40 {
    padding: 2.5rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 2.5rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 2.5rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 2.5rem !important; }
  .p-lg-50 {
    padding: 3.125rem !important; }
  .pt-lg-50,
  .py-lg-50 {
    padding-top: 3.125rem !important; }
  .pr-lg-50,
  .px-lg-50 {
    padding-right: 3.125rem !important; }
  .pb-lg-50,
  .py-lg-50 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-50,
  .px-lg-50 {
    padding-left: 3.125rem !important; }
  .p-lg-60 {
    padding: 3.75rem !important; }
  .pt-lg-60,
  .py-lg-60 {
    padding-top: 3.75rem !important; }
  .pr-lg-60,
  .px-lg-60 {
    padding-right: 3.75rem !important; }
  .pb-lg-60,
  .py-lg-60 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-60,
  .px-lg-60 {
    padding-left: 3.75rem !important; }
  .p-lg-80 {
    padding: 5rem !important; }
  .pt-lg-80,
  .py-lg-80 {
    padding-top: 5rem !important; }
  .pr-lg-80,
  .px-lg-80 {
    padding-right: 5rem !important; }
  .pb-lg-80,
  .py-lg-80 {
    padding-bottom: 5rem !important; }
  .pl-lg-80,
  .px-lg-80 {
    padding-left: 5rem !important; }
  .p-lg-100 {
    padding: 6.25rem !important; }
  .pt-lg-100,
  .py-lg-100 {
    padding-top: 6.25rem !important; }
  .pr-lg-100,
  .px-lg-100 {
    padding-right: 6.25rem !important; }
  .pb-lg-100,
  .py-lg-100 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-100,
  .px-lg-100 {
    padding-left: 6.25rem !important; }
  .p-lg-120 {
    padding: 7.5rem !important; }
  .pt-lg-120,
  .py-lg-120 {
    padding-top: 7.5rem !important; }
  .pr-lg-120,
  .px-lg-120 {
    padding-right: 7.5rem !important; }
  .pb-lg-120,
  .py-lg-120 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-120,
  .px-lg-120 {
    padding-left: 7.5rem !important; }
  .p-lg-140 {
    padding: 8.75rem !important; }
  .pt-lg-140,
  .py-lg-140 {
    padding-top: 8.75rem !important; }
  .pr-lg-140,
  .px-lg-140 {
    padding-right: 8.75rem !important; }
  .pb-lg-140,
  .py-lg-140 {
    padding-bottom: 8.75rem !important; }
  .pl-lg-140,
  .px-lg-140 {
    padding-left: 8.75rem !important; }
  .p-lg-160 {
    padding: 10rem !important; }
  .pt-lg-160,
  .py-lg-160 {
    padding-top: 10rem !important; }
  .pr-lg-160,
  .px-lg-160 {
    padding-right: 10rem !important; }
  .pb-lg-160,
  .py-lg-160 {
    padding-bottom: 10rem !important; }
  .pl-lg-160,
  .px-lg-160 {
    padding-left: 10rem !important; }
  .p-lg-180 {
    padding: 11.25rem !important; }
  .pt-lg-180,
  .py-lg-180 {
    padding-top: 11.25rem !important; }
  .pr-lg-180,
  .px-lg-180 {
    padding-right: 11.25rem !important; }
  .pb-lg-180,
  .py-lg-180 {
    padding-bottom: 11.25rem !important; }
  .pl-lg-180,
  .px-lg-180 {
    padding-left: 11.25rem !important; }
  .p-lg-200 {
    padding: 12.5rem !important; }
  .pt-lg-200,
  .py-lg-200 {
    padding-top: 12.5rem !important; }
  .pr-lg-200,
  .px-lg-200 {
    padding-right: 12.5rem !important; }
  .pb-lg-200,
  .py-lg-200 {
    padding-bottom: 12.5rem !important; }
  .pl-lg-200,
  .px-lg-200 {
    padding-left: 12.5rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -0.3125rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -0.3125rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -0.3125rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -0.3125rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -0.3125rem !important; }
  .m-lg-n10 {
    margin: -0.625rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -0.625rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -0.625rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -0.625rem !important; }
  .m-lg-n15 {
    margin: -0.9375rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -0.9375rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -0.9375rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -0.9375rem !important; }
  .m-lg-n20 {
    margin: -1.25rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -1.25rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -1.25rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -1.25rem !important; }
  .m-lg-n25 {
    margin: -1.5625rem !important; }
  .mt-lg-n25,
  .my-lg-n25 {
    margin-top: -1.5625rem !important; }
  .mr-lg-n25,
  .mx-lg-n25 {
    margin-right: -1.5625rem !important; }
  .mb-lg-n25,
  .my-lg-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-lg-n25,
  .mx-lg-n25 {
    margin-left: -1.5625rem !important; }
  .m-lg-n30 {
    margin: -1.875rem !important; }
  .mt-lg-n30,
  .my-lg-n30 {
    margin-top: -1.875rem !important; }
  .mr-lg-n30,
  .mx-lg-n30 {
    margin-right: -1.875rem !important; }
  .mb-lg-n30,
  .my-lg-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-lg-n30,
  .mx-lg-n30 {
    margin-left: -1.875rem !important; }
  .m-lg-n35 {
    margin: -2.1875rem !important; }
  .mt-lg-n35,
  .my-lg-n35 {
    margin-top: -2.1875rem !important; }
  .mr-lg-n35,
  .mx-lg-n35 {
    margin-right: -2.1875rem !important; }
  .mb-lg-n35,
  .my-lg-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-lg-n35,
  .mx-lg-n35 {
    margin-left: -2.1875rem !important; }
  .m-lg-n40 {
    margin: -2.5rem !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -2.5rem !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -2.5rem !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -2.5rem !important; }
  .m-lg-n50 {
    margin: -3.125rem !important; }
  .mt-lg-n50,
  .my-lg-n50 {
    margin-top: -3.125rem !important; }
  .mr-lg-n50,
  .mx-lg-n50 {
    margin-right: -3.125rem !important; }
  .mb-lg-n50,
  .my-lg-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-lg-n50,
  .mx-lg-n50 {
    margin-left: -3.125rem !important; }
  .m-lg-n60 {
    margin: -3.75rem !important; }
  .mt-lg-n60,
  .my-lg-n60 {
    margin-top: -3.75rem !important; }
  .mr-lg-n60,
  .mx-lg-n60 {
    margin-right: -3.75rem !important; }
  .mb-lg-n60,
  .my-lg-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n60,
  .mx-lg-n60 {
    margin-left: -3.75rem !important; }
  .m-lg-n80 {
    margin: -5rem !important; }
  .mt-lg-n80,
  .my-lg-n80 {
    margin-top: -5rem !important; }
  .mr-lg-n80,
  .mx-lg-n80 {
    margin-right: -5rem !important; }
  .mb-lg-n80,
  .my-lg-n80 {
    margin-bottom: -5rem !important; }
  .ml-lg-n80,
  .mx-lg-n80 {
    margin-left: -5rem !important; }
  .m-lg-n100 {
    margin: -6.25rem !important; }
  .mt-lg-n100,
  .my-lg-n100 {
    margin-top: -6.25rem !important; }
  .mr-lg-n100,
  .mx-lg-n100 {
    margin-right: -6.25rem !important; }
  .mb-lg-n100,
  .my-lg-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n100,
  .mx-lg-n100 {
    margin-left: -6.25rem !important; }
  .m-lg-n120 {
    margin: -7.5rem !important; }
  .mt-lg-n120,
  .my-lg-n120 {
    margin-top: -7.5rem !important; }
  .mr-lg-n120,
  .mx-lg-n120 {
    margin-right: -7.5rem !important; }
  .mb-lg-n120,
  .my-lg-n120 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n120,
  .mx-lg-n120 {
    margin-left: -7.5rem !important; }
  .m-lg-n140 {
    margin: -8.75rem !important; }
  .mt-lg-n140,
  .my-lg-n140 {
    margin-top: -8.75rem !important; }
  .mr-lg-n140,
  .mx-lg-n140 {
    margin-right: -8.75rem !important; }
  .mb-lg-n140,
  .my-lg-n140 {
    margin-bottom: -8.75rem !important; }
  .ml-lg-n140,
  .mx-lg-n140 {
    margin-left: -8.75rem !important; }
  .m-lg-n160 {
    margin: -10rem !important; }
  .mt-lg-n160,
  .my-lg-n160 {
    margin-top: -10rem !important; }
  .mr-lg-n160,
  .mx-lg-n160 {
    margin-right: -10rem !important; }
  .mb-lg-n160,
  .my-lg-n160 {
    margin-bottom: -10rem !important; }
  .ml-lg-n160,
  .mx-lg-n160 {
    margin-left: -10rem !important; }
  .m-lg-n180 {
    margin: -11.25rem !important; }
  .mt-lg-n180,
  .my-lg-n180 {
    margin-top: -11.25rem !important; }
  .mr-lg-n180,
  .mx-lg-n180 {
    margin-right: -11.25rem !important; }
  .mb-lg-n180,
  .my-lg-n180 {
    margin-bottom: -11.25rem !important; }
  .ml-lg-n180,
  .mx-lg-n180 {
    margin-left: -11.25rem !important; }
  .m-lg-n200 {
    margin: -12.5rem !important; }
  .mt-lg-n200,
  .my-lg-n200 {
    margin-top: -12.5rem !important; }
  .mr-lg-n200,
  .mx-lg-n200 {
    margin-right: -12.5rem !important; }
  .mb-lg-n200,
  .my-lg-n200 {
    margin-bottom: -12.5rem !important; }
  .ml-lg-n200,
  .mx-lg-n200 {
    margin-left: -12.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 0.3125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 0.3125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 0.3125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 0.3125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 0.3125rem !important; }
  .m-xl-10 {
    margin: 0.625rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 0.625rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 0.625rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 0.625rem !important; }
  .m-xl-15 {
    margin: 0.9375rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 0.9375rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 0.9375rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 0.9375rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 0.9375rem !important; }
  .m-xl-20 {
    margin: 1.25rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 1.25rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 1.25rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 1.25rem !important; }
  .m-xl-25 {
    margin: 1.5625rem !important; }
  .mt-xl-25,
  .my-xl-25 {
    margin-top: 1.5625rem !important; }
  .mr-xl-25,
  .mx-xl-25 {
    margin-right: 1.5625rem !important; }
  .mb-xl-25,
  .my-xl-25 {
    margin-bottom: 1.5625rem !important; }
  .ml-xl-25,
  .mx-xl-25 {
    margin-left: 1.5625rem !important; }
  .m-xl-30 {
    margin: 1.875rem !important; }
  .mt-xl-30,
  .my-xl-30 {
    margin-top: 1.875rem !important; }
  .mr-xl-30,
  .mx-xl-30 {
    margin-right: 1.875rem !important; }
  .mb-xl-30,
  .my-xl-30 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-30,
  .mx-xl-30 {
    margin-left: 1.875rem !important; }
  .m-xl-35 {
    margin: 2.1875rem !important; }
  .mt-xl-35,
  .my-xl-35 {
    margin-top: 2.1875rem !important; }
  .mr-xl-35,
  .mx-xl-35 {
    margin-right: 2.1875rem !important; }
  .mb-xl-35,
  .my-xl-35 {
    margin-bottom: 2.1875rem !important; }
  .ml-xl-35,
  .mx-xl-35 {
    margin-left: 2.1875rem !important; }
  .m-xl-40 {
    margin: 2.5rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 2.5rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 2.5rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 2.5rem !important; }
  .m-xl-50 {
    margin: 3.125rem !important; }
  .mt-xl-50,
  .my-xl-50 {
    margin-top: 3.125rem !important; }
  .mr-xl-50,
  .mx-xl-50 {
    margin-right: 3.125rem !important; }
  .mb-xl-50,
  .my-xl-50 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-50,
  .mx-xl-50 {
    margin-left: 3.125rem !important; }
  .m-xl-60 {
    margin: 3.75rem !important; }
  .mt-xl-60,
  .my-xl-60 {
    margin-top: 3.75rem !important; }
  .mr-xl-60,
  .mx-xl-60 {
    margin-right: 3.75rem !important; }
  .mb-xl-60,
  .my-xl-60 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-60,
  .mx-xl-60 {
    margin-left: 3.75rem !important; }
  .m-xl-80 {
    margin: 5rem !important; }
  .mt-xl-80,
  .my-xl-80 {
    margin-top: 5rem !important; }
  .mr-xl-80,
  .mx-xl-80 {
    margin-right: 5rem !important; }
  .mb-xl-80,
  .my-xl-80 {
    margin-bottom: 5rem !important; }
  .ml-xl-80,
  .mx-xl-80 {
    margin-left: 5rem !important; }
  .m-xl-100 {
    margin: 6.25rem !important; }
  .mt-xl-100,
  .my-xl-100 {
    margin-top: 6.25rem !important; }
  .mr-xl-100,
  .mx-xl-100 {
    margin-right: 6.25rem !important; }
  .mb-xl-100,
  .my-xl-100 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-100,
  .mx-xl-100 {
    margin-left: 6.25rem !important; }
  .m-xl-120 {
    margin: 7.5rem !important; }
  .mt-xl-120,
  .my-xl-120 {
    margin-top: 7.5rem !important; }
  .mr-xl-120,
  .mx-xl-120 {
    margin-right: 7.5rem !important; }
  .mb-xl-120,
  .my-xl-120 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-120,
  .mx-xl-120 {
    margin-left: 7.5rem !important; }
  .m-xl-140 {
    margin: 8.75rem !important; }
  .mt-xl-140,
  .my-xl-140 {
    margin-top: 8.75rem !important; }
  .mr-xl-140,
  .mx-xl-140 {
    margin-right: 8.75rem !important; }
  .mb-xl-140,
  .my-xl-140 {
    margin-bottom: 8.75rem !important; }
  .ml-xl-140,
  .mx-xl-140 {
    margin-left: 8.75rem !important; }
  .m-xl-160 {
    margin: 10rem !important; }
  .mt-xl-160,
  .my-xl-160 {
    margin-top: 10rem !important; }
  .mr-xl-160,
  .mx-xl-160 {
    margin-right: 10rem !important; }
  .mb-xl-160,
  .my-xl-160 {
    margin-bottom: 10rem !important; }
  .ml-xl-160,
  .mx-xl-160 {
    margin-left: 10rem !important; }
  .m-xl-180 {
    margin: 11.25rem !important; }
  .mt-xl-180,
  .my-xl-180 {
    margin-top: 11.25rem !important; }
  .mr-xl-180,
  .mx-xl-180 {
    margin-right: 11.25rem !important; }
  .mb-xl-180,
  .my-xl-180 {
    margin-bottom: 11.25rem !important; }
  .ml-xl-180,
  .mx-xl-180 {
    margin-left: 11.25rem !important; }
  .m-xl-200 {
    margin: 12.5rem !important; }
  .mt-xl-200,
  .my-xl-200 {
    margin-top: 12.5rem !important; }
  .mr-xl-200,
  .mx-xl-200 {
    margin-right: 12.5rem !important; }
  .mb-xl-200,
  .my-xl-200 {
    margin-bottom: 12.5rem !important; }
  .ml-xl-200,
  .mx-xl-200 {
    margin-left: 12.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 0.3125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 0.3125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 0.3125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 0.3125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 0.3125rem !important; }
  .p-xl-10 {
    padding: 0.625rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 0.625rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 0.625rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 0.625rem !important; }
  .p-xl-15 {
    padding: 0.9375rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 0.9375rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 0.9375rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 0.9375rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 0.9375rem !important; }
  .p-xl-20 {
    padding: 1.25rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 1.25rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 1.25rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 1.25rem !important; }
  .p-xl-25 {
    padding: 1.5625rem !important; }
  .pt-xl-25,
  .py-xl-25 {
    padding-top: 1.5625rem !important; }
  .pr-xl-25,
  .px-xl-25 {
    padding-right: 1.5625rem !important; }
  .pb-xl-25,
  .py-xl-25 {
    padding-bottom: 1.5625rem !important; }
  .pl-xl-25,
  .px-xl-25 {
    padding-left: 1.5625rem !important; }
  .p-xl-30 {
    padding: 1.875rem !important; }
  .pt-xl-30,
  .py-xl-30 {
    padding-top: 1.875rem !important; }
  .pr-xl-30,
  .px-xl-30 {
    padding-right: 1.875rem !important; }
  .pb-xl-30,
  .py-xl-30 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-30,
  .px-xl-30 {
    padding-left: 1.875rem !important; }
  .p-xl-35 {
    padding: 2.1875rem !important; }
  .pt-xl-35,
  .py-xl-35 {
    padding-top: 2.1875rem !important; }
  .pr-xl-35,
  .px-xl-35 {
    padding-right: 2.1875rem !important; }
  .pb-xl-35,
  .py-xl-35 {
    padding-bottom: 2.1875rem !important; }
  .pl-xl-35,
  .px-xl-35 {
    padding-left: 2.1875rem !important; }
  .p-xl-40 {
    padding: 2.5rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 2.5rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 2.5rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 2.5rem !important; }
  .p-xl-50 {
    padding: 3.125rem !important; }
  .pt-xl-50,
  .py-xl-50 {
    padding-top: 3.125rem !important; }
  .pr-xl-50,
  .px-xl-50 {
    padding-right: 3.125rem !important; }
  .pb-xl-50,
  .py-xl-50 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-50,
  .px-xl-50 {
    padding-left: 3.125rem !important; }
  .p-xl-60 {
    padding: 3.75rem !important; }
  .pt-xl-60,
  .py-xl-60 {
    padding-top: 3.75rem !important; }
  .pr-xl-60,
  .px-xl-60 {
    padding-right: 3.75rem !important; }
  .pb-xl-60,
  .py-xl-60 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-60,
  .px-xl-60 {
    padding-left: 3.75rem !important; }
  .p-xl-80 {
    padding: 5rem !important; }
  .pt-xl-80,
  .py-xl-80 {
    padding-top: 5rem !important; }
  .pr-xl-80,
  .px-xl-80 {
    padding-right: 5rem !important; }
  .pb-xl-80,
  .py-xl-80 {
    padding-bottom: 5rem !important; }
  .pl-xl-80,
  .px-xl-80 {
    padding-left: 5rem !important; }
  .p-xl-100 {
    padding: 6.25rem !important; }
  .pt-xl-100,
  .py-xl-100 {
    padding-top: 6.25rem !important; }
  .pr-xl-100,
  .px-xl-100 {
    padding-right: 6.25rem !important; }
  .pb-xl-100,
  .py-xl-100 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-100,
  .px-xl-100 {
    padding-left: 6.25rem !important; }
  .p-xl-120 {
    padding: 7.5rem !important; }
  .pt-xl-120,
  .py-xl-120 {
    padding-top: 7.5rem !important; }
  .pr-xl-120,
  .px-xl-120 {
    padding-right: 7.5rem !important; }
  .pb-xl-120,
  .py-xl-120 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-120,
  .px-xl-120 {
    padding-left: 7.5rem !important; }
  .p-xl-140 {
    padding: 8.75rem !important; }
  .pt-xl-140,
  .py-xl-140 {
    padding-top: 8.75rem !important; }
  .pr-xl-140,
  .px-xl-140 {
    padding-right: 8.75rem !important; }
  .pb-xl-140,
  .py-xl-140 {
    padding-bottom: 8.75rem !important; }
  .pl-xl-140,
  .px-xl-140 {
    padding-left: 8.75rem !important; }
  .p-xl-160 {
    padding: 10rem !important; }
  .pt-xl-160,
  .py-xl-160 {
    padding-top: 10rem !important; }
  .pr-xl-160,
  .px-xl-160 {
    padding-right: 10rem !important; }
  .pb-xl-160,
  .py-xl-160 {
    padding-bottom: 10rem !important; }
  .pl-xl-160,
  .px-xl-160 {
    padding-left: 10rem !important; }
  .p-xl-180 {
    padding: 11.25rem !important; }
  .pt-xl-180,
  .py-xl-180 {
    padding-top: 11.25rem !important; }
  .pr-xl-180,
  .px-xl-180 {
    padding-right: 11.25rem !important; }
  .pb-xl-180,
  .py-xl-180 {
    padding-bottom: 11.25rem !important; }
  .pl-xl-180,
  .px-xl-180 {
    padding-left: 11.25rem !important; }
  .p-xl-200 {
    padding: 12.5rem !important; }
  .pt-xl-200,
  .py-xl-200 {
    padding-top: 12.5rem !important; }
  .pr-xl-200,
  .px-xl-200 {
    padding-right: 12.5rem !important; }
  .pb-xl-200,
  .py-xl-200 {
    padding-bottom: 12.5rem !important; }
  .pl-xl-200,
  .px-xl-200 {
    padding-left: 12.5rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -0.3125rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -0.3125rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -0.3125rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -0.3125rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -0.3125rem !important; }
  .m-xl-n10 {
    margin: -0.625rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -0.625rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -0.625rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -0.625rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -0.625rem !important; }
  .m-xl-n15 {
    margin: -0.9375rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -0.9375rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -0.9375rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -0.9375rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -0.9375rem !important; }
  .m-xl-n20 {
    margin: -1.25rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -1.25rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -1.25rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -1.25rem !important; }
  .m-xl-n25 {
    margin: -1.5625rem !important; }
  .mt-xl-n25,
  .my-xl-n25 {
    margin-top: -1.5625rem !important; }
  .mr-xl-n25,
  .mx-xl-n25 {
    margin-right: -1.5625rem !important; }
  .mb-xl-n25,
  .my-xl-n25 {
    margin-bottom: -1.5625rem !important; }
  .ml-xl-n25,
  .mx-xl-n25 {
    margin-left: -1.5625rem !important; }
  .m-xl-n30 {
    margin: -1.875rem !important; }
  .mt-xl-n30,
  .my-xl-n30 {
    margin-top: -1.875rem !important; }
  .mr-xl-n30,
  .mx-xl-n30 {
    margin-right: -1.875rem !important; }
  .mb-xl-n30,
  .my-xl-n30 {
    margin-bottom: -1.875rem !important; }
  .ml-xl-n30,
  .mx-xl-n30 {
    margin-left: -1.875rem !important; }
  .m-xl-n35 {
    margin: -2.1875rem !important; }
  .mt-xl-n35,
  .my-xl-n35 {
    margin-top: -2.1875rem !important; }
  .mr-xl-n35,
  .mx-xl-n35 {
    margin-right: -2.1875rem !important; }
  .mb-xl-n35,
  .my-xl-n35 {
    margin-bottom: -2.1875rem !important; }
  .ml-xl-n35,
  .mx-xl-n35 {
    margin-left: -2.1875rem !important; }
  .m-xl-n40 {
    margin: -2.5rem !important; }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -2.5rem !important; }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -2.5rem !important; }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -2.5rem !important; }
  .m-xl-n50 {
    margin: -3.125rem !important; }
  .mt-xl-n50,
  .my-xl-n50 {
    margin-top: -3.125rem !important; }
  .mr-xl-n50,
  .mx-xl-n50 {
    margin-right: -3.125rem !important; }
  .mb-xl-n50,
  .my-xl-n50 {
    margin-bottom: -3.125rem !important; }
  .ml-xl-n50,
  .mx-xl-n50 {
    margin-left: -3.125rem !important; }
  .m-xl-n60 {
    margin: -3.75rem !important; }
  .mt-xl-n60,
  .my-xl-n60 {
    margin-top: -3.75rem !important; }
  .mr-xl-n60,
  .mx-xl-n60 {
    margin-right: -3.75rem !important; }
  .mb-xl-n60,
  .my-xl-n60 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n60,
  .mx-xl-n60 {
    margin-left: -3.75rem !important; }
  .m-xl-n80 {
    margin: -5rem !important; }
  .mt-xl-n80,
  .my-xl-n80 {
    margin-top: -5rem !important; }
  .mr-xl-n80,
  .mx-xl-n80 {
    margin-right: -5rem !important; }
  .mb-xl-n80,
  .my-xl-n80 {
    margin-bottom: -5rem !important; }
  .ml-xl-n80,
  .mx-xl-n80 {
    margin-left: -5rem !important; }
  .m-xl-n100 {
    margin: -6.25rem !important; }
  .mt-xl-n100,
  .my-xl-n100 {
    margin-top: -6.25rem !important; }
  .mr-xl-n100,
  .mx-xl-n100 {
    margin-right: -6.25rem !important; }
  .mb-xl-n100,
  .my-xl-n100 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n100,
  .mx-xl-n100 {
    margin-left: -6.25rem !important; }
  .m-xl-n120 {
    margin: -7.5rem !important; }
  .mt-xl-n120,
  .my-xl-n120 {
    margin-top: -7.5rem !important; }
  .mr-xl-n120,
  .mx-xl-n120 {
    margin-right: -7.5rem !important; }
  .mb-xl-n120,
  .my-xl-n120 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n120,
  .mx-xl-n120 {
    margin-left: -7.5rem !important; }
  .m-xl-n140 {
    margin: -8.75rem !important; }
  .mt-xl-n140,
  .my-xl-n140 {
    margin-top: -8.75rem !important; }
  .mr-xl-n140,
  .mx-xl-n140 {
    margin-right: -8.75rem !important; }
  .mb-xl-n140,
  .my-xl-n140 {
    margin-bottom: -8.75rem !important; }
  .ml-xl-n140,
  .mx-xl-n140 {
    margin-left: -8.75rem !important; }
  .m-xl-n160 {
    margin: -10rem !important; }
  .mt-xl-n160,
  .my-xl-n160 {
    margin-top: -10rem !important; }
  .mr-xl-n160,
  .mx-xl-n160 {
    margin-right: -10rem !important; }
  .mb-xl-n160,
  .my-xl-n160 {
    margin-bottom: -10rem !important; }
  .ml-xl-n160,
  .mx-xl-n160 {
    margin-left: -10rem !important; }
  .m-xl-n180 {
    margin: -11.25rem !important; }
  .mt-xl-n180,
  .my-xl-n180 {
    margin-top: -11.25rem !important; }
  .mr-xl-n180,
  .mx-xl-n180 {
    margin-right: -11.25rem !important; }
  .mb-xl-n180,
  .my-xl-n180 {
    margin-bottom: -11.25rem !important; }
  .ml-xl-n180,
  .mx-xl-n180 {
    margin-left: -11.25rem !important; }
  .m-xl-n200 {
    margin: -12.5rem !important; }
  .mt-xl-n200,
  .my-xl-n200 {
    margin-top: -12.5rem !important; }
  .mr-xl-n200,
  .mx-xl-n200 {
    margin-right: -12.5rem !important; }
  .mb-xl-n200,
  .my-xl-n200 {
    margin-bottom: -12.5rem !important; }
  .ml-xl-n200,
  .mx-xl-n200 {
    margin-left: -12.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0BAFFF !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #007fbe !important; }

.text-secondary {
  color: #8391A7 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #5c6b82 !important; }

.text-success {
  color: #2DCA73 !important; }

a.text-success:hover, a.text-success:focus {
  color: #1f8b4f !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #FFC213 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #c69200 !important; }

.text-danger {
  color: #F03F3F !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d21010 !important; }

.text-light {
  color: #F9FAFC !important; }

a.text-light:hover, a.text-light:focus {
  color: #c6d0e3 !important; }

.text-dark {
  color: #3C4858 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d232b !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-black {
  color: #000 !important; }

a.text-black:hover, a.text-black:focus {
  color: black !important; }

.text-gray-100 {
  color: #F9FAFC !important; }

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #c6d0e3 !important; }

.text-gray-200 {
  color: #F1F2F6 !important; }

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #c2c7d8 !important; }

.text-gray-300 {
  color: #E5E9F2 !important; }

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b2bed9 !important; }

.text-gray-400 {
  color: #C0CCDA !important; }

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #90a5be !important; }

.text-gray-500 {
  color: #8492A6 !important; }

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #5d6c81 !important; }

.text-gray-600 {
  color: #8391A7 !important; }

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #5c6b82 !important; }

.text-gray-700 {
  color: #5A6679 !important; }

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #39414d !important; }

.text-gray-800 {
  color: #3C4858 !important; }

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #1d232b !important; }

.text-gray-900 {
  color: #1C2C40 !important; }

a.text-gray-900:hover, a.text-gray-900:focus {
  color: #05070b !important; }

.text-blue {
  color: #0BAFFF !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #007fbe !important; }

.text-purple {
  color: #7550E9 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #471ad2 !important; }

.text-orange {
  color: #FF7D50 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #ff4404 !important; }

.text-pink {
  color: #ED63D2 !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #e51ebe !important; }

.text-green {
  color: #2DCA73 !important; }

a.text-green:hover, a.text-green:focus {
  color: #1f8b4f !important; }

.text-red {
  color: #F03F3F !important; }

a.text-red:hover, a.text-red:focus {
  color: #d21010 !important; }

.text-yellow {
  color: #FFC213 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #c69200 !important; }

.text-body {
  color: #1C2C40 !important; }

.text-muted {
  color: #8391A7 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

[data-aos][data-aos][data-aos-duration="50"],
body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"],
body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate,
body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"],
body[data-aos-duration="100"] [data-aos] {
  transition-duration: 0.1s; }

[data-aos][data-aos][data-aos-delay="100"],
body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate,
body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: 0.1s; }

[data-aos][data-aos][data-aos-duration="150"],
body[data-aos-duration="150"] [data-aos] {
  transition-duration: 0.15s; }

[data-aos][data-aos][data-aos-delay="150"],
body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate,
body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: 0.15s; }

[data-aos][data-aos][data-aos-duration="200"],
body[data-aos-duration="200"] [data-aos] {
  transition-duration: 0.2s; }

[data-aos][data-aos][data-aos-delay="200"],
body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate,
body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: 0.2s; }

[data-aos][data-aos][data-aos-duration="250"],
body[data-aos-duration="250"] [data-aos] {
  transition-duration: 0.25s; }

[data-aos][data-aos][data-aos-delay="250"],
body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate,
body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: 0.25s; }

[data-aos][data-aos][data-aos-duration="300"],
body[data-aos-duration="300"] [data-aos] {
  transition-duration: 0.3s; }

[data-aos][data-aos][data-aos-delay="300"],
body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate,
body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: 0.3s; }

[data-aos][data-aos][data-aos-duration="350"],
body[data-aos-duration="350"] [data-aos] {
  transition-duration: 0.35s; }

[data-aos][data-aos][data-aos-delay="350"],
body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate,
body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: 0.35s; }

[data-aos][data-aos][data-aos-duration="400"],
body[data-aos-duration="400"] [data-aos] {
  transition-duration: 0.4s; }

[data-aos][data-aos][data-aos-delay="400"],
body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate,
body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: 0.4s; }

[data-aos][data-aos][data-aos-duration="450"],
body[data-aos-duration="450"] [data-aos] {
  transition-duration: 0.45s; }

[data-aos][data-aos][data-aos-delay="450"],
body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate,
body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: 0.45s; }

[data-aos][data-aos][data-aos-duration="500"],
body[data-aos-duration="500"] [data-aos] {
  transition-duration: 0.5s; }

[data-aos][data-aos][data-aos-delay="500"],
body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate,
body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: 0.5s; }

[data-aos][data-aos][data-aos-duration="550"],
body[data-aos-duration="550"] [data-aos] {
  transition-duration: 0.55s; }

[data-aos][data-aos][data-aos-delay="550"],
body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate,
body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: 0.55s; }

[data-aos][data-aos][data-aos-duration="600"],
body[data-aos-duration="600"] [data-aos] {
  transition-duration: 0.6s; }

[data-aos][data-aos][data-aos-delay="600"],
body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate,
body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: 0.6s; }

[data-aos][data-aos][data-aos-duration="650"],
body[data-aos-duration="650"] [data-aos] {
  transition-duration: 0.65s; }

[data-aos][data-aos][data-aos-delay="650"],
body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate,
body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: 0.65s; }

[data-aos][data-aos][data-aos-duration="700"],
body[data-aos-duration="700"] [data-aos] {
  transition-duration: 0.7s; }

[data-aos][data-aos][data-aos-delay="700"],
body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate,
body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: 0.7s; }

[data-aos][data-aos][data-aos-duration="750"],
body[data-aos-duration="750"] [data-aos] {
  transition-duration: 0.75s; }

[data-aos][data-aos][data-aos-delay="750"],
body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate,
body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: 0.75s; }

[data-aos][data-aos][data-aos-duration="800"],
body[data-aos-duration="800"] [data-aos] {
  transition-duration: 0.8s; }

[data-aos][data-aos][data-aos-delay="800"],
body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate,
body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: 0.8s; }

[data-aos][data-aos][data-aos-duration="850"],
body[data-aos-duration="850"] [data-aos] {
  transition-duration: 0.85s; }

[data-aos][data-aos][data-aos-delay="850"],
body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate,
body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: 0.85s; }

[data-aos][data-aos][data-aos-duration="900"],
body[data-aos-duration="900"] [data-aos] {
  transition-duration: 0.9s; }

[data-aos][data-aos][data-aos-delay="900"],
body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate,
body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: 0.9s; }

[data-aos][data-aos][data-aos-duration="950"],
body[data-aos-duration="950"] [data-aos] {
  transition-duration: 0.95s; }

[data-aos][data-aos][data-aos-delay="950"],
body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate,
body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: 0.95s; }

[data-aos][data-aos][data-aos-duration="1000"],
body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"],
body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate,
body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"],
body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"],
body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate,
body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"],
body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"],
body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate,
body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"],
body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"],
body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate,
body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"],
body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"],
body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate,
body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"],
body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"],
body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate,
body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"],
body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"],
body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate,
body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"],
body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"],
body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate,
body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"],
body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"],
body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate,
body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"],
body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"],
body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate,
body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"],
body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"],
body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate,
body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"],
body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"],
body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate,
body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"],
body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"],
body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate,
body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"],
body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"],
body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate,
body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"],
body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"],
body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate,
body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"],
body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"],
body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate,
body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"],
body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"],
body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate,
body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"],
body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"],
body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate,
body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"],
body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"],
body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate,
body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"],
body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"],
body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate,
body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"],
body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"],
body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate,
body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"],
body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"],
body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate,
body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"],
body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"],
body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate,
body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"],
body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"],
body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate,
body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"],
body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"],
body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate,
body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"],
body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"],
body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate,
body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"],
body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"],
body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate,
body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"],
body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"],
body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate,
body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"],
body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"],
body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate,
body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"],
body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"],
body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate,
body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"],
body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"],
body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate,
body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"],
body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"],
body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate,
body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"],
body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"],
body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate,
body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"],
body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"],
body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate,
body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"],
body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"],
body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate,
body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"],
body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"],
body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate,
body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"],
body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"],
body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate,
body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"],
body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"],
body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate,
body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"],
body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"],
body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate,
body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"],
body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"],
body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate,
body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"],
body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"],
body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate,
body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing="linear"],
body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing="ease"],
body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing="ease-in"],
body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing="ease-out"],
body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing="ease-in-out"],
body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing="ease-in-back"],
body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing="ease-out-back"],
body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing="ease-in-out-back"],
body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing="ease-in-sine"],
body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing="ease-out-sine"],
body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"],
body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing="ease-in-quad"],
body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing="ease-out-quad"],
body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"],
body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing="ease-in-cubic"],
body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing="ease-out-cubic"],
body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"],
body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing="ease-in-quart"],
body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing="ease-out-quart"],
body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"],
body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0); }

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0); }

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0); }

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0); }

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0); }

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0); }

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform; }

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1); }

[data-aos="zoom-in"] {
  transform: scale(0.6); }

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos="zoom-out"] {
  transform: scale(1.2); }

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform; }

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0); }

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0); }

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0); }

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0); }

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0); }

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos="flip-left"] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos="flip-right"] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos="flip-up"] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos="flip-down"] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */
/* hide the link until viewport size is reached */
a.meanmenu-reveal {
  display: none; }

/* when under viewport size, .mean-container is added to body */
.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
  z-index: 99; }

.mean-container a.meanmenu-reveal {
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1px;
  font-weight: 700;
  height: 22px;
  line-height: 22px;
  margin-top: -62px;
  padding: 8px 9px;
  position: absolute;
  right: 0;
  text-decoration: none;
  top: -5px;
  width: 22px; }

.mean-container a.meanmenu-reveal span {
  background: #fff none repeat scroll 0 0;
  display: block;
  height: 4px;
  margin-top: 3px; }

.mean-container .mean-nav {
  background: transparent;
  float: left;
  margin-top: 18px;
  width: 100%; }

.mean-container .mean-nav ul {
  padding: 0 10px;
  margin: 0;
  width: 97%;
  list-style-type: none; }

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
  border-top: 1px solid #ffffff80; }

.mean-container .mean-nav ul li a {
  color: #fff;
  display: block;
  float: left;
  margin: 0;
  padding: 11px 1px;
  text-align: left;
  text-decoration: none;
  text-transform: capitalize;
  width: 100%; }

.mean-container .mean-nav ul li li a {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  padding: 10px 10%;
  text-shadow: none;
  visibility: visible;
  width: 80%; }

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0; }

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%; }

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%; }

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%; }

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255, 255, 255, 0.1); }

.mean-container .mean-nav ul li a.mean-expand {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: rgba(255, 255, 255, 0.1) none repeat scroll 0 0;
  border-color: currentcolor currentcolor rgba(255, 255, 255, 0.2) rgba(255, 255, 255, 0.4);
  -o-border-image: none;
  border-image: none;
  border-style: none none solid solid;
  border-width: medium medium 1px 1px;
  font-weight: 700;
  height: 27px;
  margin-top: 1px;
  padding: 8px 12px;
  position: absolute;
  right: 0px;
  text-align: center;
  top: 0;
  width: 26px;
  z-index: 2; }

.mean-container .mean-nav ul li a.mean-expand:hover {
  background-color: #363636; }

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both; }

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0; }

/* Fix for box sizing on Foundation Framework etc. */
.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.mean-remove {
  display: none !important; }

.transparent-header .mean-container a.meanmenu-reveal {
  border: 1px solid #fff;
  color: #fff; }

.transparent-header .mean-container a.meanmenu-reveal span {
  background: #fff; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #ccc; }

.mfp-preloader a:hover {
  color: #fff; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0;
  margin: 0 auto; }

.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

/***********************************************/
/* TABLE OF CONTENTS:
----------------------------------------------------------------

--------------------------------------------------------------

1. Default Theme CSS
    1.1 Preloader
    1.2 Section Heading
    1.3 Section Button
    1.4 Section Pattern Animation

2. Menu Area CSS
    2.1 Menu CSS
    2.2 Drop Down Menu CSS
    2.3 Sticy Menu CSS
    2.4 Fullscreen Search CSS

3. Header Area CSS
	3.1 Header Pattern Image
	3.2 Header ArtWork Animation
	3.3 Intro Text
	3.4 Header Button

4. Home About Area CSS
	4.1 Home About Content

5. Service Area CSS
	5.1 Curve SVG
	5.2 Single Service Pattern
	5.3 Single Service Animation
	5.4 Single Service Text

6. Portfolio Area CSS
	6.1 Portfolio Pattern
	6.2 Portfolio Item
	6.3 Portfolio Item Arrow

7. Expertise Area CSS

8. Counter Area CSS
	8.1 Single Counter

9. Testimonial Area CSS
	9.1 Left Content
	9.2 Right Content
	9.3 Testimonial Arrow

10. Pricing Area CSS
	10.1 Single Price
	10.2 Single Price Button
	10.3 Price Pattern Animation

11. Blog Area CSS	
	11.1 Single Blog Image
	11.2 Single Blog PostDate
	11.3 Single Blog Content

12. Footer Area CSS
	12.1 Footer Social
	12.2 Footer Content
	12.3 Copyright Text


++++++OTHER PAGES++++++


13. Blog Details Page
	13.1 Blog Banner
	13.2 Blog Side
	13.3 Blog Search
	13.4 Blog Social Share
	13.5 Blog Author
	13.6 Blog Category
	13.7 Blog Tags
	13.8 Blog Comments
	13.9 Blog Comments Reply

14. Blog List Area CSS
	14.1 Blog Pagination

15. Team Area CSS
	15.1 Team Banner
	15.2 Team Content
	15.3 Team Social

16. Contact Us Area CSS
	16.1 Contact Us Banner
	16.2 Contact Pattern
	16.2 Contact Pattern
	16.3 Single Contact Info
	16.4 Contact Form
	16.5 Contact Form Button

17. Portfolio Area CSS
	17.1 Portfolio Banner
	17.2 Portfolio Content
	17.3 Portfolio Filtering

18. Portfolio Details Area CSS
	18.1 Project  Description
	18.2 Project  Technology
	18.3 Project  Slider

19. About Us Area CSS
	19.1 About Us Banner
	19.2 About Hero Text
	19.3 About Video
	19.4 About Video Icon
	19.5 About Counter
	19.6 About Section Content

20. 404 Area CSS

21. Call to Action
--------------------------------------------------------------

--------------------------------------------------------------*/
/*==================== 1. Default Theme CSS ===================*/
html,
body {
  height: 100%;
  font-family: 'Nunito', sans-serif;
  margin: 0px;
  padding: 0px; }

::-moz-selection {
  background: #3ed60e;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #3ed60e;
  color: #fff;
  text-shadow: none; }

a:focus {
  outline: 0px solid; }

img {
  width: auto;
  height: auto; }

p {
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  color: #363636; }

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 10px; }

a {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-decoration: none; }

a:hover {
  color: #6b6fff;
  text-decoration: none; }

a:active,
a:hover {
  outline: 0 none; }

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0; }

.container {
  box-shadow: none !important;
  outline: 0px !important;
  max-width: 1300px; }

.default-margin-mt {
  margin-top: 30px; }

#scrollUp {
  bottom: 0;
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  right: 10px;
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  font-size: 45px; }

/* 1.1 Preloader*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F7F7F7;
  z-index: 99999999999999;
  height: 100%; }

#status {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  padding: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99999999999999; }

#status img {
  height: 150px;
  width: 150px; }

/*1.2 Section Heading*/
.section-heading-1 h4 {
  font-size: 23px;
  text-transform: capitalize; }

.section-heading-1 h3 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 35px;
  position: relative;
  font-size: 35px;
  display: inline-block; }

.section-heading-1 h3:before {
  position: absolute;
  content: '';
  right: 0;
  bottom: -15px;
  background-color: #66E9AE;
  height: 5px;
  width: 70px; }

.section-heading-1 p {
  color: #363636; }

.section-heading-2 {
  padding-bottom: 45px; }

.section-heading-2-p {
  padding-bottom: 0 !important; }

.section-heading-2 h4 {
  font-size: 23px;
  text-transform: capitalize; }

.section-heading-2 h3 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 35px;
  position: relative;
  font-size: 35px;
  display: inline-block; }

.section-heading-2 h3:before {
  position: absolute;
  content: '';
  left: 0;
  bottom: -15px;
  background-color: #66E9AE;
  height: 5px;
  width: 70px; }

.section-heading-2 p {
  color: #363636; }

.section-heading-3 {
  padding-bottom: 45px;
  text-align: center; }

.section-heading-3 h4 {
  font-size: 23px;
  text-transform: capitalize; }

.section-heading-3 h3 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 35px;
  position: relative;
  font-size: 35px;
  display: inline-block; }

.section-heading-3 h3:before {
  position: absolute;
  content: '';
  left: 50%;
  bottom: -15px;
  background-color: #66E9AE;
  height: 5px;
  width: 70px;
  transform: translateX(-50%); }

/*1.3 Section Button*/
.section-button a {
  background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%);
  box-shadow: 0px 2px 15px rgba(164, 164, 164, 0.25);
  border-radius: 68px;
  color: #fff;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 25px;
  display: inline-block; }

.section-button a:hover {
  background: linear-gradient(253.52deg, #84FC6A 8.92%, #48D6F2 96.59%); }

/*1.4 Section Pattern Animation*/
.e-pattern {
  position: absolute;
  right: 0px;
  top: -70px;
  z-index: -1; }

.e-pattern img {
  -webkit-animation: ep 3s linear infinite;
  animation: ep 3s linear infinite; }

@keyframes ep {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 30px); }
  to {
    transform: translate(0, 0px); } }

/*=========2. Menu Area CSS==========*/
.main-navigation {
  width: 100%;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9; }

.logo-area img {
  padding: 16px 0;
  width: 180px; }

/*2.1 Menu CSS*/
.main-menu {
  margin-right: 85px; }

.main-menu ul li {
  display: inline-block;
  margin-left: 25px;
  position: relative; }

.main-menu ul li a {
  color: #fff;
  display: inline-block;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 1;
  position: relative;
  font-weight: 700;
  padding: 40px 0;
  transition: .5s; }

.main-menu ul li a:hover {
  color: #505262; }

.main-menu ul li a:before {
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: #323657;
  left: 0;
  top: 67%;
  -webkit-transition: .7s;
  transition: .7s;
  mix-blend-mode: multiply; }

.main-menu ul li a:after {
  content: '';
  position: absolute;
  width: 0px;
  height: 3px;
  background-color: #323657;
  right: 0;
  top: 67%;
  -webkit-transition: .7s;
  transition: .7s;
  mix-blend-mode: multiply;
  z-index: 999; }

.main-menu ul li a.current:before {
  content: '';
  position: absolute;
  width: 50%;
  height: 3px;
  background-color: #323657;
  left: 0;
  top: 67%;
  -webkit-transition: .7s;
  transition: .7s;
  mix-blend-mode: multiply; }

.main-menu ul li a.current:after {
  content: '';
  position: absolute;
  width: 50%;
  height: 3px;
  background-color: #323657;
  right: 0;
  top: 67%;
  -webkit-transition: .7s;
  transition: .7s;
  mix-blend-mode: multiply;
  z-index: 9; }

.main-menu ul li a.current {
  color: #323657; }

.main-menu ul li a:hover:before {
  width: 50%;
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.main-menu ul li a:hover:after {
  width: 50%;
  -webkit-transform: translateX(100%);
  transform: translateX(-100%); }

.f-right {
  float: right; }

.mean-container .mean-nav {
  margin-top: 0;
  background: rgba(50, 54, 87, 0.9); }

.main-menu ul li i {
  margin-left: 5px; }

#mobile-menu {
  display: block; }

/*2.2 Drop Down Menu CSS*/
.main-menu ul li ul.dropdown {
  position: absolute;
  right: 0;
  top: 130%;
  width: 280px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
  box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
  border: solid 3px transparent;
  border-image: linear-gradient(238.45deg, #49e513 3.32%, #42D2FF 98.6%);
  border-image-slice: 1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .5s;
  transition: .5s;
  padding: 15px; }

ul.dropdown li a:before {
  position: absolute;
  content: '';
  background-color: transparent;
  height: 0;
  left: 0;
  top: 0;
  opacity: 0; }

ul.dropdown li a:after {
  position: absolute;
  content: '';
  background-color: transparent;
  height: 0;
  right: 0;
  top: 0;
  opacity: 0; }

.main-menu ul li:hover ul.dropdown {
  top: 106%;
  opacity: 1;
  visibility: visible; }

.main-menu ul li ul.dropdown li a:hover {
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text; }

.main-menu ul li ul.dropdown li {
  display: block;
  margin-left: 15px; }

.main-menu ul li ul.dropdown li a {
  padding: 12px 0px;
  -webkit-transition: .5s;
  transition: .5s;
  position: relative;
  color: #646572;
  font-weight: 600; }

/*2.3 Sticy Menu CSS*/
.main-navigation.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  background: linear-gradient(270deg, #00B0E7 0%, #75F389 100%);
  -webkit-box-shadow: 0px 0px 4px #016946;
  box-shadow: 0px 0px 50px #60636242;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-animation: sticky 1s;
  animation: sticky 1s; }

@-webkit-keyframes sticky {
  0% {
    top: -120px; }
  100% {
    top: 0; } }

@keyframes sticky {
  0% {
    top: -120px; }
  100% {
    top: 0; } }

.main-navigation-1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  background: linear-gradient(270deg, #00B0E7 0%, #75F389 100%);
  -webkit-box-shadow: 0px 0px 4px #016946;
  box-shadow: 0px 0px 50px #60636242;
  -webkit-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-animation: sticky 1s;
  animation: sticky 1s; }

/* 2.4 Fullscreen Search CSS*/
#search {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #00b09b;
  background: -webkit-gradient(linear, left top, right top, from(rgba(150, 201, 61, 0.9)), to(rgba(0, 176, 155, 0.9)));
  background: linear-gradient(to right, rgba(150, 201, 61, 0.9), rgba(0, 176, 155, 0.9));
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all .3s;
  transition: all .3s;
  will-change: transform, opacity;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  visibility: hidden; }

.search-icon-area {
  position: absolute;
  right: 20px;
  top: 29px; }

.search-icon-area i {
  background-color: #fff;
  color: #75DDED;
  font-size: 20px;
  padding: 13px;
  border-radius: 50%; }

.close-btn:hover {
  color: #363636; }

#search:target {
  height: 100vh !important;
  opacity: 1;
  -webkit-transition: .4s;
  transition: .4s;
  visibility: visible; }

#search:target .close-btn {
  display: block; }

#searchbox {
  background: transparent;
  border: solid #fff;
  border-width: 0 0 1px 0;
  color: #fff;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  font-size: 25px;
  max-width: 66%;
  outline: 0;
  padding: 10px 3px; }

.close-btn {
  display: none;
  color: #fff;
  font-size: 2rem;
  position: absolute;
  top: 0.5rem;
  right: 2rem; }

/*======= 3. Header Area CSS =========*/
.header-content-area {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-image: url("/assets/img/header.png");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

/*3.1 Header Pattern Image*/
.header-pattern-1 {
  position: absolute;
  left: 50%;
  top: 13%; }

.header-pattern-2 {
  position: absolute;
  left: 12%;
  top: 30%; }

.header-pattern-2 img {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*3.2 Header ArtWork Animation */
.header-animation-area {
  position: relative; }

.header-1-artwork {
  position: absolute;
  right: 60px;
  bottom: -150px; }

.layer {
  position: relative;
  z-index: -1; }

.layer-1 {
  position: absolute;
  left: -40px;
  bottom: 0px; }

.layer-2 {
  position: absolute;
  right: -55px;
  bottom: 0px; }

.layer-3 {
  position: absolute;
  right: -30px;
  top: -50px;
  z-index: -1; }

.layer-3 img {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

/*3.3 Intro Text*/
.intro-text {
  text-align: left;
  padding-top: 60px; }

.intro-text-2 {
  text-align: right;
  padding-top: 150px; }

.intro-text h1 {
  font-size: 50px;
  line-height: 65px;
  color: #3e3d3d;
  margin-bottom: 10px;
  font-weight: 900; }

/*3.4 Header Button */
.learnmore {
  margin-top: 25px;
  position: relative;
  display: inline-block; }

.learnmore2 {
  position: absolute;
  right: -55px;
  top: 7px;
  z-index: 9;
  display: inline-block; }

.learnmore .skill-btn {
  background: #323657;
  -webkit-box-shadow: 0px 1px 26px rgba(41, 147, 34, 0.62);
  box-shadow: 0px 1px 26px rgba(41, 147, 34, 0.62);
  border-radius: 50px;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  padding: 15px 40px;
  -webkit-transition: .5s;
  transition: .5s;
  font-size: 16px;
  font-weight: 700; }

.skill-btn-youtube i {
  color: #323657;
  font-size: 40px;
  transition: .5s; }

.skill-btn-youtube i:hover {
  color: #fff; }

.learnmore .skill-btn:hover {
  color: #323657;
  background-color: #fff; }

/*========= 4. Home About Area CSS =========*/
.home-about-area {
  padding: 120px 0 60px 0;
  background-color: #fff; }

/*4.1 Home About Content*/
.home-about-image {
  text-align: right; }

.home-about-image img {
  width: 88%; }

.home-about-text {
  text-align: right;
  padding-right: 70px;
  position: relative;
  z-index: 1; }

/*========= 5. Service Area CSS ==========*/
.services-area {
  padding: 120px 0 45px 0;
  background: linear-gradient(to top, #FDFFFD, #F5FFF5, rgba(202, 245, 202, 0.3));
  position: relative; }

/*5.1 Curve SVG*/
#curve {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(180deg); }

#curve path {
  fill: #fff; }

.single-service {
  background: #FFFFFF;
  box-shadow: 0px 1px 35px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  text-align: center;
  padding: 75px 30px 30px 30px;
  position: relative;
  z-index: 1; }

/*5.2 Single Service Pattern*/
.servive-p-1 {
  position: absolute;
  top: -80px;
  left: -50px; }

.servive-p-2 {
  position: absolute;
  bottom: -23px;
  left: -128px; }

/*5.3 Single Service Animation*/
.servive-p-1 img {
  -webkit-animation: sp 3s linear infinite;
  animation: sp 3s linear infinite; }

.servive-p-2 img {
  -webkit-animation: sp 3s linear infinite;
  animation: sp 3s linear infinite; }

@keyframes sp {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 30px); }
  to {
    transform: translate(0, 0px); } }

/*5.4 Single Service Text*/
.service-text h3 {
  background: linear-gradient(253.52deg, #84FC6A 8.92%, #48D6F2 96.59%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px; }

.service-text p {
  font-size: 16.5px;
  line-height: 24px; }

.service-icon img {
  height: 80px;
  margin-bottom: 20px;
  transition: .25s; }

.service-icon img:hover {
  transform: scale(1.2); }

.service-mt-30 {
  margin-top: 30px; }

/*=========6. Portfolio Area CSS==========*/
.portfolio-area {
  padding: 120px 0;
  background: -webkit-linear-gradient(to bottom, #FDFFFD, #F5FFF5A8, #a3f2a324);
  background: linear-gradient(to bottom, #FDFFFD, #F5FFF5A8, #a3f2a324);
  border-bottom-left-radius: 73% 40%;
  border-bottom-right-radius: 50% 20%; }

/*6.1 Portfolio Pattern*/
.portfolio-pattern {
  position: relative;
  z-index: 1; }

.p-pattern {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: -1; }

.p-pattern img {
  -webkit-animation: pp 3s linear infinite;
  animation: pp 3s linear infinite; }

@keyframes pp {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 30px); }
  to {
    transform: translate(0, 0px); } }

.portfolio-button-area {
  text-align: right; }

.single-portfolio-item img {
  border-radius: 15px;
  width: 100%; }

/*6.2 Portfolio Item*/
.single-portfolio-item {
  position: relative;
  margin: 0 15px; }

.single-portfolio-item:last-child {
  margin-right: 0px; }

.single-portfolio-item:hover .portfolio-item-content {
  visibility: visible;
  opacity: 1;
  transform: scale(1); }

.portfolio-item-content {
  position: absolute;
  left: 3%;
  top: 3%;
  width: 94%;
  height: 94%;
  background-color: rgba(255, 255, 255, 0.95);
  transition: .6s;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.7);
  border-radius: 10px; }

.item-icon {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%); }

.item-icon i {
  background-color: #D7D7D7;
  color: #fff;
  font-size: 30px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  transition: .5s; }

.item-icon i:hover {
  background-color: #66E9AE; }

.item-text {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding: 20px; }

.item-text h5 {
  background: linear-gradient(253.52deg, #84FC6A 8.92%, #48D6F2 96.59%);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  font-size: 22px; }

.item-text p {
  font-size: 16px;
  line-height: 22px; }

/*6.3 Portfolio Item Arrow*/
.left-arrow {
  position: absolute;
  right: 90px;
  top: -70px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 2px solid #75DDED;
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  cursor: pointer;
  opacity: .5;
  transition: .5s; }

.right-arrow {
  position: absolute;
  right: 30px;
  top: -70px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: solid 2px #75DDED;
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  cursor: pointer;
  opacity: .5;
  transition: .5s; }

.right-arrow:hover {
  opacity: 1; }

.left-arrow:hover {
  opacity: 1; }

/*========= 7. Expertise Area CSS==========*/
.expertise-area {
  padding: 120px 0;
  background-color: #fff; }

.expertise-image img {
  width: 100%; }

.home-expertise-text {
  text-align: right;
  padding-right: 70px;
  position: relative;
  z-index: 1; }

/*========= 8. Counter Area CSS==========*/
.main-counter-area {
  padding: 120px 0 0px 0; }

/*8.1 Single Counter*/
.single-count-area {
  background-color: #fff;
  box-shadow: 0px 1px 31px rgba(187, 187, 187, 0.25);
  border-radius: 20px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  padding: 50px 0 !important; }

.single-count-item-mb {
  margin-bottom: 30px; }

.home-counter-text {
  text-align: right;
  padding-right: 70px;
  position: relative;
  z-index: 1; }

.single-count-area span {
  font-size: 29px;
  color: #173D7A !important;
  margin-top: 15px;
  display: inline-block; }

.single-count-area p {
  font-size: 17px;
  color: #173D7A; }

.counter-area-small {
  padding-right: 50px; }

/*========= 9. Testimonial Area CSS==========*/
.client-area {
  background-color: #F7FFF7;
  padding: 70px; }

.testimonial-area {
  padding: 120px 0;
  background: -webkit-linear-gradient(to bottom, #FDFFFD, #F5FFF5A8, #a3f2a324);
  background: linear-gradient(to bottom, #FDFFFD, #F5FFF5A8, #a3f2a324);
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%; }

/*9.1 Left Content*/
.left-content {
  float: left;
  width: 70%;
  position: relative; }

.testi-slider-text {
  margin-top: 30px;
  padding: 0 0 0 15px; }

.right-quote {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.quotation {
  padding-left: 15px; }

.testi-slider-text h4 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px; }

.testi-slider-text h5 {
  text-transform: capitalize;
  font-size: 18px; }

/*9.2 Right Content*/
.right-content {
  overflow: hidden;
  width: 30%; }

.testimonial-img {
  text-align: right; }

.testimonial-img img {
  display: inline;
  border-radius: 68% 68% 2% 68% / 35% 50% 0 38%;
  animation: morph 2s ease-in-out infinite both alternate; }

@keyframes morph {
  0% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%; }
  100% {
    border-radius: 2% 68% 68% 68% / 35% 50% 0 35%; } }

@keyframes spin {
  to {
    transform: rotate(1turn); } }

/*9.3 Testimonial Arrow*/
.left-arrow-2 {
  position: absolute;
  left: 15px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 2px solid #75DDED;
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  cursor: pointer;
  opacity: .5;
  transition: .5s;
  z-index: 9; }

.right-arrow-2 {
  position: absolute;
  left: 75px;
  bottom: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 2px solid #75DDED;
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  cursor: pointer;
  opacity: .5;
  transition: .5s; }

.right-arrow-2:hover {
  opacity: 1; }

.left-arrow-2:hover {
  opacity: 1; }

/*========= 10. Pricing Area CSS =========*/
.pricing-plan-area {
  padding: 120px 0;
  background-color: #fff; }

/*10.1 Single Price*/
.single-price {
  background: #fbfbfb;
  box-shadow: 0px 4px 25px rgba(36, 35, 35, 0.09);
  border-radius: 20px;
  position: relative;
  transition: .6s; }

.single-price.active-s {
  background: #FFFFFF;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  transform: translateY(-10px); }

.single-price:hover {
  background-color: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.09); }

.single-price:hover .package h3 {
  background: linear-gradient(180deg, #7FF977 0%, #48D6F2 229.46%); }

.single-price:hover .price-button a {
  background: linear-gradient(252.64deg, #48D6F2 8.92%, #84FC6A 96.59%); }

.package h3 {
  background: #F5F5F5;
  border-radius: 20px 20px 0px 0px;
  text-align: center;
  padding: 30px 0;
  color: #173D7A;
  font-weight: 700;
  font-size: 32px;
  transition: .2s; }

.package.active-h h3 {
  background: linear-gradient(180deg, #7FF977 0%, #48D6F2 229.46%);
  color: #fff;
  padding: 35px 0; }

.price-qoute {
  padding: 10px 0 5px 50px; }

.price-qoute h2 {
  color: #173D7A;
  font-size: 30px;
  margin-top: 10px;
  font-weight: 900; }

.price-qoute h2 span {
  font-size: 27px;
  font-weight: 700; }

.price-details {
  padding: 0 50px; }

.price-details ul li {
  line-height: 40px;
  color: #5c5e71;
  font-size: 17px; }

.price-details ul i {
  color: #a0e0a0;
  margin-right: 10px; }

/*10.2 Single Price Button*/
.price-button {
  text-align: center; }

.price-button a {
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(214, 214, 214, 0.25);
  border-radius: 68px;
  color: #173D7A;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 13px 30px;
  display: inline-block;
  margin: 30px 0;
  font-weight: 700; }

.price-button.active-b a {
  background: linear-gradient(252.64deg, #48D6F2 8.92%, #84FC6A 96.59%);
  color: #fff; }

/*10.3 Price Pattern Animation*/
.price-pattern {
  position: absolute;
  right: 30px;
  top: 130px;
  transform: rotate(120deg); }

.price-pattern img {
  -webkit-animation: ptp 3s linear infinite;
  animation: ptp 3s linear infinite; }

@keyframes ptp {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(0, 30px); }
  to {
    transform: translate(0, 0px); } }

/*========= 11. Blog Area CSS==========*/
.home-blog-area {
  background-color: #F7FFF7;
  padding: 120px 0; }

/*11.1 Single Blog Image*/
.s-blog-image {
  position: relative;
  overflow: hidden; }

.home-single-blog:hover .s-blog-image img {
  transform: scale(1.1); }

.s-blog-image img {
  width: 100%;
  transition: .5s;
  transform: scale(1); }

/*11.2 Single Blog PostDate*/
.blog-post-date {
  position: absolute;
  right: 0;
  top: 30px;
  background: linear-gradient(255.91deg, #79F583 5.33%, #4EDAE5 92.37%);
  border-radius: 30px 0px 0px 30px;
  background: linear-gradient(255.91deg, #79F583 5.33%, #4EDAE5 92.37%);
  border-radius: 30px 0px 0px 30px;
  color: #fff;
  width: 80px;
  height: 40px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 40px;
  text-align: center;
  transition: .5s; }

.s-blog-image:hover .blog-post-date {
  width: 95px; }

/*11.3 Single Blog Content*/
.s-blog-content {
  margin-top: 25px; }

.s-blog-content h4 {
  font-weight: 700;
  color: #323657;
  text-transform: capitalize; }

.s-blog-content a {
  font-weight: 700;
  display: inline-block;
  color: #42D2FE;
  margin-top: 5px;
  margin-bottom: 10px; }

.s-blog-content a:hover {
  background: linear-gradient(238.45deg, #49e513 3.32%, #42D2FF 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; }

/*========= 11. Footer Area CSS ==========*/
.big-footer {
  background-color: #151723;
  padding: 120px 0;
  background-image: url("/assets/img/./f-pattern.png"); }

#footer-fixed {
  width: 100%; }

.footer-logo img {
  width: 200px; }

.footer-logo p {
  color: #fff;
  line-height: 26px;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px; }

/*12.1 Footer Social*/
.social ul li {
  display: inline-block;
  margin-right: 15px; }

.social ul li:last-child {
  margin-right: 0; }

.social ul li a {
  display: inline-block;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  -webkit-transition: .5s;
  transition: .5s; }

.social ul li a:hover {
  color: #85FF7F; }

/*12.2 Footer Content*/
.footer-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 5px; }

.footer-content ul {
  margin-top: 30px; }

.footer-content.footer-cont-mar-40 ul {
  margin-top: 0px; }

.footer-content ul li a {
  color: #efefef;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 30px; }

.footer-content ul li a:hover {
  color: #85FF7F; }

.footer-content form {
  margin-top: 20px; }

.footer-content form input#leadgenaration {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  color: #fff;
  padding: 15px 0px;
  margin-bottom: 30px;
  -webkit-transition: .5s;
  transition: .5s;
  display: block;
  width: 100%; }

.footer-content form input#subscribe {
  background: linear-gradient(252.62deg, #48D6F2 8.92%, #84FC6A 96.59%);
  box-shadow: 0px 2px 15px rgba(164, 164, 164, 0.25);
  border-radius: 68px;
  border: none;
  color: #fff;
  padding: 10px 25px;
  cursor: pointer;
  -webkit-transition: all .5s;
  transition: all .5s;
  font-size: 15px;
  -webkit-transition: .5s;
  transition: .5s; }

.footer-content form input#subscribe:hover {
  background: linear-gradient(252.62deg, #84FC6A 8.92%, #48D6F2 96.59%); }

.footer-content form input#leadgenaration:focus {
  padding: 15px 10px; }

/*12.3 Copyright Text*/
footer {
  background-color: #090c21;
  text-align: center;
  padding: 20px 0;
  width: 100%; }

footer p {
  margin: 0;
  color: #fff;
  font-size: 15px; }

/*============= 13. Blog Details Page ==============*/
/*13.1 Blog Banner*/
.blog-hero-banner {
  background-image: url("/assets/img/blog/blog-bg.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.blog-hero-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45); }

.blog-hero-banner-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.blog-hero-banner h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.blog-hero-banner p {
  color: #fff; }

.blog-body {
  background-color: #fff; }

.blog-area {
  padding: 120px 0; }

/*13.2 Blog Side*/
.left-side {
  padding: 60px 20px 20px 0px; }

.right-side {
  padding: 60px 0px 20px 20px; }

.left-side-2 {
  padding: 60px 20px 20px 0px; }

.right-side-2 {
  padding: 60px 0px 20px 20px; }

.blog-post-heading h1 {
  font-size: 37px;
  color: #363636;
  text-transform: capitalize;
  margin-top: 0;
  font-weight: 700; }

.blog-post-heading span {
  font-weight: 700;
  text-transform: capitalize; }

/*13.3 Blog Search*/
.blog-search input {
  width: 100%;
  background-color: #F9F9F9;
  border: none;
  padding: 15px 10px;
  position: relative; }

.blog-search-icon-small {
  position: absolute;
  top: 72px;
  right: 30px;
  background: none;
  border: none;
  color: #ddd;
  font-size: 20px;
  cursor: pointer; }

.blog-body-content {
  padding: 30px 0; }

.blog-body-content p {
  font-size: 16.5px;
  line-height: 28px !important; }

.blog-inner {
  padding: 30px 0; }

.blog-inner img {
  width: 100%; }

/*13.4 Blog Social Share*/
.blog-share {
  margin-top: 30px; }

.blog-share h4 {
  font-size: 21px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 15px; }

.blog-share ul {
  margin-top: 20px; }

.blog-share ul li {
  display: inline-block;
  margin-right: 8px; }

.blog-share ul li a {
  display: inline-block;
  background: linear-gradient(252.62deg, #48D6F2 8.92%, #84FC6A 96.59%);
  color: #fff;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  font-size: 15px; }

/*13.5 Blog Author*/
.author-profile {
  margin-top: 40px; }

.author-profile h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 10px;
  color: #363636;
  padding-left: 20px; }

.author-content {
  background-color: #F9F9F9;
  padding: 20px; }

.author-profile img {
  width: 100%;
  margin-bottom: 30px; }

.author-profile h4 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  color: #363636; }

.author-profile span {
  text-transform: capitalize;
  font-size: 16px; }

.author-profile p {
  font-size: 15.5px;
  margin-top: 10px;
  line-height: 27px; }

/*13.6 Blog Category*/
.main-category {
  margin-top: 40px; }

.main-category h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 10px;
  color: #363636;
  padding-left: 20px; }

.category-list {
  background-color: #F9F9F9;
  padding: 20px; }

.category-list ul li {
  padding: 11px 0;
  border-bottom: 1px solid #ebebeb;
  color: #212121;
  font-size: 15px; }

.category-list ul li:last-child {
  border-bottom: none;
  padding-bottom: 0; }

.category-list ul li:first-child {
  padding-top: 0; }

.category-list ul li a {
  color: #363636;
  font-size: 15px;
  text-transform: capitalize; }

.category-list ul li a:hover {
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #49e513 98.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; }

/*13.7 Blog Tags*/
.main-tags {
  margin-top: 40px; }

.main-tags h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 10px;
  color: #363636;
  padding-left: 20px; }

.tag-list {
  background-color: #F9F9F9;
  padding: 20px; }

.tag-list ul li {
  display: inline-block;
  margin: 5px; }

.tag-list ul li a {
  padding: 5px;
  font-size: 15px;
  color: #363636;
  display: inline-block;
  border: 1px solid #ebebeb;
  text-transform: capitalize; }

.tag-list ul li a:hover {
  background: linear-gradient(252.62deg, #48D6F2 8.92%, #84FC6A 96.59%);
  border: 1px solid transparent; }

/*13.8 Blog Comments*/
.single-comment {
  margin-bottom: 25px;
  display: block; }

.single-comment:last-child {
  margin-bottom: 0; }

.comment-heading h4 {
  font-size: 21px;
  margin: 40px 0;
  font-weight: 600;
  text-transform: capitalize; }

.image-box {
  float: left;
  margin-right: 20px; }

.image-box img {
  width: 80px;
  height: 80px; }

.text-box {
  overflow: hidden; }

.text-box p {
  font-size: 16px;
  line-height: 26px;
  margin: 0; }

/*13.9 Blog Comments Reply*/
.text-box a.replay {
  background-color: #F9F9F9;
  color: #212121;
  padding: 2px 11px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 13px;
  margin-top: 10px; }

.text-box a.replay:hover {
  background: linear-gradient(252.62deg, #48D6F2 8.92%, #84FC6A 96.59%); }

.nesting-reply {
  margin-top: 30px;
  overflow: hidden; }

.nesting-reply img {
  width: 60px;
  height: 60px; }

.nesting-reply a.replay {
  background-color: #F9F9F9;
  color: #212121;
  padding: 2px 9px;
  display: inline-block;
  text-transform: capitalize;
  font-size: 13px;
  margin-top: 10px; }

.comment-field input {
  width: 100%;
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 10px;
  border: none; }

.comment-field textarea {
  width: 100%;
  background-color: #F8F8F8;
  margin-bottom: 10px;
  border: none;
  padding: 10px; }

.comment-field #postcomment {
  background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%);
  border-radius: 68px;
  color: #fff;
  padding: 10px 30px;
  font-weight: 700;
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 25px;
  display: inline-block;
  border: none;
  cursor: pointer; }

.comment-field #postcomment:hover {
  background: linear-gradient(253.52deg, #84FC6A 8.92%, #48D6F2 96.59%); }

/*=========== 14. Blog List Area CSS =============*/
.blog-hero-home {
  background-image: url("/assets/img/blog/blog-home.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.blog-hero-home:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45); }

.blog-home-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.blog-home-text h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.blog-home-text p {
  color: #F9F9F9; }

.main-blog-list {
  padding: 120px 0; }

/*14.1 Blog Pagination*/
.next-previous-page {
  margin: 60px 0 0 0; }

.page-link {
  padding: .7rem 1.2rem;
  color: #323657;
  margin: 0 1px; }

.page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #323657;
  border-color: #323657; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #323657;
  border-color: #323657; }

.pagination {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

/*========= 15. Team Area CSS =========*/
.team-area {
  background-color: #fff;
  padding: 120px 0; }

/*15.1 Team Banner*/
.team-hero-banner {
  background-color: #fff;
  background-image: url("/assets/img/team/team-bg.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.team-hero-banner img {
  width: 100%; }

.team-hero-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.45); }

.team-banner-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.team-banner-text h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.team-banner-text p {
  color: #fff; }

/*15.2 Team Content*/
.team-image {
  position: relative;
  overflow: hidden;
  border-radius: 15px; }

.team-image img {
  width: 100%; }

.person-name {
  margin-top: 30px;
  transition: .6s; }

.person-name h4 {
  font-weight: 700;
  color: #323657;
  text-transform: capitalize;
  font-size: 22px;
  margin: 5px 0; }

.person-name p {
  color: #7D7D7D;
  font-size: 18px;
  text-transform: capitalize; }

.team-content {
  position: absolute;
  left: 3%;
  top: 3%;
  width: 94%;
  height: 94%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.7);
  -webkit-transition: .6s;
  transition: .8s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; }

.team-image:hover .team-content {
  opacity: 1;
  visibility: visible;
  transform: scale(1); }

.single-team:hover .person-name {
  margin-left: 15px; }

/*15.3 Team Social*/
.team-social ul li {
  display: inline-block;
  margin-right: 7px; }

.team-social ul li a {
  display: inline-block;
  color: #323657;
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #323657;
  border-radius: 50%; }

.team-social ul li a:hover {
  color: #fff;
  background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%);
  border: 1px solid transparent; }

/*========= 16. Contact Us Area CSS =========*/
.contactus-area {
  background-color: #fff;
  padding: 160px 0 120px 0; }

/*16.1 Contact Us Banner*/
.contact-hero-banner {
  background-color: #fff;
  background-image: url("/assets/img/contact-bg.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.contact-hero-banner img {
  width: 100%; }

.contact-hero-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.45); }

.contact-banner-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.contact-banner-text h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.contact-banner-text p {
  color: #fff; }

/*16.2 Contact Pattern*/
.contact-left-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1; }

.contact-left-bg img {
  -webkit-animation: cp1 3s linear infinite;
  animation: cp1 3s linear infinite; }

@keyframes cp1 {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(30px, 0px); }
  to {
    transform: translate(0, 0px); } }

.contact-right-bg {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1; }

.contact-right-bg img {
  -webkit-animation: cp2 3s linear infinite;
  animation: cp2 3s linear infinite; }

@keyframes cp2 {
  from {
    transform: translate(0, 0px); }
  65% {
    transform: translate(-30px, 0px); }
  to {
    transform: translate(0, 0px); } }

/*16.3 Single Contact Info*/
.single-contact-area {
  position: relative;
  z-index: 1; }

.single-contact-info {
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 25px rgba(194, 194, 194, 0.25);
  box-shadow: 0px 2px 25px rgba(194, 194, 194, 0.25);
  padding: 80px 0;
  text-align: center;
  margin: 0 20px; }

.info-icon {
  margin-top: -125px;
  margin-bottom: 50px; }

.info-icon i {
  background: linear-gradient(180deg, #89F794 0%, #4EDAE5 100%);
  color: #fff;
  height: 90px;
  width: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  font-size: 35px; }

.info-content h6 {
  background: linear-gradient(238.45deg, #42D2FF 10.32%, #49e513 88.6%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 22px; }

/*16.4 Contact Form*/
.contact-form-area {
  background: #fff;
  -webkit-box-shadow: 0px 9px 45px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 25px rgba(194, 194, 194, 0.25);
  border-radius: 26px;
  padding: 100px;
  margin-top: 120px;
  position: relative;
  z-index: 1; }

.contact-form-heading h3 {
  color: #323657;
  text-align: center;
  margin-bottom: 60px;
  font-weight: 700; }

.contact-form-area input[type='text'] {
  width: 49.6%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #323657;
  padding: 10px 5px;
  color: #323657; }

.contact-form-area input[type='email'] {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #323657;
  margin-top: 60px;
  margin-bottom: 60px;
  color: #323657;
  padding: 10px 5px; }

.contact-form-area textarea {
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #323657;
  color: #323657;
  padding: 10px 5px; }

/*16.5 Contact Form Button*/
.send-btn {
  text-align: center;
  margin-top: 80px; }

#formsend {
  background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%);
  color: #fff;
  border: none;
  padding: 12px 42px;
  text-transform: capitalize;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: .5s;
  transition: .5s;
  border-radius: 68px; }

#formsend:hover {
  background: linear-gradient(253.52deg, #84FC6A 8.92%, #48D6F2 96.59%); }

/*=========== 17. Portfolio Area CSS ==============*/
.portfolio-main-area {
  background-color: #fff;
  padding: 120px 0; }

/*17.1 Portfolio Banner*/
.portfolio-hero-banner {
  background-color: #fff;
  background-image: url("/assets/img/portfolio/portfolio-bg.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.portfolio-hero-banner img {
  width: 100%; }

.portfolio-hero-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.45); }

/*17.2 Portfolio Content*/
.portfolio-hero-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.portfolio-hero-text h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.portfolio-hero-text p {
  color: #fff; }

.portfolio-intro p {
  text-align: center;
  font-weight: 600;
  margin-bottom: 60px;
  font-size: 20px; }

.single-portfolio img {
  width: 100%; }

.single-portfolio {
  overflow: hidden; }

.portfolio-image {
  position: relative; }

.portfolio-image img {
  border-radius: 10px; }

.portfolio-titile h4 {
  font-size: 18px;
  text-transform: capitalize;
  color: #323657;
  font-weight: 700;
  margin-top: 13px;
  transition: .6s; }

.portfolio-headmove:hover .portfolio-titile h4 {
  margin-left: 15px; }

.portfolio-content {
  position: absolute;
  left: 3%;
  top: 3%;
  width: 94%;
  height: 94%;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-transition: 1s;
  transition: .6s;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.7);
  border-radius: 10px; }

.portfolio-content a {
  background: linear-gradient(to bottom, #84FC6A 8.92%, #48D6F2 96.59%);
  background-clip: border-box;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 700;
  margin-top: 10px;
  display: block; }

.portfolio-content p {
  color: #323657;
  font-size: 17px;
  line-height: 25px; }

.single-portfolio:hover .portfolio-content {
  opacity: 1;
  visibility: visible;
  transform: scale(1); }

/*17.3 Portfolio Filtering*/
.portfolio-filter {
  text-align: center;
  margin-bottom: 30px; }

.portfolio-filter li {
  display: inline-block;
  margin-right: 25px;
  position: relative; }

.portfolio-filter li:last-child {
  margin-right: 0px; }

.portfolio-filter li a {
  display: inline-block;
  padding: 8px 0px;
  color: #000;
  text-transform: capitalize; }

.portfolio-filter li.active:before {
  position: absolute;
  content: "";
  width: 100%;
  -webkit-box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
  box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.1);
  border: solid 1px transparent;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-image: linear-gradient(238.45deg, #49e513 3.32%, #42D2FF 98.6%);
  border-image-slice: 100%;
  border-image-slice: 1;
  bottom: 0;
  left: 0;
  -webkit-transition: 1s;
  transition: 1s; }

/*========= 18. Portfolio Details Area CSS=========*/
.portfolio-details {
  padding: 120px 0;
  background-color: #fff; }

.portfolio-details-box {
  -webkit-box-shadow: 0px 4px 60px rgba(191, 191, 191, 0.25);
  box-shadow: 0px 4px 60px rgba(191, 191, 191, 0.25); }

.portfolio-screenshot img {
  width: 100%; }

/*18.1 Project  Description*/
.project-description h3 {
  font-size: 23px;
  font-weight: 600;
  text-transform: capitalize; }

.project-description p {
  padding-right: 30px; }

.project-name {
  margin-bottom: 15px; }

.project-name h3 {
  font-size: 23px;
  font-weight: 600;
  text-transform: capitalize;
  padding-top: 20px; }

.project-info {
  background: #FFFFFF;
  box-shadow: 0px 4px 50px rgba(196, 196, 196, 0.25); }

.project-info h3 {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 15px;
  text-transform: capitalize; }

.project-info h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: capitalize; }

.project-info h4 span {
  font-weight: normal;
  text-transform: none; }

.project-brief {
  margin-top: 50px; }

.project-info {
  margin-top: 50px;
  background-color: #FAFAFA;
  padding: 30px; }

.margin-top-project {
  margin-top: 40px; }

/*18.2 Project  Technology*/
.project-technology h3 {
  font-size: 23px;
  font-weight: 600;
  text-transform: capitalize; }

.project-technology {
  margin-top: 40px;
  background-color: #FAFAFA;
  padding: 30px; }

.project-technology ul li {
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  margin: 5px;
  background-color: #fff;
  padding: 5px 10px;
  -webkit-transition: .5s;
  transition: .5s; }

.project-technology ul li:hover {
  background: linear-gradient(238.45deg, #42D2FF 3.32%, #88FF5F 98.6%); }

/*18.3 Project  Slider*/
.left-arrow-3 {
  position: absolute;
  left: 47%;
  bottom: 15px;
  z-index: 1;
  color: #fff;
  cursor: pointer;
  transform: translateX(-50%); }

.right-arrow-3 {
  position: absolute;
  right: 47%;
  bottom: 15px;
  z-index: 1;
  color: #fff;
  cursor: pointer;
  transform: translateX(-50%); }

/*======== 19. About Us Area CSS =======*/
.abou-us-area {
  padding: 120px 0;
  background-color: #fff; }

/*19.1 About Us Banner*/
.about-hero-banner {
  background-color: #fff;
  background-image: url("/assets/img/about-banner.jpg");
  width: 100%;
  height: 50vh;
  background-position: center;
  background-size: cover;
  position: relative; }

.about-hero-banner img {
  width: 100%; }

.about-hero-banner:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.45); }

/*19.2 About Hero Text*/
.about-hero-text {
  position: absolute;
  left: 0;
  top: 55%;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.about-hero-text h1 {
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
  font-size: 40px; }

.about-hero-text p {
  color: #fff; }

/*19.3 About Video*/
.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #33b506;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite; }

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: rgba(132, 252, 106, 0.5);
  border-radius: 50%;
  -webkit-transition: all 200ms;
  transition: all 200ms; }

.video-play-button:hover:after {
  background: #6ef43f; }

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto; }

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0px;
  height: 0;
  border-left: 20px solid #fff;
  border-top: 14px solid transparent;
  border-bottom: 13px solid transparent;
  margin-right: -6px; }

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0; } }

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0; } }

.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: all ease 500ms;
  transition: all ease 500ms; }

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1; }

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 200ms;
  transition: all 200ms; }

.video-overlay-close:hover {
  color: #fa183d; }

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75); }

.youtube-video-wrapper {
  background: url("/assets/img/about-video.png") top center no-repeat;
  background-size: cover;
  padding: 160px 0;
  position: relative;
  text-align: center;
  border-radius: 15px; }

.popup-gallery {
  display: inline-block;
  position: relative; }

/*19.4 About Video Icon*/
.youtube-video-icon {
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 50% 50%;
  display: inline-block;
  background: #fff;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.youtube-video-icon i {
  font-size: 60px;
  line-height: 100px;
  color: #6B02FF;
  margin-left: 10px;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out; }

.about-team img {
  width: 100%; }

/*19.5 About Counter*/
.about-counter-area {
  background-color: #F7FFF7;
  padding: 120px 0; }

.about-counter-text {
  text-align: left !important; }

/*19.6 About Section Content*/
.about-section-title h4 {
  font-size: 23px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 15px; }

.about-section-separate {
  margin-top: 120px; }

.about-content-right {
  text-align: right; }

/*====== 20. 404 Area CSS ========*/
.error-page-area {
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #abd8f2;
  padding: 120px 0; }

.error-text {
  text-align: center; }

.error-text h2 {
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 40px;
  font-size: 28px; }

.error-text a {
  background-color: #323657;
  text-transform: capitalize;
  font-weight: 600;
  padding: 15px 30px;
  color: #fff;
  display: inline-block;
  margin-top: 30px;
  border: 2px solid transparent;
  border-radius: 68px; }

.error-text a:hover {
  border: 2px solid #323657;
  color: #23D89B;
  background-color: #323657; }

/*============== 21. Call to Action ============*/
.callto-action {
  background-color: #191c2f;
  color: #fff;
  padding: 45px 0; }

.callto-action-text h5 {
  font-size: 30px; }

.callto-action span {
  font-weight: 700; }

.callto-action-btn {
  text-align: right; }

.callto-action-btn a {
  background: transparent;
  border-radius: 68px;
  text-transform: capitalize;
  padding: 12px 30px;
  margin-top: -5px;
  display: inline-block;
  font-weight: 600;
  -webkit-transition: .7s;
  transition: .7s;
  border-radius: 30px;
  transition: .7s;
  border-radius: 30px;
  border: 2px solid #fff;
  color: #fff; }

.callto-action-btn a:hover {
  background: linear-gradient(252.62deg, #84FC6A 8.92%, #48D6F2 96.59%);
  border: 2px solid transparent; }

/* Large desktop :1280px. */
/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1200px) {
  #searchbox {
    max-width: 70%;
    font-size: 25px; }
  .home-counter-text {
    margin-top: 70px; }
  .testimonial-area {
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%; }
  .left-arrow-2 {
    bottom: -40px; }
  .right-arrow-2 {
    bottom: -40px; }
  .section-heading-2-p-a {
    padding-bottom: 45px; } }

/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  #searchbox {
    max-width: 90%;
    font-size: 23px; }
  .layer img {
    width: 270px; }
  .layer-1 img {
    width: 80px; }
  .layer-2 img {
    width: 120px; }
  .layer-3 img {
    width: 60px; }
  .section-heading-1 h3 {
    font-size: 28px; }
  .single-client-img {
    margin-bottom: 15px;
    text-align: center; }
  .default-margin-mt-sm {
    margin-top: 30px; }
  .intro-text h1 {
    font-size: 35px;
    line-height: 50px; }
  .main-menu ul li {
    margin-left: 15px; }
  .single-service {
    padding: 25px 15px; }
  .portfolio-area {
    border-bottom-left-radius: 73% 20%;
    border-bottom-right-radius: 50% 20%; }
  .home-counter-text {
    margin-top: 70px; }
  .testimonial-area {
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%; }
  .left-arrow-2 {
    bottom: -40px; }
  .right-arrow-2 {
    bottom: -40px; }
  .price-details {
    padding: 0 10px; }
  .price-qoute {
    padding: 10px 0 5px 10px; }
  .footer-logo img {
    width: 160px; }
  .footer-logo p {
    font-size: 15px; }
  .footer-content ul li a {
    font-size: 15px; }
  .single-contact-info {
    margin: 0; } }

/* small mobile :320px. */
@media only screen and (min-width: 320px) and (max-width: 574px) {
  .search-icon-area {
    right: 20%;
    top: -67px; }
  #searchbox {
    max-width: 90%;
    font-size: 20px; }
  .search-icon-area i {
    background-color: transparent;
    color: #fff; }
  .header-content-area {
    background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%); }
  .header-pattern-1 {
    display: none; }
  .header-pattern-2 {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .layer {
    display: none; }
  .intro-text {
    padding-top: 30px;
    margin-bottom: 0px; }
  .intro-text h1 {
    font-size: 32px;
    line-height: 45px; }
  .single-client-img {
    margin-bottom: 15px;
    text-align: center; }
  .margin-top-sb-30 {
    margin-top: 30px !important; }
  .margin-bot-sb-30 {
    margin-bottom: 45px; }
  .default-margin-mt {
    margin-top: 0; }
  .testimonial-area {
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%; }
  .testimonial-img img {
    width: 95%; }
  .left-arrow-2 {
    bottom: -40px; }
  .right-arrow-2 {
    bottom: -40px; }
  .quotation img {
    width: 60px; }
  .testi-slider-text {
    margin-top: 15px; }
  .contactus-area {
    margin-bottom: 0px; }
  .contact-form-area {
    padding: 30px; }
  .contact-form-area input[type='text'] {
    width: 100%; }
  .blog-post-heading h1 {
    font-size: 30px; }
  .portfolio-area {
    border-bottom-left-radius: 73% 15%;
    border-bottom-right-radius: 50% 10%; }
  .portfolio-button-area {
    text-align: left;
    margin-bottom: 30px; }
  .portfolio-intro p {
    font-size: 18px; }
  .portfolio-hero-text {
    top: 67%; }
  .portfolio-hero-text h1 {
    font-size: 32px; }
  .portfolio-hero-text p {
    line-height: 25px; }
  .portfolio-filter {
    margin-bottom: 60px; }
  .home-counter-text {
    margin-top: 70px;
    padding-right: 0; }
  .home-about-text {
    margin-top: 70px;
    padding-right: 0; }
  .home-expertise-text {
    margin-top: 70px;
    padding-right: 0; }
  .section-heading-2-p {
    padding-bottom: 45px !important; }
  .counter-area-small {
    padding-right: 0px; }
  .blog-text {
    width: 100%; }
  .blog-image {
    width: 100%; }
  .blog-image img {
    width: 100% !important; }
  .callto-action-btn {
    text-align: center;
    margin-top: 30px; }
  .callto-action-text h5 {
    text-align: center;
    font-size: 27px; }
  .error-text img {
    width: 100%; }
  .error-text h2 {
    font-size: 28px;
    line-height: 40px; }
  .price-details {
    padding: 0 55px; }
  .price-qoute {
    padding: 10px 0 5px 55px; }
  .footer-logo img {
    width: 160px; }
  .footer-logo p {
    font-size: 15px; }
  .footer-content ul li a {
    font-size: 15px; }
  .footer-heading h3 {
    margin-top: 18px; } }

/* Large Mobile :575px. */
@media only screen and (min-width: 575px) and (max-width: 767px) {
  .search-icon-area {
    right: 15%;
    top: -67px; }
  #searchbox {
    max-width: 90%;
    font-size: 20px; }
  .search-icon-area i {
    background-color: transparent;
    color: #fff; }
  .header-pattern-2 {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .header-pattern-1 {
    display: none; }
  .header-content-area {
    background: linear-gradient(253.52deg, #48D6F2 8.92%, #84FC6A 96.59%); }
  .intro-text h1 {
    font-size: 35px;
    line-height: 45px; }
  .layer {
    display: none; }
  .portfolio-area {
    border-bottom-left-radius: 73% 15%;
    border-bottom-right-radius: 50% 10%; }
  .portfolio-button-area {
    text-align: left;
    margin-bottom: 30px; }
  .portfolio-hero-text {
    top: 67%; }
  .portfolio-hero-text h1 {
    font-size: 32px; }
  .portfolio-hero-text p {
    line-height: 25px; }
  .portfolio-filter {
    margin-bottom: 60px; }
  .single-client-img {
    margin-bottom: 15px;
    text-align: center; }
  .testimonial-area {
    border-bottom-left-radius: 50% 10%;
    border-bottom-right-radius: 50% 10%; }
  .testimonial-img img {
    width: 95%; }
  .left-arrow-2 {
    bottom: -40px; }
  .right-arrow-2 {
    bottom: -40px; }
  .quotation img {
    width: 60px; }
  .testi-slider-text {
    margin-top: 15px; }
  .margin-top-lb-30 {
    margin-top: 30px !important; }
  .home-counter-text {
    margin-top: 70px;
    padding-right: 0; }
  .home-about-text {
    margin-top: 70px;
    padding-right: 0; }
  .home-expertise-text {
    margin-top: 70px;
    padding-right: 0; }
  .section-heading-2-p {
    padding-bottom: 45px !important; }
  .counter-area-small {
    padding-right: 0px; }
  .default-margin-mt {
    margin-top: 0; }
  .blog-post-heading h1 {
    font-size: 30px; }
  .price-details {
    padding: 0 20px; }
  .price-qoute {
    padding: 10px 0 5px 20px; }
  .footer-logo img {
    width: 160px; }
  .footer-logo p {
    font-size: 15px; }
  .footer-content ul li a {
    font-size: 15px; }
  .footer-heading h3 {
    margin-top: 18px; }
  .author-profile img {
    width: 50%; }
  .contact-form-area {
    padding: 50px; }
  .contact-form-area input[type='text'] {
    width: 100%; }
  .callto-action-btn {
    text-align: center; }
  .callto-action-text h5 {
    font-size: 22px; }
  .error-text img {
    width: 100%; }
  .single-contact-info {
    margin: 0; }
  .large-mb-d {
    margin-top: 30px; } }

.pt-6 {
  padding-top: 5em; }

/* Responsive CSS END
/*----------------------------------------*/
/*#region Responsive Templates */
/*#endregion */
/*#region Rem */
/*#endregion */
button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 1 !important;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background-color: transparent;
  outline: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
  transition: all 0.15s ease;
  transition-delay: 0; }
  button:hover, button:focus, button:active {
    outline: 0 !important;
    box-shadow: none !important; }

.btn {
  height: 42px;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1rem; }
  .btn:hover, .btn:focus, .btn:active {
    text-decoration: none;
    outline: 0 !important;
    box-shadow: none !important; }
  .btn i {
    padding-right: 0.5em;
    font-size: 1.125rem; }
    .btn i.right {
      padding-right: 0;
      padding-left: 0.5em; }
  .btn.btn-sm, .btn-group-sm > .btn {
    height: 33px;
    font-size: 0.75rem; }
  .btn.btn-lg, .btn-group-lg > .btn {
    height: 60px;
    font-size: 1.125rem; }
  .btn.btn-narrow {
    padding: 0 1rem !important; }
  .btn.btn-round, .btn.btn-square {
    width: 42px;
    border-radius: 50%;
    padding: 0 !important; }
    .btn.btn-round i, .btn.btn-square i {
      padding: 0;
      font-size: 20px; }
    .btn.btn-round.btn-sm, .btn-group-sm > .btn.btn-round, .btn.btn-square.btn-sm, .btn-group-sm > .btn.btn-square {
      width: 33px; }
    .btn.btn-round.btn-lg, .btn-group-lg > .btn.btn-round, .btn.btn-square.btn-lg, .btn-group-lg > .btn.btn-square {
      width: 60px; }
  .btn.btn-square {
    border-radius: 0.5rem !important; }
  .btn.btn-light {
    background-color: rgba(11, 175, 255, 0.1);
    border: none;
    color: #0BAFFF; }
    .btn.btn-light:hover, .btn.btn-light:focus, .btn.btn-light:active {
      background-color: rgba(11, 175, 255, 0.2);
      border: none;
      color: #0BAFFF; }
  .btn.btn-green.btn-light {
    background-color: rgba(45, 202, 115, 0.1);
    border: none;
    color: #2DCA73; }
    .btn.btn-green.btn-light:hover, .btn.btn-green.btn-light:focus, .btn.btn-green.btn-light:active {
      background-color: rgba(45, 202, 115, 0.2);
      border: none;
      color: #2DCA73; }
  .btn.btn-red.btn-light {
    background-color: rgba(240, 63, 63, 0.1);
    border: none;
    color: #F03F3F; }
    .btn.btn-red.btn-light:hover, .btn.btn-red.btn-light:focus, .btn.btn-red.btn-light:active {
      background-color: rgba(240, 63, 63, 0.2);
      border: none;
      color: #F03F3F; }

/*#region Responsive Templates */
/*#endregion */
/*#region Rem */
/*#endregion */
.dropdown-toggle::after {
  display: none !important; }

.dropdown-menu {
  border-color: #E5E9F2; }

/*#region Responsive Templates */
/*#endregion */
/*#region Rem */
/*#endregion */
.form-group {
  position: relative; }
  .form-group label {
    color: #5A6679;
    margin-bottom: 10px;
    min-height: 16px; }
  .form-group textarea {
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .form-group textarea::-webkit-scrollbar {
      background-color: #fff;
      width: 14px;
      height: 14px; }
    .form-group textarea::-webkit-scrollbar-corner {
      background-color: #fff; }
    .form-group textarea::-webkit-scrollbar-thumb {
      border-radius: 14px;
      background-color: rgba(132, 146, 166, 0.15);
      border: 4px solid #fff; }
  .form-group.form-group-icon-right .form-control {
    padding-right: 58px; }
  .form-group.form-group-icon-right > i {
    position: absolute;
    top: 52px;
    right: 20px;
    font-size: 20px;
    color: #8492A6; }

.form-control:not(textarea) {
  height: 60px !important; }

input, textarea {
  outline: none;
  box-shadow: none !important; }

/*#region Rem */
/*#endregion */
/*#region Responsive Templates */
/*#endregion */
[class^="icon-"],
[class*=" icon-"] {
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem; }

html {
  height: 100%; }

body {
  font-family: 'Nunito';
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  transition: all 0.15s ease;
  transition-delay: 0; }

.features {
  overflow-x: hidden; }
  .features__row {
    display: flex;
    align-items: center; }
    @media (max-width: 767.98px) {
      .features__row {
        flex-direction: column !important; } }
    .features__row--reversed {
      flex-direction: row-reverse; }
      .features__row--reversed .features__img {
        text-align: right; }
  .features__txt {
    flex: 0 0 50%; }
    @media (max-width: 767.98px) {
      .features__txt {
        order: 2; } }
  .features__img {
    flex: 0 0 50%; }
    @media (max-width: 767.98px) {
      .features__img {
        order: 1;
        text-align: center; }
        .features__img img {
          max-width: 100%; } }

/*#region Responsive Templates */
/*#endregion */
/*#region Rem */
/*#endregion */
.list-bordered {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-bordered li {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid #E5E9F2; }
    .list-bordered li:last-child {
      border: none; }

/*#region Responsive Templates */
/*#endregion */
/*#region Rem */
/*#endregion */
