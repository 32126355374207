@import "../variables";
@import "../mixins";

.list-bordered {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border: none;
    }
  }
}
