@import "../mixins";
@import "../variables";

[class^="icon-"],
[class*=" icon-"] {
  font-family: "iconfont" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-base;
}

html {
  height: 100%;
}

body {
  font-family: 'Nunito';
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  @include transition(0.15s);
}

.container {
}

.features {
  overflow-x: hidden;

  &__row {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column !important;
    }

    &--reversed {
      flex-direction: row-reverse;

      .features__img {
        text-align: right;
      }
    }
  }

  &__txt {
    flex: 0 0 50%;

    @include media-breakpoint-down(sm) {
      order: 2;
    }
  }

  &__img {
    flex: 0 0 50%;

    @include media-breakpoint-down(sm) {
      order: 1;
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
}
