//Core modules
@import "../lib/bootstrap/scss/functions";
@import "../lib/bootstrap/scss/mixins";
@import "mixins";
@import "variables";

//Fonts
@import "../fonts/fonts";
@import url('../icons/iconfont.css');

// BOOTSTRAP v4.1.0
@import "../lib/bootstrap/scss/variables";
@import "../lib/bootstrap/scss/root";
@import "../lib/bootstrap/scss/reboot";
@import "../lib/bootstrap/scss/type";
@import "../lib/bootstrap/scss/images";
@import "../lib/bootstrap/scss/code";
@import "../lib/bootstrap/scss/grid";
@import "../lib/bootstrap/scss/tables";
@import "../lib/bootstrap/scss/forms";
@import "../lib/bootstrap/scss/buttons";
@import "../lib/bootstrap/scss/transitions";
@import "../lib/bootstrap/scss/dropdown";
@import "../lib/bootstrap/scss/button-group";
@import "../lib/bootstrap/scss/input-group";
//@import "../lib/bootstrap/scss/custom-forms";
@import "../lib/bootstrap/scss/nav";
@import "../lib/bootstrap/scss/navbar";
@import "../lib/bootstrap/scss/card";
//@import "../lib/bootstrap/scss/breadcrumb";
@import "../lib/bootstrap/scss/pagination";
//@import "../lib/bootstrap/scss/badge";
//@import "../lib/bootstrap/scss/jumbotron";
@import "../lib/bootstrap/scss/alert";
//@import "../lib/bootstrap/scss/progress";
@import "../lib/bootstrap/scss/media";
//@import "../lib/bootstrap/scss/list-group";
@import "../lib/bootstrap/scss/close";
@import "../lib/bootstrap/scss/modal";
@import "../lib/bootstrap/scss/tooltip";
//@import "../lib/bootstrap/scss/popover";
//@import "../lib/bootstrap/scss/carousel";
@import "../lib/bootstrap/scss/utilities";
//@import "../lib/bootstrap/scss/print";

// Plugins
@import "../lib/aos/scss/aos";
@import "plugins/slick.scss";
@import "plugins/meanmenu.scss";
@import "plugins/magnific-popup.scss";

// Theme
@import "theme/theme";

//Shared
@import "shared/app-footer";
@import "shared/app-header";
@import "shared/buttons";
@import "shared/dropdown";
@import "shared/forms";
@import "shared/global";
@import "shared/list";
@import "shared/modal";
@import "shared/table";
@import "shared/typohraphy";

//Pages
@import "pages/about";
@import "pages/contact";
@import "pages/index";
