@import "../variables";
@import "../mixins";

$btn-font-weight: 500;
$input-btn-line-height: 1;
$input-btn-line-height-sm: 1;
$input-btn-line-height-lg: 1;
$input-btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-block-spacing-y: 0.5rem;

$input-btn-padding-y-sm: 0rem;
$input-btn-padding-y: 0rem;
$input-btn-padding-y-lg: 0rem;

$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-x: 2rem;
$input-btn-padding-x-lg: 3rem;

$input-btn-font-size-sm: 0.75rem;
$input-btn-font-size: 1rem;
$input-btn-font-size-lg: 1.125rem;

$input-btn-height-sm: 33px;
$input-btn-height: 42px;
$input-btn-height-lg: 60px;

button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  line-height: 1 !important;
  display: inline-block;
  vertical-align: middle;
  border: none;
  background-color: transparent;
  outline: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
  @include transition(0.15s);

  &:hover, &:focus, &:active {
    outline: 0 !important;
    box-shadow: none !important;
  }
}

.btn {
  height: $input-btn-height;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  //background: $blue;
  //color: $white;
  font-size: $input-btn-font-size;

  &:hover, &:focus, &:active {
    //background: darken($blue, 12%);
    //color: $white;
    text-decoration: none;
    outline: 0 !important;
    box-shadow: none !important;
  }

  i {
    padding-right: 0.5em;
    font-size: $input-btn-font-size-lg;

    &.right {
      padding-right: 0;
      padding-left: 0.5em;
    }
  }

  &.btn-sm {
    height: $input-btn-height-sm;
    font-size: $input-btn-font-size-sm;
  }

  &.btn-lg {
    height: $input-btn-height-lg;
    font-size: $input-btn-font-size-lg;
  }

  &.btn-narrow {
    padding: 0 1rem !important;
  }

  &.btn-round, &.btn-square {
    width: $input-btn-height;
    border-radius: 50%;
    padding: 0 !important;

    i {
      padding: 0;
      font-size: 20px;
    }

    &.btn-sm {
      width: $input-btn-height-sm;
    }

    &.btn-lg {
      width: $input-btn-height-lg;
    }
  }

  &.btn-square {
    border-radius: $border-radius !important;
  }

  &.btn-light {
    background-color: rgba($blue,0.1);
    border: none;
    color: $blue;

    &:hover, &:focus, &:active {
      background-color: rgba($blue,0.2);
      border: none;
      color: $blue;
    }
  }

  &.btn-green {
    &.btn-light {
      background-color: rgba($green,0.1);
      border: none;
      color: $green;

      &:hover, &:focus, &:active {
        background-color: rgba($green,0.2);
        border: none;
        color: $green;
      }
    }
  }

  &.btn-red {
    &.btn-light {
      background-color: rgba($red,0.1);
      border: none;
      color: $red;

      &:hover, &:focus, &:active {
        background-color: rgba($red,0.2);
        border: none;
        color: $red;
      }
    }
  }

}
